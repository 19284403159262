import { apiTurnos, apiTurnosBackground } from '@/core/api/';

export const getProfile = (email) => {
  return apiTurnos.get(`api/account/profile/${email}`);
};

export const updateProfile = (payload) => {
  return apiTurnos.put('api/account/profile', payload);
}

export const setOnlineActivity = () => {
  return apiTurnosBackground.get(`api/account/track`);
};

export const loginChat = () => {
  return apiTurnosBackground.post(`api/account/login/chat`);
};
