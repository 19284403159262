import types from './types';

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },

  [types.SET_PROFILE](state, profile) {
    state.profile = profile;
  },

  [types.SET_MENU](state, userMenu) {
    state.userMenu = userMenu;
  },

  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },

  [types.SET_MENU_ITEM_ID](state, menu) {
    state.menuItemId = menu.id;
  },
  [types.SET_CURRENT_ACTIVE_LOCATION](state, payload) {
    state.currentActiveLocation = payload;
  },
  [types.SET_ACTIVE_TURN](state, payload) {
    state.activeBreakAndTurn.activeTurn = payload;
  },
  [types.SET_ACTIVE_BREAK](state, payload) {
    state.activeBreakAndTurn.activeBreak = payload;
  },
  [types.SET_ACTIVE_WAITING_ROOM](state, payload) {
    state.activeBreakAndTurn.activeWaitingRoom = payload;
  },
  [types.SET_WAITING_ROOM_CITIZEN_STATE](state, payload) {
    if(state.activeBreakAndTurn.activeWaitingRoom) {
      state.activeBreakAndTurn.activeWaitingRoom.isCitizenActive = payload;
    }
  },
  [types.SET_CURRENT_ACTIVE_STATION](state, payload) {
    state.currentActiveStation = payload;
  },
  [types.SET_INTERNAL_CHAT_ACTIVE](state, payload) {
    state.isInternalChatActive = payload;
  },
  [types.SHOW_INTERNAL_CHAT](state, payload) {
    state.showInternalChat = payload;
  },
  [types.SET_VIRTUAL_PHONE_INFO](state, payload) {
    state.virtualPhoneInfo = payload;
  },
};
