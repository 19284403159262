import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from '../translations';

import store, { modulesName } from '@/store';

Vue.use(VueI18n);

const defaultLanguage = store.getters[`${modulesName.languageModuleName}/getLanguage`];

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  messages
});
