import { apiTurnos, apiTurnosBackground } from '@/core/api/';

export const getBreakReasons = (locationConfigurationId) => apiTurnos.get(`/api/breakmanager/reason/list`, { locationConfigurationId });

export const getBreakTypes = (locationConfigurationId) => apiTurnos.get(`/api/breakmanager/${locationConfigurationId}/types/list`);

export const createBreak = (payload) => apiTurnos.post('/api/breakmanager/log', payload);

export const approveOrDenyBreak = (payload) =>  apiTurnos.post(`/api/breakmanager/approve`, payload);

export const checkBreak = (payload) => apiTurnos.get('/api/breakmanager/check', { params: { ...payload }});

export const checkBreakInBackground = (payload) => apiTurnosBackground.get('/api/breakmanager/check', { params: { ...payload }});

export const getCurrentLoggedInLocation = () => apiTurnos.get('/api/breakmanager/current/loggedin');

export const login = (locationConfigurationId, forceCreate = false) => apiTurnos.post(`/api/breakmanager/${locationConfigurationId}/login?forceCreate=${forceCreate}`);

export const logout = (locationConfigurationId) => apiTurnos.post(`/api/breakmanager/${locationConfigurationId}/logout`);

export const finish = (breakLogId) => apiTurnos.post(`/api/breakmanager/finish`, { breakLogId });

export const getBreaks = (payload) => apiTurnos.get('/api/breakManager/types/search',{ params : { ...payload }});

export const getBreakLocations = (payload) => apiTurnos.get('/api/breakManager/locations/search',{ params : { ...payload }});

export const addBreakType = (payload) => apiTurnos.post('/api/breakManager/type',payload);

export const updateBreakType = (payload) => apiTurnos.put('/api/breakManager/type',payload);

export const updateBreakLocation = (payload) => apiTurnos.put('/api/breakManager/location', payload);

export const deleteBreakType = (payload) => apiTurnos.delete(`/api/breakManager/type/${payload}`);

export const getAssignedLocations = (payload) => apiTurnos.get(`/api/breakManager/type/${payload}/locations`);

export const assignLocations = (payload) => apiTurnos.post(`/api/breakManager/type/${payload.breakTypeId}/locations`, payload.locations);
