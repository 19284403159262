import { apiTurnos } from '@/core/api/';

export const getUserMenu = async () => {
  const { data } = await apiTurnos.get('/api/account/sitemap/');
  return data;
};

export const clearApiCache = async () => {
  await apiTurnos.get('/api/account/cleancache/');
};

export const getAdditionalProfileInfo = async () => {
  const { data } = await apiTurnos.get('/api/account/getprofileinformation/');

  if (data) {
    return data;
  }

  return null;
};

export const changeUserPassword = args => {
  return apiTurnos.post('/api/account/changepassword/', args);
};