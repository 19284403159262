const _state = {
  loading: false,
  sitemap: sessionStorage.getItem('user-siteMap') ? JSON.parse(sessionStorage.getItem('user-siteMap')) : [],
  isNavOpen: true,
  isMobileDensity: false,
  selectedMenuItem: null,
  route: null,
  hubLoaded: false
};

export const initialState = () => _state;

export const state = initialState();
