import types from './types';
export default {
  [types.SET_LOADING](state, isLoading) {
    state.loading = isLoading;
  },
  [types.SET_SELECTED_MENU_ITEM](state, menuItem) {
    state.selectedMenuItem = menuItem;
  },
  [types.SET_ROUTE](state, route) {
    state.route = route;
  },
  setIsNavOpen(state, value) {
    state.isNavOpen = value;
  },
  toggleNav(state) {
    state.isNavOpen = !state.isNavOpen;
  },
  setIsMobileDensity(state, value) {
    state.isMobileDensity = value;
  },
  [types.SET_HUB_LOADED](state, payload) {
    state.hubLoaded = payload;
  },
};
