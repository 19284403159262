import store, { modulesName } from '@/store';

export const applyEvents = (router) => {
  router.beforeEach(async (to, _, next) => {
    const meta = to.matched
      .filter((x) => x.meta)
      .map((x) => x.meta)
      .reduce((x, y) => ({ ...x, ...y }), {});

    if (!meta.authorization || (meta.authorization.authenticated && !store.getters[`${modulesName.userModuleName}/authenticated`])) {
      if (!store.getters[`${modulesName.userModuleName}/authenticated`]) {
        store.dispatch(`${modulesName.userModuleName}/login`);
        return;
      }
    }

    if(meta.authorization && meta.authorization.authenticated) {
      const userInfo = store.getters[`${modulesName.userModuleName}/profile`];

      if (to.path !== '/' && userInfo == null && meta.role != null) {
        return next({
          path: '/401'
        });
      }
    }

    return next();
  });
};
