export default {
  authenticated: ({ user }) => (user === null ? false : !user.expired),
  accessToken: ({ user }) => (user === null ? null : user.access_token),
  tokenType: ({ user }) => (user === null ? null : user.token_type),
  expiresAt: ({ user }) => (user === null ? null : user.expires_at),
  role: ({ user }) => (user === null ? null : user.profile.role),
  roleId: ({ user }) => (user === null ? null : user.profile.roleId),
  user: ({ user }) => user,

  fullProfile({ profile }) {
    return profile;
  },

  profile({ profile }) {
    if (profile === null) {
      return null;
    }
    const { userName, userId } = profile;
    return {
      id: userId,
      name: userName,
    };
  },

  hasPermissions: ({ permissions }) => _permission =>
    permissions && permissions.length
      ? permissions.some(permission => permission === _permission)
      : false,
  hasStationPermissions: ({ currentActiveStation }) => _permission =>
    currentActiveStation && currentActiveStation.permissions.length
      ? currentActiveStation.permissions.some(
        permission => permission === _permission,
      )
      : false,

  selectedMenuItem({ userMenu, menuItemId }) {
    const menuItem = userMenu.find(x => x.id == menuItemId);

    if (!menuItem) {
      return [];
    }

    return menuItem.children.map(item => ({
      description: item.description,
      detail: item.detail,
      hoverImage: item.mainImageHoverPath,
      icon: item.iconClasses,
      mainImage: item.mainImagePath,
      needSelectedStudent: item.needSelectedStudent,
      route: item.navigationPath,
      title: item.title,
    }));
  },
  currentActiveLocation({ currentActiveLocation }) {
    return currentActiveLocation;
  },
  currentActiveStation({ currentActiveStation }) {
    return currentActiveStation;
  },
  currentLocations({ profile }) {
    let result = [];
    if (!profile || !profile.locationConfigurations) return result;

    const locationConfigurations = profile.locationConfigurations;
    const locations = profile.locations;
    for (const key in locationConfigurations) {
      result.push({
        locationConfigurationId: key,
        locationId: locationConfigurations[key],
        name: locations[locationConfigurations[key]],
      });
    }

    result = result.sort((a, b) => a.name.localeCompare(b.name));

    return result;
  },
  companies({ profile }) {
    return profile.companies;
  },
  userMenu({ userMenu }) {
    return userMenu;
  },
  isInternalChatActive({ isInternalChatActive, profile }) {
    return isInternalChatActive && profile.isInternalChatActive;
  },
};
