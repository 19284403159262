import moment from 'moment'

const CUSTOM_LOCALE = 'es-PR'

export const hour = (date) => {
    if (date == undefined || date == '' || date == null) return '-';

    let result = moment(date)
      .locale(CUSTOM_LOCALE)
      .format('hh:mm A')

    return result
  }

export const date = (value) => {
    if (value == undefined || value == '') return '-';

    let result = moment(value)
      .locale(CUSTOM_LOCALE)
      .format('DD/MMM/YYYY')
      .replace(/ 0(?:0)?/, ' ');

    return result;
  }

export const datetime = (value) => {
  if (value == undefined || value == '') return '-';

  let result = moment(value)
    .locale(CUSTOM_LOCALE)
    .format('DD/MMM/YYYY HH:mm:ss')
    .replace(/ 0(?:0)?/, ' ');

  return result;
}

export const capitalize = (value) => {
    if (!value) return ''
        value = value.toString()

    return value
        .toLowerCase()
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
}

export const daywithnumber = (value) => {
    if (!value) return ''
        value = value.toString();

    return moment(value)
        .locale(CUSTOM_LOCALE)
        .format('DD - dddd')
        .replace(/ 0(?:0)?/, ' ')
        .split(' ')
        .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
        .join(' ');
}

export const price = (value) => {
    let val = (value / 1).toFixed(2);
    let formattedValue = parseFloat(Math.abs(val)).toLocaleString(CUSTOM_LOCALE, { minimumFractionDigits: 2 })

    return val < 0 ? '-$' + formattedValue : '$' + formattedValue;
}

export const currency = (value, minimumFraction = 2) => {
    if (value == undefined || value == '') return '-'

    return value.toLocaleString(CUSTOM_LOCALE, { minimumFractionDigits: minimumFraction, maximumFractionDigits: 2 })
}

export const uppercase = (value) => {
    if (!value) return '';
    return value.toString().toUpperCase();
};

export const telephone = (value) => {
  if (!value) return value;
  return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
};
