import Vue from 'vue';

import types from './types';
import {
  getUser,
  login as _login,
  logoutWithRedirect as _logoutWithRedirect,
} from '@/core/auth';

import { userMixin } from '@/modules/user/mixins/user.mixin';
import IdentityPermission from '@/constants/IdentityPermission';
import { modulesName } from '@/store';
import { getUserMenu, getAdditionalProfileInfo } from '../api';
import { logoutWithRedirect } from '@/core/auth';
import { clearApiCache } from '@/modules/user/api';
import { getVirtualPhoneInfo as _getVirtualPhoneInfo } from '@/services/CommunicationService';

import {
  checkBreak as _checkBreak,
  getCurrentLoggedInLocation as _getCurrentLoggedInLocation,
} from '@/services/BreakManagerService';

export default {
  async init(context, payload) {
    const { state, commit, dispatch } = context;
    try {
      const user = await getUser(); // This always call for refresh token information.
      commit(types.SET_USER, user);

      if (state.user && (!state.userMenu || !state.permissions)) {
        dispatch('setCurrentActiveLocation', null);

        const [userMenu, additionalProfileInfo] = await Promise.all([
          await getUserMenu(),
          await getAdditionalProfileInfo(),
        ]);

        if (userMenu && userMenu.length > 0) {
          commit(types.SET_MENU, userMenu);
          sessionStorage.setItem('user-menu', JSON.stringify(userMenu));
        }

        if (
          additionalProfileInfo &&
          additionalProfileInfo.permissions.length > 0
        ) {
          commit(
            `${modulesName.languageModuleName}/SET_LANGUAGE`,
            localStorage.getItem('locale') ||
              additionalProfileInfo.user.locale ||
              'es',
            { root: true },
          );

          commit(types.SET_PERMISSIONS, additionalProfileInfo.permissions);
          sessionStorage.setItem(
            'user-permissions',
            JSON.stringify(additionalProfileInfo.permissions),
          );
        }

        additionalProfileInfo.user.isInternalChatActive =
          additionalProfileInfo.isInternalChatActive;       

        // This have the default claims and the additional ones
        commit(types.SET_PROFILE, additionalProfileInfo.user);
        sessionStorage.setItem(
          'user-profile',
          JSON.stringify(additionalProfileInfo.user),
        );

        dispatch('validateCurrentLoggedInLocation');
      } else if (state.user) {
        dispatch('validateCurrentLoggedInLocation');

        if (payload && payload.initialLoadOrRefresh) {
          dispatch('checkForActiveBreakAndTurn');
        }
      }

      await _getVirtualPhoneInfo().then(async ({ data }) => {
        if (data) {
          if (data.isActive === true) {
            const virtualPhoneInfo = {
              communicationServerAddress: data.communicationServerAddress,
              virtualPhoneExtension: data.virtualPhoneExtension,
              virtualPhonePassword: data.virtualPhonePassword,
            };
            commit(types.SET_VIRTUAL_PHONE_INFO, virtualPhoneInfo);
          }

        }
      });

      if (state.user && Vue.prototype && Vue.prototype.$appInsights) {
        Vue.prototype.$appInsights.setAuthenticatedUserContext(
          state.user.profile.name,
        );
      }
    } catch {
      commit(types.SET_USER, null);
      commit(types.SET_PROFILE, null);
      commit(types.SET_MENU, null);
      commit(types.SET_PERMISSIONS, null);
      commit(types.SET_CURRENT_ACTIVE_LOCATION, null);
    }
  },
  setProfile({ commit }, payload) {
    commit(types.SET_PROFILE, payload);
  },
  setCurrentActiveLocation({ commit }, payload) {
    commit(types.SET_CURRENT_ACTIVE_LOCATION, payload);
    localStorage.setItem('current-active-location', JSON.stringify(payload));
  },
  setCurrentActiveStation({ commit }, payload) {
    commit(types.SET_CURRENT_ACTIVE_STATION, payload);
  },
  showInternalChat({ commit }, payload) {
    commit(types.SHOW_INTERNAL_CHAT, payload);
  },
  login() {
    _login();
  },
  logout({ dispatch }) {
    dispatch('setCurrentActiveLocation', null);

    _logoutWithRedirect();
  },
  async setUserActiveTurn({ commit }, payload) {
    commit(types.SET_ACTIVE_TURN, payload);
  },
  async setActiveBreak({ commit }, payload) {
    commit(types.SET_ACTIVE_BREAK, payload);
  },
  async setUserActiveWaitingRoom({ commit }, payload) {
    commit(types.SET_ACTIVE_WAITING_ROOM, payload);
  },
  async setWaitingRoomCitizenState({ commit }, payload) {
    commit(types.SET_WAITING_ROOM_CITIZEN_STATE, payload);
  },
  async setInternalChatActive({ commit }, payload) {
    commit(types.SET_INTERNAL_CHAT_ACTIVE, payload);
  },
  async checkForActiveBreakAndTurn({ commit, state, getters, dispatch }) {
    const isMultiOffice = userMixin.methods.userHasPermissions(
      IdentityPermission.multi.office,
    );

    const breakCheckEnabled = userMixin.methods.userHasPermissions(
      IdentityPermission.break.check.enabled,
    );

    if (!breakCheckEnabled && !isMultiOffice) {
      return dispatch('setCurrentActiveLocation', null);
    }

    const payload = {
      turnFinished: false,
      addTurnInfo: true,
      addWaitingRoomInfo: true,
      locationConfigurationId:
        state?.currentActiveLocation?.locationConfigurationId,
    };

    await _checkBreak(payload).then(async ({ data }) => {
      if (data.hasPendingLogout) return dispatch('logout');

      if (
        !data.activeTurn &&
        !data.activeWaitingRoom &&
        data.activeBreak &&
        data.activeBreak.userHasPendingBreakToStart
      ) {
        await _checkBreak({ turnFinished: true }).then(async () => {
          await clearApiCache();
          logoutWithRedirect();
        });

        return;
      }

      if (data.hasPendingRefresh)
        dispatch(
          `${modulesName.turnManagerModuleName}/refreshTurnManager`,
          null,
          { root: true },
        );

      // Set this first to correctly evaluate the change in the break
      commit(types.SET_ACTIVE_TURN, data.activeTurn);
      commit(types.SET_ACTIVE_WAITING_ROOM, data.activeWaitingRoom);

      if (
        isMultiOffice &&
        data.activeTurn &&
        data.activeTurn.locationConfigurationId &&
        getters.currentLocations
      ) {
        if (
          !state.currentActiveLocation ||
          (state.currentActiveLocation &&
            state.currentActiveLocation.locationConfigurationId !=
              data.activeTurn.locationConfigurationId)
        ) {
          const current = getters.currentLocations.find(
            (x) =>
              x.locationConfigurationId ==
              data.activeTurn.locationConfigurationId,
          );
          if (!current) return dispatch('logout');
          dispatch('changeCurrentTurn', current);
        }
      }

      if (breakCheckEnabled) {
        commit(types.SET_ACTIVE_BREAK, data.activeBreak);
        dispatch(
          `${modulesName.turnManagerModuleName}/setActiveBreak`,
          data.activeBreak,
          { root: true },
        );
      }
    });
  },
  changeCurrentTurn() {},
  async validateCurrentLoggedInLocation({ state, dispatch, getters }) {
    if (
      !userMixin.methods.userHasPermissions(IdentityPermission.multi.office) ||
      !getters.currentLocations ||
      !getters.currentLocations.length
    )
      return dispatch('setCurrentActiveLocation', null);

    if (state.currentActiveLocation) {
      const current = getters.currentLocations.find(
        (x) =>
          x.locationConfigurationId ==
          state.currentActiveLocation.locationConfigurationId,
      );
      dispatch('setCurrentActiveLocation', current || null);
      if (current) return;
    }

    if (getters.currentLocations.length === 1) {
      return dispatch('setCurrentActiveLocation', getters.currentLocations[0]);
    }
    await _getCurrentLoggedInLocation().then((response) => {
      if (!response.data) return;
      const current = getters.currentLocations.find(
        (x) => x.locationConfigurationId == response.data,
      );

      dispatch('setCurrentActiveLocation', current || null);
    });
  },
};
