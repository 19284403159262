import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import { NODE_ENV, DEBUG_LOG } from '@/config';

import appModule, { name as appModuleName } from '@/modules/app/store';
import commonModule, { name as commonModuleName } from '@/modules/common/store';
import userModule, { name as userModuleName } from '@/modules/user/store';

import appointmentModule, {
  name as appointmentModuleName
} from '@/modules/appointment/store';

import languageModule, {
  name as languageModuleName
} from '@/modules/language/store/language.js';

import turnManagerModule, {
  name as turnManagerModuleName
} from '@/modules/turnManager/store';

// Only Modules is always loaded with the app
export const modulesName = {
  appModuleName: appModuleName,
  commonModuleName: commonModuleName,
  userModuleName: userModuleName,
  appointmentModuleName: appointmentModuleName,
  languageModuleName: languageModuleName,
  turnManagerModuleName: turnManagerModuleName
};

Vue.use(Vuex);
const debug = NODE_ENV !== 'production';
const debugLog = DEBUG_LOG === 'true';

export default new Vuex.Store({
  strict: debug,
  modules: {
    [appModuleName]: appModule,
    [commonModuleName]: commonModule,
    [userModuleName]: userModule,
    [appointmentModuleName]: appointmentModule,
    [languageModuleName]: languageModule,
    [turnManagerModuleName]: turnManagerModule
  },
  plugins: debug && debugLog ? [createLogger()] : []
});
