import store, { modulesName } from '@/store';

export const authHandler = config => {
  if (store.getters[`${modulesName.userModuleName}/authenticated`]) {
    let user = store.getters[`${modulesName.userModuleName}/user`];

    let existingUser = null;

    let n = window.sessionStorage.length;

    while (n--) {
      const key = window.sessionStorage.key(n);
      if (/oidc.user/.test(key)) {
        existingUser = JSON.parse(window.sessionStorage.getItem(key));
      }
    }

    let accessToken = null;

    if (existingUser.access_token != user.access_token) {
      accessToken = existingUser.access_token;

      store.commit(`${modulesName.userModuleName}/SET_USER`, existingUser);
    } else {
      accessToken = user.access_token;
    }

    config.headers.Authorization = `${
      store.getters[`${modulesName.userModuleName}/tokenType`]
    } ${accessToken}`;
  } else {
    config.headers.Authorization = undefined;
  }
};
