const _env = process.env;

export const NODE_ENV = _env.NODE_ENV;

export const AUTH = {
  AUTHORITY: _env.VUE_APP_URL_AUTH,
  CLIENT_ID: _env.VUE_APP_CLIENT_ID,
  REDIRECT_URI: _env.VUE_APP_REDIRECT_URI,
  RESPONSE_TYPE: _env.VUE_APP_RESPONSE_TYPE,
  SCOPE: _env.VUE_APP_SCOPE,
  SILENT_REDIRECT_URI: _env.VUE_APP_REDIRECT_SILENT_URI,
  ACR_VALUES: _env.VUE_APP_ACR_VALUES,
  CLIENT_SECRET: 'q8jmiawtWS+1OkkPyEUmGau0muQi1qSbHLrq8jmXI6U=',
  SESSION_TIMEOUT: _env.VUE_APP_SESSION_TIMEOUT,
  SESSION_TIMEOUT_WARNING: _env.VUE_APP_SESSION_TIMEOUT_WARNING,
  LOGOUT_PATH: _env.VUE_APP_ACR_URL_LOGOUT,
  POST_LOGOUT_REDIRECT_URI: _env.VUE_APP_URL_AUTH_POST_LOGOUT_REDIRECT_URI,
};

export const APPINSIGHTS = {
  KEY: _env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY,
};

export const KIOSK = {
  BASE_URL: _env.VUE_APP_URL_KIOSK,
};

export const DISPLAY = {
  BASE_URL: _env.VUE_APP_URL_DISPLAY,
};

export const PWA = {
  BASE_URL: _env.VUE_APP_URL_PWA,
};

export const API = {
  BASE_URL: _env.VUE_APP_URL_API,
  TIMEOUT: 30000,
};

export const PBI_REPORTS = {
  PUERTORICOMAP_ID: _env.VUE_APP_POWERBI_PUERTORICOMAP_ID,
  SURVEYDESIGN_ID: _env.VUE_APP_POWERBI_SURVEYDESIGN_ID,
  PERFORMANCEOFATTENTION_ID: _env.VUE_APP_POWERBI_PERFORMANCEOFATTENTION_ID,
  SURVEYBYOPERATOR_ID: _env.VUE_APP_POWERBI_SURVEYBYOPERATOR_ID,
  MAINMETRICS_ID: _env.VUE_APP_POWERBI_MAINMETRICS_ID,
};

export const REPORTS = {
  BASE_URL: _env.VUE_APP_REPORT_BASE_URL,
  BASE_EXPORT_URL: _env.VUE_APP_REPORT_BASE_EXPORT_URL,
  PATH: _env.VUE_APP_REPORT_PATH,
};

export const DEBUG_LOG = _env.VUE_APP_DEBUG_LOG;

export const ROCKET_CHAT = {
  BASE_URL: _env.VUE_APP_ROCKET_CHAT_URI,
  BASE_SOCKET: _env.VUE_APP_ROCKET_CHAT_SOCKET,
};

export const SELFSERVICE = {
  REDIRECT_URI: _env.VUE_APP_SELFSERVICE_REDIRECT_URI,
};

export const COMMUNICATION = {
  PASSIVEUSER_ALERT_TIMEOUT: _env.VUE_APP_COMMUNICATION_PASSIVEUSER_ALERT_TIMEOUT,
  PASSIVEUSER_REQUEST_TIMEOUT: _env.VUE_APP_COMMUNICATION_PASSIVEUSER_REQUEST_TIMEOUT,
  SPECILISTEUSER_ALERT_TIMEOUT: _env.VUE_APP_COMMUNICATION_SPECILISTEUSER_ALERT_TIMEOUT,
  SPECILISTEUSER_REQUEST_TIMEOUT: _env.VUE_APP_COMMUNICATION_SPECILISTEUSER_REQUEST_TIMEOUT
}