import store, { modulesName } from '@/store';
import Router from '@/router';

export const userMixin = {
  computed: {
    userAuthenticated () {
      return store.getters[`${modulesName.userModuleName}/authenticated`];
    },
    userRole () {
      return store.getters[`${modulesName.userModuleName}/role`];
    },
    userProfile () {
      return store.state.profile;
    }
  },
  methods: {
    userHasPermissions (permission, useStationPermissions = false) {
      const _useStationPermissions = Router.currentRoute.meta?.authorization?.useStationPermissions || useStationPermissions || false;
      const stationPermissions = store.getters[`${modulesName.userModuleName}/currentActiveStation`].permissions;
      if (_useStationPermissions && stationPermissions.length) {
        return store.getters[`${modulesName.userModuleName}/hasStationPermissions`](
          permission
        );
      }
      return store.getters[`${modulesName.userModuleName}/hasPermissions`](
        permission
      );
    }
  }
};
