export default {
  methods: {
    saveSessionData(key, value) {
      if (this.hasSessionData(key)) {
        this.removeSessionData(key);
      }

      sessionStorage.setItem(key, JSON.stringify(value));
    },
    loadAndRemoveSessionData(key) {
      const sessionData = this.loadSessionData(key);

      if (sessionData != null) {
        this.removeSessionData(key);
      }

      return sessionData;
    },
    loadSessionData(key) {
      const strItem = sessionStorage.getItem(key);

      if (!strItem) {
        return null;
      }

      const data = JSON.parse(strItem);

      return data;
    },
    removeSessionData(key) {
      sessionStorage.removeItem(key);
    },
    hasSessionData(key) {
      return sessionStorage.getItem(key) !== null;
    },
    clearSessionDataForLogout() {
      let n = sessionStorage.length;

      while (n--) {
        const key = sessionStorage.key(n);
        if (!/oidc.user/.test(key)) {
          sessionStorage.removeItem(key);
        }
      }
    },
  },
};
