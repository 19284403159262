const _state = {
  menuItemId: null,
  companies: sessionStorage.getItem('user-companies')
    ? JSON.parse(sessionStorage.getItem('user-companies'))
    : null,
  permissions: sessionStorage.getItem('user-permissions')
    ? JSON.parse(sessionStorage.getItem('user-permissions'))
    : null,
  user: null,
  profile: sessionStorage.getItem('user-profile')
    ? JSON.parse(sessionStorage.getItem('user-profile'))
    : null,
  userMenu: sessionStorage.getItem('user-menu')
    ? JSON.parse(sessionStorage.getItem('user-menu'))
    : null,
  currentActiveLocation: localStorage.getItem('current-active-location')
    ? JSON.parse(localStorage.getItem('current-active-location'))
    : null,
  currentActiveStation: {
    id: null,
    locationConfigurationId: null,
    serviceQueueId: null,
    permissions: [],
  },
  isInternalChatActive: false,
  // break and turn information for the user
  activeBreakAndTurn: {
    activeBreak: null,
    activeTurn: null,
    activeWaitingRoom: null,
  },
  showInternalChat: false,  
  virtualPhoneInfo: {
    communicationServerAddress: '',
    virtualPhoneExtension: '',
    virtualPhonePassword: '',
  }
};

export const initialState = () => _state;
export const state = initialState();
