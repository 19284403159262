import axios from 'axios';
import { 
  requestInterceptor, responseInterceptor,
  backgroundRequestInterceptor, backgroundResponseInterceptor,
  applicationInsightsRequestInterceptor, applicationInsightsResponseInterceptor
} from './interceptor';

export const apiTurnos = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 0 // TODO: Make this configurable
});

apiTurnos.interceptors.request.use(
  requestInterceptor.onFulfilled,
  requestInterceptor.onRejected
);

apiTurnos.interceptors.response.use(
  responseInterceptor.onFulfilled,
  responseInterceptor.onRejected
);

export const apiTurnosBackground = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 0 // TODO: Make this configurable
});

apiTurnosBackground.interceptors.request.use(
  backgroundRequestInterceptor.onFulfilled,
  backgroundRequestInterceptor.onRejected
);

apiTurnosBackground.interceptors.response.use(
  backgroundResponseInterceptor.onFulfilled,
  backgroundResponseInterceptor.onRejected
);

if(process.env.VUE_APP_INSIGHTS_INSTRUMENTATION_KEY !== undefined) {
  apiTurnos.interceptors.request.use(
    applicationInsightsRequestInterceptor.onFulfilled,
    applicationInsightsRequestInterceptor.onRejected
  );

  apiTurnos.interceptors.response.use(
    applicationInsightsResponseInterceptor.onFulfilled,
    applicationInsightsResponseInterceptor.onRejected
  );

  apiTurnosBackground.interceptors.request.use(
    applicationInsightsRequestInterceptor.onFulfilled,
    applicationInsightsRequestInterceptor.onRejected
  );

  apiTurnosBackground.interceptors.response.use(
    applicationInsightsResponseInterceptor.onFulfilled,
    applicationInsightsResponseInterceptor.onRejected
  );
}