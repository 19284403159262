export default {
  SET_MENU_ITEM_ID: 'SET_MENU_ITEM_ID',
  SET_MENU: 'SET_MENU',
  SET_PERMISSIONS: 'SET_PERMISSIONS',
  SET_USER: 'SET_USER',
  SET_PROFILE: 'SET_PROFILE',
  SET_CURRENT_ACTIVE_LOCATION: 'SET_CURRENT_ACTIVE_LOCATION',
  SET_ACTIVE_TURN: 'SET_ACTIVE_TURN',
  SET_ACTIVE_BREAK: 'SET_ACTIVE_BREAK',
  SET_ACTIVE_WAITING_ROOM: 'SET_ACTIVE_WAITING_ROOM',
  SET_WAITING_ROOM_CITIZEN_STATE: 'SET_WAITING_ROOM_CITIZEN_STATE',
  CHECK_ACTIVE_BREAK_AND_TURN: 'CHECK_ACTIVE_BREAK_AND_TURN',
  SET_CURRENT_ACTIVE_STATION: 'SET_CURRENT_ACTIVE_STATION',
  SET_INTERNAL_CHAT_ACTIVE: 'SET_INTERNAL_CHAT_ACTIVE',
  SHOW_INTERNAL_CHAT: 'SHOW_INTERNAL_CHAT',
  SET_VIRTUAL_PHONE_INFO: 'SET_VIRTUAL_PHONE_INFO',
};
