export default {
  annually: 'Anual',
  hello: 'Hola',
  welcome: 'Bienvenido',
  password: 'Contraseña',
  createNewUserAccount: 'Crear una nueva cuenta de usuario',
  createdAccount: 'Cuenta creada',
  confirmPassword: 'Confirmar contraseña',
  name: 'Nombre',
  lastName: 'Apellido paterno',
  paternalLastName: 'Apellido paterno',
  maternalLastName: 'Apellido materno',
  cellPhone: 'Celular',
  cellPhones: 'Celulares',
  provider: 'Proveedor',
  deliveryProvider: 'Proveedor de envío',
  genre: 'Género',
  birthDate: 'Fecha de nacimiento',
  residencePostalCode: 'Código postal de residencia',
  createNewUser: 'Crear nuevo usuario',
  company: 'Compañía',
  localization: 'Localización',
  services: 'Servicios',
  availableSpaces: 'Espacios Disponibles',
  createSpaces: 'Creación de Espacios',
  verifySpaces: 'Verificación de Espacios',
  view: 'Ver',
  return: 'Regresar',
  confirm: 'Confirmar',
  select: 'Seleccione',
  selectionofspecialists: 'Selección de Especialistas',
  available: 'Disponible',
  createAppointment: 'Crear una cita',
  selected: 'Seleccionados',
  remove: 'Remover',
  removeAttended: 'Remover Atendido',
  cantreactivateturn: 'Este turno no puede ser reactivado',
  delete: 'Borrar',
  add: 'Agregar',
  availablespecialist: 'Especialistas Disponibles',
  selectedspecialist: 'Especialistas Seleccionados',
  selectall: 'Seleccionar todos',
  filterspecialist: 'Filtrar Especialista',
  month: 'Mes',
  week: 'Semana',
  year: 'Año',
  day: 'Día',
  lun: 'Lun',
  mar: 'Mar',
  mie: 'Mie',
  jue: 'Jue',
  vie: 'Vie',
  sab: 'Sab',
  dom: 'Dom',
  trimester: 'Trimestre',
  of: 'de',
  spaces: 'espacios',
  space: 'espacio',
  notavailable: 'No Disponible',
  changelanguage: 'Cambiar Idioma',
  specialist: 'Especialista',
  durationHoursAndMinutes: 'Duración (Horas:Minutos)',
  start: 'Comienza',
  end: 'Termina',
  createspace: 'Crear espacio',
  generalInformation: 'Información general',
  startDate: 'Fecha de inicio',
  endDate: 'Fecha de finalización',
  fromDate: 'Fecha Desde',
  toDate: 'Fecha Hasta',
  durationInMinutes: 'Duración (minutos)',
  fromHour: 'Hora desde',
  toHour: 'Hora hasta',
  ignoredHours: 'Horas a ignorar',
  recurrency: 'Recurrencia',
  repeatEach: 'Repetir cada',
  occurrences: 'ocurrencias',
  selectDatesToIgnore: 'Seleccionar fechas a ignorar',
  specialists: 'Especialistas',
  days: 'Días',
  totalSpaces: 'Espacios totales',
  selectedDays: 'Días seleccionados',
  ignoredDates: 'Fechas ignoradas',
  daily: 'Diario',
  weekly: 'Semanal',
  monthly: 'Mensual',
  annual: 'Anual',
  monday: 'Lunes',
  tuesday: 'Martes',
  wednesday: 'Miércoles',
  thursday: 'Jueves',
  friday: 'Viernes',
  saturday: 'Sábado',
  sunday: 'Domingo',
  finishAfter: 'Terminar después',
  finishOnThisDay: 'Terminar en este día',
  reviewInfo: 'Revise la información que va a someter',
  confirmation: 'Confirmación',
  office: 'Oficina',
  error_logout:
    'Tiene un turno activo que debe cerrar antes de salir del sistema',
  attention: 'Atención',
  date: 'Fecha',
  cancel: 'Cancelar',
  reset: 'Reiniciar',
  save: 'Guardar',
  deleteavailablespace: 'Borrar Espacio Disponible',
  areyousure: 'Está seguro',
  yes: 'Sí',
  no: 'No',
  lastlogin: 'último acceso',
  logout: 'Salir',
  changepassword: 'Cambiar contraseña',
  myprofile: 'Mi perfil',
  review: 'Revisar',
  goBack: 'Regresar',
  process: 'Procesar',
  turnsList: 'Listado de Turnos',
  number: 'Número',
  status: 'Estado',
  visitTo: 'Visita a',
  visiting: 'Visita a',
  row: 'Fila',
  customer: 'Cliente',
  comment: 'Comentario',
  attended: 'Atendido',
  pending: 'Pendiente',
  arrivalTime: 'Hora de Llegada',
  waitTimeInMinutes: 'Espera (Minutos)',
  estimatedWait: 'Espera Estimada',
  appointment: 'Cita',
  createAppoiment: 'Solicitar una cita',
  availability: 'Disponibilidad',
  availabilityOf: 'Disponibilidad de',
  confirmAppointmentAndVerify:
    'CONFIRMAR CITA Y VERIFICAR INFORMACIÓN DE CONTACTO',
  confirmContactInformation: 'Favor de verificar su información de contacto',
  confirmAppointmentValidation: '¿Está seguro que desea confirmar esta cita?',
  confirmAppointmentValidInputErrorMessage:
    'Caracteres en campos de nombre, apellido paterno o apellido materno no permitidos, favor de verificar',
  editAppointmentTitle: 'EDITAR CITADO',
  actions: 'Acciones',
  simpleLastName: 'Apellido',
  email: 'Email',
  emails: 'Emails',
  installationNumber: 'Número de Instalación',
  caseNumber: 'Número de Caso',
  appointmentNumber: 'Número de Cita',
  cited: 'Citado',
  regular: 'Regular',
  mustEnterCited: 'El citado es requerido',
  blind: 'No Vidente',
  attendByPhone: 'Atenderse por Teléfono',
  phoneProvider: 'Proveedor de celular',
  phoneNotification: 'Notificación al Celular',
  service: 'Servicio',
  appointmentService: 'Servicio de Cita',
  rowsAndAppointmentService: 'Filas y Servicios de Cita',
  listMaintenance: 'Mantenimiento de listado',
  customerMaintenance: 'Mantenimiento de clientes',
  turnsManager: 'Manejador de Turnos',
  advanceToRow: 'Avanzar Fila',
  advanceToStation: 'Avanzar Estación',
  calledRow: 'Fila Llamada',
  startAList: 'Comenzar una lista',
  startNumberOrRowContinuation: 'Número de Comienzo o Continuación de la lista',
  unattendedTurnsQuestion:
    'Todavía hay turnos sin atender en la lista. ¿Qué desea hacer?',
  keepOnlyUnattended: 'Mantener solamente los turnos sin atender en la lista.',
  moveAllUnattendedTurns:
    'Mover todos los turnos sin atender a una fila en específico.',
  clearTheEntireList: 'Limpiar toda la lista.',
  startOfTheList: 'Comienzo del listado',
  auto: 'Auto',
  technicalSupport: 'Apoyo Técnico',
  continue: 'Continuar',
  automatic: 'Automático',
  officedetailsmap: 'Mapa de Detalles de Oficinas',
  filters: 'Filtros',
  organization: 'Organización',
  period: 'Período',
  attentionperformance: 'Rendimiento de Atención',
  survey: 'Encuesta',
  surveybyoperator: 'Encuesta Por Operador',
  mainmetrics: 'Métricas Principales',
  sessionexpiredtitle: 'Sesión expirada',
  sessionexpiredcontent: 'Su sesión ha expirado por falta de actividad.',
  areyousuredeletespace: '¿Está seguro que desea borrar este espacio?',
  areyousurecloseturn: '¿Está seguro que desea cerrar este turno?',
  error: 'Error',
  incompleteinformation: 'Información incompleta',
  mustselectcompany: 'Debe seleccionar una compañía',
  mustselectlocalization: 'Debe seleccionar una localización',
  mustSelectService: 'Debe seleccionar un servicio',
  mustselectservices: 'Debe seleccionar al menos un servicio',
  mustselectorganization: 'Debe seleccionar una organización',
  mustselectspecialist: 'Debe seleccionar al menos un especialista',
  mustselectcoordinator: 'Debe seleccionar al menos un coordinador',
  mustselectprovider: 'Debe seleccionar un proveedor',
  mustselecttime: 'Debe seleccionar una hora',
  mustselectdate: 'Debe seleccionar una fecha',
  mustAddField: 'Debe escribir su',
  requiredField: 'Este campo es requerido',
  areyousuregoback: '¿Esta seguro que desea regresar?',
  irreversibleoperationmessage:
    'Esta operación es irreversible, se eliminarán los campos seleccionados hasta el momento',
  all: 'Todos',
  noResultsFound: 'No se encontraron resultados',

  day_0: 'Domingo',
  day_1: 'Lunes',
  day_2: 'Martes',
  day_3: 'Miércoles',
  day_4: 'Jueves',
  day_5: 'Viernes',
  day_6: 'Sábado',

  frequency_type_1: 'Diario',
  frequency_type_2: 'Semanal',
  frequency_type_3: 'Mensual',
  frequency_type_4: 'Anual',
  finalize: 'Finalizar',
  stamps: 'Sellos',
  close: 'Cerrar',
  see: 'Ver',
  downloadAll: 'Descargar todo',
  downloadStartedSuccessful: 'Descarga iniciada exitosamente!',
  findDownloadLocally:
    'Favor de buscar el programa en la carpeta de descargas en su ordenador',
  downloadError: 'Error al descargar',
  downloadErrorMessage:
    'Trate nuevamente, si el error persiste comuníquese con el administrador del servicio',
  downloadInstaller: 'Descargar Instalador',
  'document(s)': 'Documento(s)',
  documentType: 'Tipo de documento',
  action: 'Acción',
  kiosk: 'kiosco',
  kiosks: 'kioscos',
  kioskName: 'Nombre del Kiosco',
  plusClientName: 'Nombre del cliente de Citas Plus',
  edit: 'Editar',
  assignRow: 'Asignar fila',
  kioskList: 'Listado de kioscos',
  assignKioskToOffice: 'Asignar kiosco a la oficina',
  create: 'Crear',
  sendingNotifications: 'Envío de notificaciones',
  screensAmount: 'Cantidad de pantallas (niveles)',
  configuration: 'Configuración',
  integrationWithApiWebService: 'Integración con API / Web service',
  macAddress: 'Mac Address',
  screenType: 'Tipo de pantalla',
  buttonColor: 'Color del botón',
  borderColor: 'Color del borde',
  textColor: 'Color del texto',
  radiusSize: 'Tamaño del radio',
  borderSize: 'Tamaño del borde',
  shadowSize: 'Tamaño de la sombra',
  shadowColor: 'Color de la sombra',
  mustPickColor: 'Debes elegir un color',
  mustSelectOne: 'Debes seleccionar uno',
  mustSelectAtLeastOne: 'Debes seleccionar por lo menos uno',
  topText: 'Texto superior',
  invalidField: 'Campo inválido',
  columnsQuantity: 'Cantidad de columnas',
  topTextColor: 'Color de texto superior',
  topTextSize: 'Tamaño de texto superior',
  topTextBoldPercentage: 'Porcentaje de negrita',
  button: 'Botón',
  buttons: 'Botones',
  buttonText: 'Texto botón',
  sendTo: 'Envia a',
  addButton: 'Agregar botón',
  header: 'Encabezado',
  content: 'Contenido',
  footer: 'Pie de página',
  backgroundColor: 'Color de fondo',
  backgroundImage: 'Imagen de fondo',
  logoPosition: 'Posición del logo',
  textPosition: 'Posición del texto',
  logo: 'Logo',
  saveContinue: 'Guardar & Continuar',
  text: 'Texto',
  homeButtonText: 'Texto del botón de inicio',
  homeButtonTextColor: "Color de texto 'Inicio'",
  homeButtonBackgroundColor: "Color de fondo 'Inicio'",
  homeButtonBorderColor: "Color del borde 'Inicio'",
  homeButtonRadiusSize: "Tamaño del radio 'Inicio'",
  homeButtonBorderSize: "Tamaño del borde 'Inicio'",
  homeButtonShadowColor: "Color de sombra 'Inicio'",
  backButtonText: 'Texto del botón de regresar',
  backButtonTextColor: "Color de texto 'Regresar'",
  backButtonBackgroundColor: "Color de fondo 'Regresar'",
  backButtonBorderColor: "Color del borde 'Regresar'",
  backButtonRadiusSize: "Tamaño del radio 'Regresar'",
  backButtonBorderSize: "Tamaño del borde 'Regresar'",
  backButtonShadowColor: "Color de sombra 'Regresar'",
  officeSelection: 'Selección de Oficina',
  apply: 'Aplicar',
  screen: 'Pantalla',
  specialField: 'Campo especial',
  specialField1: 'Campo especial 1',
  specialField2: 'Campo especial 2',
  specialField3: 'Campo especial 3',
  specialField4: 'Campo especial 4',
  specialField5: 'Campo especial 5',
  dataEntryScreen: 'Pantalla de entrada de datos',
  termsAndConditions: 'Términos y Condiciones',
  requestPhone: 'Solicitar teléfono',
  requestEmail: 'Solicitar email',
  displayKeyboardForEmail: 'Mostrar teclado para email',
  displayKeyboardForPhone: 'Mostrar teclado para teléfono',
  registeredAppointments: 'Citas Registradas',
  directlyRegisteredAppointments: 'Citas Registradas Directamente',
  filter: 'Filtrar',
  cancelAppointmentConfimation: '¿Está seguro que desea cancelar esta cita?',
  syncChatUsersConfimation:
    '¿Está seguro que desea sincronizar (crear/actualizar) todos los usuarios de esta compañía?',
  cancelAppointment: 'cancelar cita',
  sendAppointmentConfirmation:
    '¿Está seguro que desea reenviar la notificación de cita coordinada?',
  resend: 'Reenviar',
  resendVideoCall: 'Reenviar enlace de video llamada',
  linkResent: 'Enlace reenviado',
  dateFrom: 'Fecha desde',
  dateTo: 'Fecha hasta',
  hourFrom: 'Hora desde',
  hourTo: 'Hora hasta',
  hour: 'Hora',
  coordinator: 'Coordinador',
  phone: 'Teléfono',
  mustEnterEmail: 'Debe escribir un correo electrónico',
  mustentercorrectemail: 'Debe ingresar formato de Email Correcto',
  mustEnterName: 'Debe escribir su nombre',
  mustEnterLastName: 'Debe escribir su apellido(s)',
  mustEnterAttendees: 'Debe escribir la cantidad de personas en la cita',
  mustEnterAppointmentNumber: 'Debe escribir el número de cita',
  maxNumberOfAttendees: 'Superó el máximo de {0} personas',
  schedule: 'Horario',
  clients: 'Clientes',
  clientsWaitingEstimatedTime: 'Clientes en Espera Sobre Tiempo Estimado',
  waitingClients: 'Clientes por atender',
  attendedClients: 'Clientes atendidos',
  overwaiting: 'Sobre tiempo de espera',
  client: 'Cliente',
  complete: 'Completar',
  resendconfirmation: 'Reenviar confirmación',
  address: 'Dirección',
  attendees: 'Personas en cita',
  previous: 'Anterior',
  appointmentinformation: 'Información de la Cita',
  cancelreasons: 'Motivo de la cancelación',
  mustselectreason: 'Debe elegir una razón',
  mustwritecomment: 'Debe escribir un comentario',
  search: 'Buscar',
  searchresults: 'Resultados de la búsqueda',
  requestappointment: 'Solicitar una cita',
  appointmentadditionalinformation:
    'Información adicional de la cita confirmada',
  appointmentinformationreceived:
    'Su cita e información de contacto ha sido recibida',
  appointmentInformationBellow:
    'La información de la cita seleccionada es la siguiente',
  appointmentinformationreceived2: 'El número de su confirmación de cita es',
  kioskregistered: 'Kioscos Registrados',
  coordinated: 'Coordinada',
  cancelled: 'Cancelada',
  completed: 'Completada',
  percCompleted: '% Completado',
  approved: 'Aprobada',
  denied: 'Denegada',
  extended: 'Extendida',
  confirmed: 'Confirmada',
  registered: 'Registrada',
  incomplete: 'Incompleta',
  Recoordinated: 'Recoordinada',
  NotPresent: 'No Presente',
  Unknown: 'Desconocido',
  serviceConfiguration: 'Configuración Servicios',
  mustSelectRow: 'Debe seleccionar una fila',
  selectRow: 'Seleccionar Fila',
  selectService: 'Seleccionar el Servicio',
  selectSpecialField: 'Campo especial de fila',
  showRowCounter: 'Mostrar persona en fila',
  showRowCounterText: 'Texto cantidad de personas en fila',
  requestPaternalLastName: 'Solicitar apellido paterno',
  paternalLastNameRequired: 'Apellido paterno requerido',
  requestMaternalLastName: 'Solicitar apellido materno',
  maternalLastNameRequired: 'Apellido materno requerido',
  displayKeyboard: 'Mostrar teclado',
  displayPersonTurn: 'Mostrar turno de la persona ',
  displayHowMuchIsLeftBefore: 'Mostrar cuánto falta antes',
  saveButtons: 'Guardar botones',
  kioskCreation: 'Creación de kiosco',
  screenMapping: 'Asignación de pantallas',
  phoneRequired: 'Teléfono es requerido',
  center: 'Centro',
  right: 'Derecha',
  left: 'Izquierda',
  activeLocation: 'Localización Activa',
  assignMacAddress: 'Asignar Mac Address',
  enterTheMacAddress: 'Favor de Ingresar la(s) MAC Address',
  cantDoSearchHigherThanDays: 'No se puede realizar búsqueda de más 1 mes.',
  cantDoSearchGreaterThanDays:
    'No se pueden realizar búsquedas con la fecha desde mayor que la fecha hasta',
  noRecordWasFound: 'No se encontró ningún registro.',
  items: 'Elementos',
  item: 'Elemento',
  macAddressError: 'Debe de haber por lo menos un MAC Address.',
  macAddressExits: 'Dirección MAC ingresada ya existe en el sistema.',
  macAddressGeneralError: 'Hubo un error en el listado de Mac Address.',
  macAddressRepeated: 'Mac Address Repetido.',
  cancelSpaceConfimation: '¿Está seguro que desea cancelar este procedimiento?',
  clone: 'Clonar',
  copyTexts: 'Copiar textos',
  copyLogos: 'Copiar logos',
  copyImages: 'Copiar imágenes',
  copyColors: 'Copiar colores',
  kioskClonedSuccessfully: 'kiosco clonado con éxito',
  noKioskFoundForFilter:
    'No se encontró kiosco según los filtros seleccionados',
  success: 'Éxito',
  '100Lightest': '100 - Más ligero',
  '400Normal': '400 - Normal',
  '700Bold': '700 - Negrita',
  '900Boldest': '900 - Fuerte',
  pwaName: 'Nombre del PWA',
  pwaRegistered: 'PWAs Registrados',
  showStartMessage: 'Mostrar mensaje de inicio',
  showEnglish: 'Ver en ingles',
  showTakeTurnButton: 'Mostrar botón de tomar turno',
  mainColors: 'Colores principales',
  headerText: 'Texto del encabezado ESP',
  headerTextEN: 'Texto del encabezado ENG',
  buttonBorderColor: 'Color del borde del botón',
  checkInPrimaryText: 'Texto principal botón Turnos - ChekIn ESP',
  checkInPrimaryTextEN: 'Texto principal botón Turnos - ChekIn ENG',
  checkInSecondaryText: 'Texto secundario botón Turnos - ChekIn ESP',
  checkInSecondaryTextEN: 'Texto secundario botón Turnos - ChekIn ENG',
  followPrimaryText: 'Texto principal botón Seguir Turno ESP',
  followPrimaryTextEN: 'Texto principal botón Seguir Turno ENG',
  followSecondaryText: 'Texto secundario botón Seguir Turno ESP',
  followSecondaryTextEN: 'Texto secundario botón Seguir Turno ENG',
  appointmentPrimaryText: 'Texto principal botón Sacar Cita ESP',
  appointmentPrimaryTextEN: 'Texto principal botón Sacar Cita ENG',
  appointmentSecondaryText: 'Texto secundario botón Sacar Cita ESP',
  appointmentSecondaryTextEN: 'Texto secundario botón Sacar Cita ENG',
  iconColor: 'Color de iconos',
  mainTextColor: 'Color texto principal',
  secondaryTextColor: 'Color texto secundario',
  linksColor: 'Color de enlaces',
  mainScreensTextColor: 'Color texto de principal de pantallas',
  requiresReferralToAppointments: 'Mostrar botón tomar cita',
  showing: 'Mostrando',
  results: 'resultados',
  to: 'al',
  noPwaFoundForFilter: 'No se encontró PWAs según los filtros seleccionados',
  pwaCreation: 'Creación de PWA',
  pwaClonedSuccessfully: 'PWA clonado con éxito',
  systemDoesNotLetMore: 'El sistema no permite más de',
  spaceAmount: 'cantidad de espacios',
  dateLimitExceeded: 'Ha pasado el límite de fecha',
  preview: 'Previsualización',
  previewView: 'Vista Previa',
  defaultKiosk: 'Kiosko por defecto',
  massCreateUsers: 'Crear usuarios en masa',
  noUserFoundForFilter:
    'No se encontró usuario según los filtros seleccionados',
  assignOffice: 'Asignar Oficina',
  mobile: 'Celular',
  mobileNumber: 'Número de celular',
  securityProfile: 'Perfil de seguridad',
  roles: 'Roles',
  filterRole: 'Filtrar Rol',
  roleName: 'Nombre del Rol',
  listOfRoles: 'Listado de Roles',
  userName: 'Usuario',
  active: 'Activo',
  blocked: 'Bloqueado',
  userslist: 'Listado de Usuarios',
  users: 'Usuarios',
  filterusers: 'Filtrar Usuarios',
  filterkiosks: 'Filtrar Kioscos',
  pwas: 'PWAs',
  filterpwas: 'Filtrar PWAs',
  usermaintenance: 'Mantenimiento de Usuario',
  kioskmaintenance: 'Mantenimiento de Kioscos',
  pwamaintenance: 'Mantenimiento de PWAs',
  parametermaintenance: 'Mantenimiento de Parámetros',

  // Start: These items are linked to roles, cannot be camel cased
  roleadministrator: 'Administrador',
  rolecitas: 'Usuario citas',
  roleclient: 'Supervisor',
  roleclientautologin: 'Auto-Registro',
  roleclientlist: 'Registro',
  roleclientuser: 'Usuario cliente',
  roledisplayuser: 'Usuario para el display',
  rolelocaluseradministrator: 'Administrador usuario local',
  rolesalesuser: 'Usuario para ventas',
  rolesponsoruser: 'Usuario para anunciantes',
  rolesystemadmin: 'Administrador de sistema',
  roleuseradmin: 'Administrador de usuarios',
  rolewebuser: 'Ciudadano',
  rolemonitor: 'Monitor',
  // End

  noCompany: 'No Compañía',
  noOffice: 'No Oficina',
  headerLogoImage: 'Imagen del logo',
  headerSecondaryText: 'Texto secundario ESP',
  headerSecondaryTextEN: 'Texto secundario ENG',
  headerSecondaryTextColor: 'Color del texto secundario',
  createuser: 'Crear Usuario',
  edituser: 'Editar Usuario',
  userinformation: 'Información de Usuario',
  role: 'Rol',
  specialid: 'ID especial',
  userparameters: 'Parámetros de Usuario',
  temporalpassword: 'Contraseña temporal',
  receiptappointmentconfirmation: 'Recibe confirmación citas',
  appointmentNumberuser: 'Usuario de Citas',
  activeaccount: 'Cuenta Activa',
  blockedaccount: 'Cuenta Bloqueada',
  tempPassword: 'Contraseña temporal',
  receiveappointmentconfirmation: 'Recibe confirmación de cita',
  appointmentUser: 'Usuario de citas',
  usernamerequired: 'Nombre de usuario es requerido',
  passwordrequired: 'Contraseña es requerida',
  namerequired: 'Nombre es requerido',
  mustselectrole: 'Debe seleccionar un rol',
  mustselectsecurityprofile: 'Debe seleccionar un perfil de seguridad',
  specialidrequired: 'ID especial es requerido',
  usertemplate: 'Plantilla de usuarios',
  loadcsv: 'Cargar CSV',
  proceedToSaveAreYouSure:
    'Se procederá ahora a guardar la información detallada en esta pantalla. ¿Está seguro de que desea continuar? Si desea editar la información, presione anterior.',
  pleaseSelectOptionsSearch:
    'Favor de seleccionar las opciones y presionar el botón ‘Buscar’ para ver los espacios disponibles para su cita.',
  previousAppointmentFound:
    'Este ciudadano ya tiene una cita previa para el mismo servicio solicitado',
  buttonOrder: 'Orden del botón',
  order: 'Orden',
  changeRole: 'Cambiar rol',
  changeRoleToOffice: 'Cambiar a rol oficina',
  emailRequired: 'Email es requerido',
  monitors: 'Monitores',
  monitorsRegistered: 'Monitores Registrados',
  filterMonitors: 'Filtrar Monitores',
  monitorName: 'Nombre del Monitor',
  noMonitorsFoundForFilter:
    'No se encontró Monitores según los filtros seleccionados',
  viewMacAddress: 'Ver MacAddress',
  monitorCreation: 'Creación de monitor',
  parameters: 'Parámetros',
  voiceOnMonitor: 'Voz en monitor',
  showName: 'Mostrar nombre',
  showTurnNumber: 'Mostrar número de turno',
  videoVolume: 'Volumen de vídeos',
  ringVolume: 'Volumen de timbre (voz)',
  displayFollowUpMessage: 'Mostrar mensaje de seguimiento',
  sampleTown: 'Mostrar Pueblo',
  showLiabilityRelease: 'Mostrar relevo de responsabilidad',
  displayMessageOnMonitorBackground: 'Mostrar mensaje en fondo monitor',
  messageOnMonitorBackground: 'Mensaje de fondo del monitor',
  monitorStyles: 'Estilos de Monitor',
  headerPrimaryLogo: 'Logo principal izquierda',
  textSize: 'Tamaño del texto',
  rightSecondaryLogo: 'Logo secundario derecho',
  wallpaperColor: 'Color de fondo de pantalla',
  callbar: 'Barra de llamada',
  numberTextColor: 'Color texto número',
  nameTextColor: 'Color de texto nombre',
  stationTextColor: 'Color texto estación',
  sideTurns: 'Turnos laterales',
  editTurn: 'Editar Turno',
  headerRowBackgroundColor: 'Color del fondo encabezado fila',
  headerRowTextColor: 'Color del texto encabezado fila',
  turnContentBackgroundColor: 'Color del fondo contenido turno',
  shiftNumberColor: 'Color del número de turno',
  stationColor: 'Color de estación',
  turnNumberColor: 'Color del número de turno',
  adsContainer: 'Contenedor anuncios',
  monitorFoot: 'Pie de monitor',
  pleaseEnterDisplayName:
    '¿Está seguro que desea borrar el monitor?, Para borrarlo favor de escribir la palabra ',
  inTheBox: 'en el encasillado',
  sideTurnBorderColor: 'Color del borde',
  adLiabilityReleaseTextColor: 'Color de texto relevo de responsabilidad',
  footerBackgroundColor: 'Color de fondo',
  displayType: 'Tipo de Monitor',
  displayNameIsNotCorrect: 'El nombre del monitor no es correcto.',
  nameColor: 'Color del nombre',
  pleaseEnterMonitorName: 'Favor de ingresar nombre del monitor',
  fileRequired: 'El archivo es requerido',
  assignUserToOffices: 'Asignar Usuario a Oficinas',
  listOfOffices: 'Listado de oficinas',
  onlyLocationsThatDoNotHaveAPWA:
    'Solo se mostrarán las localidades que no cuentan con una configuración de PWA',
  saveClose: 'Guardar & cerrar',
  userParameters: 'Parámetros de usuario',
  userIdentifier: 'Identificador de usuario',
  assignUser: 'Asignar usuario',
  assignTicket: 'Asignar ticket',
  toOffices: 'a oficinas',
  maxImageSize:
    'La imagen no cumple con la dimensiones correspondientes, debe ser de máximo',
  fontSize: 'Tamaño de letra',
  saveSuccessful: 'Guardado exitoso',
  operationCompleted: 'Operación completada',
  displayClonedSuccessfully: 'Monitor clonado con éxito',
  DisplayMessageCost: 'Mostrar costo por mensaje',
  listOfParameters: 'Listado de Parámetros',
  displayNameOrNumberRequired:
    "Debe marcar al menos una opción entre 'Mostrar Nombre' y 'Mostrar Turno'",
  key: 'Clave',
  value: 'Valor',
  description: 'Descripción',
  appointmentplusmaintenance: 'Mantenimiento de Citas Plus',
  nameApptPlus: 'Nombre Citas Plus',
  appointmentPlusName: 'Nombre Citas Plus',
  listOfAppointmentPlus: 'Listado de Citas Plus',
  noAppointmentPlusFoundForFilter:
    'No se encontró citas plus según los filtros seleccionados',
  pleaseEnterTheName: 'Por favor ingrese el nombre',
  nameIsNotCorrect: 'El nombre no es correcto',
  areYouSureYouWantToDelete: '¿Está seguro que desea borrar?',
  successfullyDeleted: 'Borrado con éxito',
  pleaseEnterParameterKey: 'Favor de ingresar clave del parámetro',
  pleaseEnterParameterValue: 'Favor de ingresar valor del parámetro',
  pleaseEnterParameterDescription:
    'Favor de ingresar descripción del parámetro',
  completeParameterInformation: 'Completar información de parámetros',
  contentTitle: 'Título del contenido',
  proceedToSave:
    'Se procederá ahora a guardar la información detallada en esta pantalla. ¿Está seguro de que desea continuar?',
  parameterKey: 'Key de parámetro',
  parameterKeyIsNotCorrect: 'El key del parámetro no es correcto',
  kioskNameIsNotCorrect: 'El nombre del kiosco no es correcto',
  pwaNameIsNotCorrect: 'El nombre del PWA no es correcto',
  plusClientNameIsNotCorrect:
    'El nombre del cliente de Citas Plus no es correcto',
  toDeleteWriteTheWord: 'Para borrarlo favor de escribir la palabra',
  toCloseWriteTheNumber: 'Para cerrarlo favor de escribir el número',
  specialFields: 'Campos especiales',
  continueButtonText: 'Texto botón continuar',
  keyboardType: 'Tipo de teclado',
  numeric: 'Numérico',
  alphanumeric: 'Alfanumérico',
  valueDoesNotMatch: 'El valor no coincide con el sistema legacy',
  plusClientCreation: 'Creación de citas plus',
  plusClientWebConfiguration: 'Configuración web',
  plusClientMobileConfiguration: 'Configuración móvil',
  plusClientPersonalizedFields: 'Campos personalizados',
  plusClientPreloadService: 'Servicio viene pre problado',
  plusClientAllowServiceChange: 'Permite cambio de servicio',
  plusClientAllowSubService: 'Permite sub servicio',
  plusClientAllowCitySelection: 'Permite seleccionar pueblo',
  plusClientDisplayOnlyWithAvailableSpaces:
    'Mostrar solo oficinas con espacios disponibles',
  plusClientAllowDocumentUpload: 'Carga de documento',
  plusClientClonedSuccessfully: 'Cliente Citas Plus clonado con éxito',
  only3FieldsCanBeConfiguredPerScreen:
    'Solo se puede configurar 3 campos por pantalla',
  blurRate: 'Tasa de desenfoque',
  headerBackgroundColor: 'Color de fondo del encabezado',
  headerBottomBorderColor: 'Color del borde inferior encabezado',
  headerTextColor: 'Color del texto del encabezado',
  headerTextSize: 'Tamaño del texto encabezado',
  buttonBackgroundColor: 'Color del fondo del botón',
  buttonFontColor: 'Color de la letra del botón',
  buttonFontSize: 'Tamaño de la letra de botón',
  buttonBorderSize: 'Tamaño del borde del botón',
  modalButtonBackgroundColor: 'Color del fondo botón del modal',
  modalButtonFontColor: 'Color de letra botón del modal',
  modalButtonFontSize: 'Tamaño de la letra botón del modal',
  modalButtonBorderColor: 'Color del borde botón del modal',
  modalButtonBorderSize: 'Tamaño del borde botón del modal',
  tableButtonBackgroundColor: 'Color del fondo botón de la tabla',
  tableButtonFontColor: 'Color de letra botón de la tabla',
  tableButtonFontSize: 'Tamaño de la letra botón de la tabla',
  tableButtonBorderColor: 'Color del borde botón de la tabla',
  tableButtonBorderSize: 'Tamaño del borde botón de la tabla',
  headerSection: 'Sección de encabezado',
  buttonsSection: 'Sección de botones',
  controlsSection: 'Sección de controles',
  calendarSection: 'Sección de calendario',
  tablesSection: 'Sección de tablas',
  footerSection: 'Sección pie de página',
  backgroundColorElement: 'Color del fondo elemento',
  elementTextColor: 'Color del texto del elemento',
  elementTextSize: 'Tamaño texto del elemento',
  elementBorderColor: 'Color del borde del elemento',
  selectedDateBackgroundColor: 'Color fondo fecha seleccionada',
  textBackgroundColorSelectedDate: 'Color fondo texto fecha seleccionada',
  rowColor: 'Color de la fila',
  alternateRowColor: 'Color de la fila alterna',
  completedIconColor: 'Color del icono de completado',
  clear: 'Limpiar',
  shapeTextColor: 'Color texto de la forma',
  controlColor: 'Color del control',
  controlTextSize: 'Tamaño texto del control',
  controlLabelColor: 'Color de etiqueta del control',
  boldPercentOfTheField: 'Porciento de negrita del campo',
  fieldBackgroundColor: 'Color del fondo del campo',
  hypertextColor: 'Color del hipertexto',
  colorOfTheSelectedCheckbox: 'Color del checkbox seleccionado',
  checkboxColor: 'Color del checkbox',
  boldPercentOfModalField: 'Porciento de negrita del campo modal',
  controlIconColor: 'Color del icono de control',
  modalFieldLetterSize: 'Tamaño letras del campo modal',
  modalFieldFontColor: 'Color de letra del campo modal',
  modalFieldBackgroundColor: 'Color del fondo del campo modal',
  fieldLetterSize: 'Tamaño letras del campo',
  fieldFontColor: 'Color del fondo del campo',
  copyWebStyle: 'Copiar estilo de web',
  cleanStyle: 'Limpiar estilo',
  doYouWantToRemoveAllStyles:
    '¿Está Seguro que desea limpiar los campos? esta acción es irreversible.',
  contentBackgroundColor: 'Color de fondo del contenido',
  contentTitleFontSize: 'Tamaño del titulo del contenido',
  boldPercentOfTheTitle: 'Porciento de negrita del titulo',
  titleFontColor: 'Color del titulo',
  contentSection: 'Sección de contenido',
  documentUploadBackgroundColor: 'Color de fondo de carga de documentos',
  documentUploadFontColor: 'Color de fuente de carga de documentos',
  documentDownloadBackgroundColor: 'Color de fondo de descarga de documentos',
  documentDownloadFontColor: 'Color de fuente de descarga de documentos',
  rowsPerPage: 'Filas por página',
  roleCreation: 'Creación de roles',
  assignPermission: 'Asignar permiso',
  userCreation: 'Creación de usuario',
  userEdit: 'Edición de usuario',
  permissions: 'Permisos',
  usersMaintenance: 'Mantenimiento de usuarios',
  userCompleteParameterInformation: 'Debe seleccionar al menos 2 parámetros',
  activeAccount: 'Cuenta activa',
  blockedAccount: 'Cuenta bloqueada',
  roleInformation: 'Información del rol',
  roleMaintenance: 'Mantenimiento de rol',
  rolesMaintenance: 'Mantenimiento de roles',
  employeeStatus: 'Estatus de Empleado',
  timeOfBreak: 'Tiempo de receso',
  generate: 'Generar',
  lastActivityDate: 'Fecha De Última Actividad',
  lastStation: 'Última Estación',
  lastRow: 'Última Fila',
  statusTimeMin: 'Estatus Tiempo (Min)',
  type: 'Tipo',
  approveOrDenyBreak: 'Aprobar o Denegar Receso',
  beginningOfBreak: 'Comienzo del Receso',
  breakReason: 'Razón del receso',
  maximumTime: 'Tiempo Máximo',
  timeOverBreak: 'Tiempo Sobre el Receso',
  technicalSetup: 'Configuración de técnico',
  operationLog: 'Bitácora de Operación',
  operationAdministrativeLog: 'Bitácora Administrativa de Operación',
  closeTechnicianSection: 'Cerrar sesión del técnico',
  callTurn: 'Llamar Turno',
  endTurn: 'Finalizar Turno',
  transferTurn: 'Transferir Turno',
  placeInHold: 'Colocar en Hold',
  placeInRecess: 'Colocar en Receso',
  notPresent: 'No Presente',
  attending: 'Atendiendo',
  openStations: 'Estaciones Abiertas',
  breaks: 'Recesos',
  takeABreak: 'Tomar un Receso',
  customerToAttend: 'Clientes por Atender',
  aboutWaitingTime: 'Exceso tiempo de espera',
  customerAttended: 'Clientes Atendidos',
  roleNameIsNotCorrect: 'El nombre del Rol no es correcto',
  totalTechnicians: 'Total de técnicos',
  totalTechniciansConfigured: 'Total de técnicos configurados',
  totalTechniciansNotConfigured: 'Total de técnicos sin configurar',
  listOfTechnicians: 'Listado de técnicos',
  processLogs: 'Registro de Procesos',
  filterProcessLogs: 'Filtrar Registro de Procesos',
  processStatus_P: 'Pendiente',
  processStatus_R: 'Ejecutando',
  processStatus_S: 'Completado',
  fixedRow: 'Fila fija',
  baseRow: 'Fila base',
  fixedBaseRow: 'Fila fija base',
  station: 'Estación',
  fixedStation: 'Estación fija',
  individualCallAvailable: 'Llamada Individual Disponible',
  viewListOfTurns: 'Visualizar Listado de Turnos',
  removeTurns: 'Eliminar turnos',
  officeRequired: 'La oficina es requerida',
  break: 'Receso',
  cannotCloseSessionUserAttendingClient:
    'Usuario está atendiendo cliente, favor de finalizar el mismo antes de cerrarle la sesión',
  youMustSelectAnOptionOfViewShiftList:
    'Debe seleccionar una opción de visualizar listado de turnos',
  breakType: 'Tipo de Receso',
  call: 'Llamar',
  turnCalledSuccessfully: 'Turno llamado existosamente',
  turnFinalizedSuccessfully: 'Turno finalizado existosamente',
  turnMarkedAsNotPresentSuccessfully:
    'Turno marcado como no presente exitosamente',
  statusCalled: 'Llamado',
  statusUncalled: 'No Llamado',
  subService: 'Sub Servicio',
  turnTransferredSuccessfully: 'Turno Transferido con éxito',
  registeredRepresentative: 'Representantes ingresados',
  employeeStatusList: 'Listado estatus de empleados',
  requestingRecess: 'Solicitando receso',
  excessRecess: 'Exceso de receso',
  currentDashboardData: 'Dashboard Corriente',
  currentStatisticsSummary: 'Resumen Estadístico Corriente',
  statisticsSummary: 'Resumen Estadístico',
  callStatisticsSummary: 'Resumen Estadístico de Llamadas',
  considerTimes: 'Considerar Tiempos 0',
  considerAppointmentDate: 'Considerar Horario de la Cita',
  externalDisplay: 'Pantalla Externa',
  includeWaitingQueues: 'Incluir Filas en Espera',
  dailyList: 'Lista Diaria',
  dailyListByService: 'Lista Diaria por Servicio',
  videoCall: 'Iniciar video llamada',
  currentList: 'Lista Corriente',
  currentSimpleList: 'Lista Corriente Simple',
  realTimeRegionalDashboard: 'Dashboard Regional Tiempo Real',
  refreshReport: 'Refrescar informe',
  totalClients: 'Total de clientes',
  totalAttended: 'Total atendidos',
  totalUnattended: 'Total sin atender',
  transactionsToAttend: 'Transacciones por atender',
  averageWaitingMinutes: 'Promedio minutos espera',
  averageServiceTime: 'Promedio tiempo servicio',
  totalOfficeAverage: 'Promedio total oficina',
  totalOpenStations: 'Total de estaciones abiertas',
  export: 'Exportar',
  localityName: 'Nombre localidad',
  inRecess: 'En receso',
  multiLanguage: 'Multi idioma',
  hasCardReader: 'Lector de Tarjeta',
  displayRowName: 'Mostrar nombre de la fila',
  peopleNotPresent: 'Personas No Presente',
  turnReactivated: 'Turno reactivado con éxito',
  dashboard: 'Dashboard',
  timeFrom: 'Tiempo desde',
  timeTo: 'Tiempo hasta',
  format: 'Formato',
  displayBy: 'Mostrar Por',
  rowCategories: 'Categoría de Filas',
  serviceCategory: 'Categoría de Servicios',
  extendedStatisticsReport: 'Informe Estadístico Extendido',
  breaksTakenByEmployee: 'Recesos Tomados por Empleado',
  deletedTurnsReport: 'Informe de Turnos Eliminados',
  employeeHistory: 'Histórico de Empleados',
  statisticsSummaryMultiOffice: 'Resumen Estadístico Multi-Oficina',
  statisticsSummaryServiceMultiOffice:
    'Resumen Estadístico Servicio Multi-Oficina',
  averageServiceTimePerEmployee: 'Tiempo Promedio de Servicio por Empleado',
  averageTimePerService: 'Tiempo Promedio de Servicio',
  employee: 'Empleado',
  orderBy: 'Ordernar por',
  groupByOffice: 'Agrupar Por Oficina',
  personPerHourByOffice: 'Personas por Hora por Oficina',
  personPerHourServiceByOffice:
    'Personas por Hora por Oficina (Grupo de Servicios)',
  offices: 'Oficinas',
  peopleCited: 'Personas Citadas',
  stampStatus: 'Estatus de Sellos',
  appointmentsStatus: 'Estatus de Citas',
  waitingTimeByOffice: 'Tiempo de Espera por Oficina',
  stampCancelation: 'Cancelación de Sellos',
  averageServiceTimePerQueue: 'Tiempo Promedio por Fila',
  averageServiceTimePerService: 'Tiempo Promedio por Servicio',
  appointmentsAvailabeVsAppointmentsTaken: 'Citas Disponibles vs Citas Tomadas',
  queue: 'Fila',
  responseResultsGraphs: 'Gráficos de Resultados de Respuesta',
  responseResults: 'Resultados de Respuestas',
  answersAndTurnData: 'Resultado de respuestas con comentarios',
  answers: 'Respuestas',
  appointmentRecoodinationAndExport: 'Exportación/Recoordinación Citas',
  emailOrAppointmentNumber: 'Email o Número de Cita',
  selectOfficeForTurnsManager:
    'Debe seleccionar una oficina para poder utilizar el manejador de turnos',
  isBlind: 'No vidente',
  appointmentHour: 'Hora de la cita',
  fieldRequired: 'El campo es requerido',
  searchNotFound: 'No fue encontrado. Su búsqueda no produjo resultado.',
  seeAvailableAppointments: 'Ver citas disponibles',
  recordsLimit: 'Límite de Registros',
  statusCalledNotPresent: 'Llamado no presente',
  reactivated: 'Reactivado',
  print: 'Imprimir',
  checkPrinter:
    'Por favor, compruebe si una impresora compatible con ZPL está configurada como predeterminada',
  turnUnCalled: 'Turno sin llamar',
  notCalled: 'Sin Llamar',
  called: 'Llamado',
  turnMarkedNotPresent: 'Turno marcado no presente',
  turnWithStamp: 'Turno con sellos',
  turnCalled: 'Turno llamado',
  turnAttended: 'Turno atendido',
  appointmentForToday: 'Citados para hoy',
  appointmentTime: 'Hora de la cita',
  noAppointmentsAvailable: 'No hay citas disponibles para el día de hoy',
  searchCited: 'Buscar citados por nombre',
  turnManager: 'Manejador de Turnos',
  rowCall: 'Fila Llamada',
  advanceRow: 'Avanzar fila',
  advanceStation: 'Avanzar estación',
  turnsQuantity: 'Cantidad de turnos',
  clientsQuantity: 'Cantidad de clientes',
  customersServed: 'Clientes atendidos',
  transactionsServed: 'Transacciones atendidas',
  totalTransactions: 'Total Transacciones',
  takeTurn: 'Tomar Turno',
  reassignToAnotherRow: 'Reasignar a otra fila',
  moveToAnotherRow: 'Mover a otra fila',
  finalizeTurn: 'Finalizar Turno',
  ok: 'OK',
  errorCreatingList: 'Error creando la lista',
  singleCall: 'Llamada Individual',
  removeTurn: 'Eliminar turno',
  reprint: 'Re-imprimir',
  surveys: 'Encuestas',
  turnNumberIsNotCorrect: 'El número de turno no es correcto.',
  callWithMSTeams: 'Llamar con Microsoft Teams®',
  showDocuments: 'Ver documentos',
  instantCall: 'Llamada al instante',
  editTurnInfo: 'Editar información del turno',
  attendeds: 'Atendidos',
  attendedsF: 'Atendidas',
  notAttended: 'No Atendidos',
  notAttendedF: 'No Atendidas',
  total: 'Total',
  transactions: 'Transacciones',
  allTurnsWereAttended: 'Todos los turnos han sido atendidos.',
  reCall: 'Volver a llamar',
  statistics: 'Estadísticas',
  serviceTotalTime: 'Tiempo Total de Servicio',
  reassign: 'Reasignar',
  selectMotive: 'Seleccione un motivo',
  commentRequired: 'El comentario es requerido',
  commentMinLength: 'El comentario debe tener un mínimo de {0} caracteres',
  commentMaxLength: 'El comentario debe tener máximo {0} caracteres',
  selectOfficeForSystem:
    'Debe seleccionar una oficina para poder utilizar el sistema',
  errorLogginIntoOffice: 'Error al loguearse en la oficina',
  breakNotification:
    'Al finalizar el turno, el sistema lo sacará automáticamente por el receso solicitado - {0}',
  breakNotificationHtml:
    'Al finalizar el turno, el sistema lo sacará automáticamente por el receso solicitado <br/><br/><h4 class="red">{0}</h4>',
  notification: 'Notificación',
  breakExistMessage: 'Usted inició un receso a las',
  timeInBreak: 'Tiempo en receso',
  continueWorking: 'Continuar Trabajando',
  cantChangeLocationActiveTurn:
    'No puedes cambiar de ubicación si tienes un turno activo',
  cantChangeLocationActiveWaitingRoom:
    'No puedes cambiar de ubicación si tienes una sala de espera activa',
  youMustEndTurnToLogOut: 'Debe finalizar el turno para poder cerrar sesión',
  invalidActiveTurn: 'Turno activo inválido',
  noAlertsAvailable: 'No hay alertas disponibles',
  residenceZIPCode: 'Código Postal de Residencia',
  ethnicity: 'Etnicidad',
  male: 'Masculino',
  female: 'Femenino',
  other: 'Otro',
  stationNameIsNotCorrect: 'El nombre de la estación no es correcto',
  listOfStations: 'Listado de Estaciones',
  stationsMaintenance: 'Mantenimiento de Estaciones',
  filterStations: 'Filtro de Estaciones',
  stationName: 'Nombre de estación',
  selectOfficeForTheMaintenance:
    'Debe seleccionar una oficina para poder utilizar el mantenimiento',
  createStation: 'Crear estación',
  stationMaintenance: 'Mantenimiento de estacione',
  stationInformation: 'Información de la estación',
  mustUpdateTheRoleToModifyPermissions:
    'Debe actualizar el rol para poder modificar los permisos',
  update: 'Actualizar',
  extraField: 'Campo Adicional',
  block: 'Bloquear',
  unlock: 'Desbloquear',
  rolePermission: 'Permiso del rol',
  addedPermission: 'Permiso agregado',
  currentPassword: 'Contraseña actual',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  newPasswordDifferentCurrent:
    'La nueva contraseña debe ser diferente a la actual',
  pageBackgroundColor: 'Color de Fondo de la Página',
  footerBackgroundTopColor: 'Color superior de fondo',
  footerBackgroundBottomColor: 'Color inferior de fondo',
  footerFontTopColor: 'Color superior de letra',
  footerFontBottomColor: 'Color inferior de letra',
  mustBeAtLeast2Chars: 'Deben ser por lo menos 2 caracteres',
  selectOneStation: 'Seleccione una Estación',
  stationsName: 'Nombre de las estaciones',
  serviceQueueMaintenance: 'Mantenimiento de Filas',
  officeMaintenance: 'Mantenimiento de Oficinas',
  serviceQueueGroupMaintenance: 'Mantenimiento de Grupo de Filas',
  filterServiceQueues: 'Filtro de filas',
  filterOffices: 'Filtro de oficinas',
  filterServiceQueueGroups: 'Filtro de grupo de filas',
  serviceTypeGroupMaintenance: 'Mantenimiento de Grupo de Servicios',
  appointmentServiceTypeGroupMaintenance:
    'Mantenimiento de Grupo de Servicios de Cita',
  filterServiceTypeGroups: 'Filtro de grupo de servicios',
  filterAppointmentServiceTypeGroups: 'Filtro de grupo de servicios de cita',
  listOfServiceQueues: 'Listado de Filas',
  serviceQueueName: 'Nombre de fila',
  serviceQueueGroupName: 'Nombre del grupo',
  serviceQueueNumber: 'Número de fila',
  automaticCall: 'Llamada automática',
  serviceQueueToMove: 'Filas a mover',
  daysAvailable: 'Días hábiles',
  hoursAvailable: 'Horas hábiles',
  autoregisterConfiguration: 'Configuración autoregistro',
  kioskSpecialField: 'Campo Especial Kiosco',
  footerButtonBackgroundColor: 'Color de Fondo de Botón Inferior',
  footerButtonTextColor: 'Color de Texto de Botón Inferior',
  unlocked: 'Desbloqueado',
  location: 'Localización',
  locations: 'Localidades',
  serviceMaintenance: 'Mantenimiento de Servicios',
  filterServices: 'Filtro de servicios',
  listOfServices: 'Listado de Servicios',
  nameOfServiceESP: 'Nombre de servicio [ESP]',
  nameOfServiceENG: 'Nombre de servicio [ENG]',
  autoregisterNameOfServiceESP: 'Nombre servicio AutoRegistro [ESP]',
  autoregisterNameOfServiceENG: 'Nombre servicio AutoRegistro [ENG]',
  isCommentRequired: '¿Comentario es requerido?',
  waitingTimeInMinutes: 'Tiempo de espera en minutos',
  attentionGoalTime: 'Tiempo meta de atención',
  autoregistrationOrder: 'Orden AutoRegistro',
  availableHoursForService: 'Horas hábiles para servicio',
  kioskHoursForService: 'Horas Kiosco para servicio',
  availableDaysForService: 'Días hábiles para servicio',
  serviceName: 'Nombre de servicio',
  selfRegistrationServiceName: 'Nombre de servicio autorregistro',
  waitTimeInMinuts: 'Tiempo de espera en minutos',
  targetAttentionTime: 'Tiempo meta de atención',
  orderInSelfregistration: 'Orden en autoregistro',
  commentIsRequired: 'Comentario es requerido',
  businessDays: 'Días hábiles de trabajo',
  businessHours: 'Horario hábiles de trabajo',
  businessHoursKiosk: 'Horario hábiles de kiosco',
  copyBusinessHours: 'Copiar horario de trabajo',
  copyWorkingDays: 'Copiar días de trabajo',
  copyAttentionTargetTime: 'Copiar tiempo meta de atención',
  copyGoalWaitTime: 'Copiar tiempo meta de espera',
  cloneService: 'Clonar servicio',
  exit: 'Salir',
  sessionAboutToExpireTitle: 'Sesión a punto de expirar',
  sessionAboutToExpireContent:
    'La sesión actual está a punto de caducar. Pulse <b>Continuar</b> si no desea cerrar la sesión.',
  sessionWillExpireIn: 'La sesión va a expirar en',
  serviceQueueStartingLetter: 'Letra de comienzo de la fila',
  serviceQueueStartingNumber: 'Número de comienzo de la fila',
  createServiceQueue: 'Crear fila',
  createServiceQueueGroup: 'Crear grupo de fila',
  editServiceQueueGroup: 'Editar grupo de fila',
  createServiceTypeGroup: 'Crear grupo de servicio',
  editServiceTypeGroup: 'Editar grupo de servicio',
  automaticServiceQueuesMaintenance: 'Mantenimiento de filas automáticas',
  automaticServiceQueuesMaintenanceMessage:
    'Filas para llamada automática cuando no haya más personas en la fila',
  serviceQueueConfiguration: 'Configuración de la fila',
  serviceQueueAssociatedServices: 'Servicios asociados a fila',
  moveToAssociatedQueues: 'Mover a filas asociadas',
  daysAvailableForWork: 'Días hábiles de trabajo',
  workingHours: 'Horario hábiles de trabajo',
  autoregisterConfiguration2: 'Configuración de autoregistro',
  publicAliases: 'Alias público',
  cloneQueue: 'Clonar fila',
  queueNameIsNotCorrect: 'El nombre de la fila no es correcto.',
  groupNameIsNotCorrect: 'El nombre del grupo no es correcto.',
  domainNameIsNotCorrect: 'El nombre del dominio no es correcto.',
  serviceNameIsNotCorrect: 'El nombre del servicio no es correcto.',
  publicAlias: 'Alias público',
  colorInScreen: 'Color en pantalla',
  autoSequential: 'Auto secuencial',
  visibleInRegistry: 'Visible en registro',
  visibleInHandler: 'Visible en manejador',
  canAssignToAnotherUser: 'No puede asignar a otro usuario',
  considerVisitTo: 'Considera visita a',
  waitingQueue: 'Fila en espera',
  commentRequired2: 'Comentario requerido',
  videoCall2: 'Video llamada',
  transferVisitTo: 'Transferencia visita a',
  instantRecord: 'Record instantáneo',
  totalTurnsPerQueue: 'Total de turnos por fila',
  alertWhenX: 'Alerta con X turno de anticipación',
  displayInMonitor: 'Mostrar en pantalla',
  associatedQueuesList: 'Listado de Filas Asociadas',
  associatedServicesList: 'Listado de Servicios Asociados',
  all2: 'Todas',

  autoRegisterServiceQueue: 'Fila en autoregistro',
  expressOrRegular: 'Expreso o Regular',
  serviceInAppointment: 'Servicio en Cita',
  activateAlertOption: 'Activar opción de alerta',
  respectCitedHour: 'Respetar horario citado',
  messageSending: 'Envio de mensajes',
  autoRegister: 'Auto registro',
  startingLetter: 'Letra de comienzo',
  userEmail: 'Usuario / Correo',
  breakMaintenance: 'Mantenimiento de Recesos',
  filterBreak: 'Filtrar Recesos',
  listOfBreak: 'Lista de Recesos',
  breakName: 'Nombre de receso',
  breakTime: 'Tiempo de receso (minutos)',
  finalStep: 'Paso final',
  implicit: 'Implicito',
  deleteVisitRelationship: 'Eliminar relación de visita a',
  documentBreak: 'Documentar receso',
  autoApproved: 'Auto aprobado',
  configureLocation: 'Configurar localidad',
  createBreak: 'Crear receso',
  updateBreak: 'Actualizar receso',
  breakNameIsNotCorrect: 'El nombre del receso no es correcto',
  deleteAppointmentSpaces: 'Borrar Espacios de Citas',
  verifyAppointmentSpacesToDelete: 'Citas a borrar',
  appointmentSpacesToDelete: 'Espacios de citas a borrar',
  deletedAppointmentSpaces: 'Citas borradas',
  appointmentsMassiveCancellation: 'Cancelación Masiva de Citas',
  cancellationReason: 'Razón de cancelación',
  listOfGroups: 'Listado de Grupos',
  nameESP: 'Nombre [ESP]',
  nameENG: 'Nombre [ENG]',
  contactUs: 'Contáctenos',
  contactForm: 'Formulario de Contacto',
  message: 'Mensaje',
  sendMessage: 'Enviar Mensaje',
  contactInfornation: 'INFORMACIÓN DE CONTACTO',
  contactinformationRequest: 'Solicitud de Información',
  contactstationName: 'Número telefónico',
  infoMail: 'info@turnosmedia.com',
  infoPhone: ' 787-399-0998',
  organizationsMaintenance: 'Mantenimiento de Organizaciones',
  organizationMaintenance: 'Mantenimiento de Organización',
  organizationFilter: 'Filtro de Organización',
  listOfOrganizations: 'Listado de Organizaciones',
  addQueue: 'Agregar Fila',
  addService: 'Agregar Servicio',
  officeName: 'Nombre de Oficina',
  selection: 'Selección',
  queueSelectionAccordingToOffice: 'Selección de Fila según Oficina',
  serviceSelectionAccordingToOffice: 'Selección de Servicio según Oficina',
  configurationId: 'ID de Configuración',
  disclaimer: 'Disclaimer',
  companiesMaintenance: 'Mantenimiento de compañias',
  filtersCompanies: 'Filtros de Compañias',
  listOfCompanies: 'Listado de Compañias',
  visitFactor: 'Factor de visita',
  workingDaysPerWeek: 'Días de Trabajo por semana',
  workingHoursPerDay: 'Horas de trabajo por día',
  waitingTimeInHours: 'Tiempo de espera en horas',
  finalPaymentDate: 'Fecha final de pago',
  alertDaysBeforeSuspension: 'Días de alerta antes de suspensión',
  alertDaysAfterSuspension: 'Días de alerta despues de suspensión',
  miscellaneous: 'Misceláneo',
  nameMisc: 'Nombre misc',
  coordinatorFilter: 'Filtro de coordinador',
  companyMaintenance: 'Mantenimiento de Compañia',
  companyInformation: 'Información de la compañia',
  otherMembers: 'Otros miembros',
  otherMembersMaintenance: 'Mantenimiento de Otros Miembros',
  filtersMembers: 'Filtros de Miembros',
  listOfMembers: 'Listado de Miembros',
  emailIsNotCorrect: 'Email no es correcto',
  addMembers: 'Agregar miembros',
  errorsList: 'Listado de Errores',
  systemErrors: 'Errores del Sistema',
  systemErrorsLegacy: 'Errores del Sistema Legacy',
  filterSystemErrors: 'Filtros de Errores',
  errorMessage: 'Mensaje de Error',
  errorLocation: 'Ubicación de Error',
  errorDate: 'Fecha del Error',
  pageOfOrigin: 'Página de Origen',
  turnoPRCategory: 'Categoria de TurnosPR',
  exceptionSent: 'Excepción Enviada',
  internalErrorException: 'Excepción Interna',
  errorLevel: 'Nivel de Error',
  officeNameIsNotCorrect: 'El nombre de la oficina no es correcto',
  averageWaitingTimeGauge: 'Tiempo Promedio Espera',
  averageServiceTimeGauge: 'Tiempo Promedio Atención',
  targetAverageTime: 'Tiempo Promedio Meta',
  activate: 'Activar',
  deactivate: 'Desactivar',
  numberOfTurns: 'Cantidad Turnos',
  invalidAppointmentNumber: 'Número de cita inválido',
  headerBackgroundText: 'Texto de fondo del encabezado',
  headerBackgroundSubText: 'Sub Texto de fondo del encabezado',
  headerBackgroundImage: 'Imagen de fondo del encabezado',
  statusUncalledWithStamp: 'No llamado con sello',
  statusAttended: 'Atendido',
  searchClient: 'Buscar cliente',
  officeInformation: 'Información de Oficina',
  officeParameter: 'Parámetro de oficina',
  handlerParameters: 'Parámetros de Manejador',
  printerParameters: 'Parámetros de Impresora',
  kioskParameters: 'Parámetros de Kiosco',
  displayParameters: 'Parámetros de Display',
  surveyModule: 'Módulo de Encuestas',
  appointmentModule: 'Módulo de Citas',
  integrations: 'Integraciones',
  alerts: 'Alertas',
  extraField1100: 'Campos Extras',
  address1200: 'Dirección',
  geographicCoordinates: 'Coordenas Geograficas',
  descriptionServicesOffered: 'Descripción de servicios ofrecidos',
  goLive: 'Go-live',
  officeCategory: 'Categoría de oficina',
  officeType: 'Tipo de oficina',
  schedules: 'Horarios',
  waitingTimePerShiftInMinuts: 'Tiempo de espera por turno en minutos',
  automaticRow: 'Fila Automatica',
  mobileAccess: 'Acceso Móvil',
  createPublicAccount: 'Crear Cuenta para el Publico',
  autoCleanListing: 'Auto-Limpieza de Listado',
  requireTelephone: 'Requerir Teléfono',
  activatePWA: 'Activar PWA',
  askForStation: 'Preguntar por estación',
  activeDeactiveOffice: 'Activar/Desactivar oficina',
  municipality: 'Municipio',
  alertWithXNumberInAdvance: 'Alerta con X número de anticipación',
  automaticRowType: 'Tipo de Fila Automatica',
  alternated: 'Alternado',
  sequential: 'Secuencial',
  recordsPerPage: 'Récords por página',
  autoCloseUserAccounts: 'Auto cierre de cuentas de usuario',
  officeOpeningHours: 'Hora de apertura de oficina',
  officeClosingHours: 'Hora de cierre de oficina',
  callNextAutomatically: 'Llamar próximo automáticamente',
  systemAllocatesNextEqually: 'Sistema asigna próximo equitativamente',
  reassingToRow: 'Reasignar a fila ',
  editCustomerInformationBeingServed:
    'Editar información de cliente siendo atendido',
  readOnlyService: 'Servicio solo lectura',
  minimumTimeToWaitMarkNoShow:
    'Tiempo minimo (en segundos) de espera Marcar no Presente',
  endOfServiceTime: 'Tiempo de terminar servicio ',
  systemAssignsNextAutomatically: 'Sistema asigna próximo automáticamente ',
  recessModule: 'Módulo de receso ',
  individualServiceCounter: 'Contador Individual de servicio ',
  recoordinateAppointment: 'Recoordinar Cita ',
  enablePrinter: 'Activar Impresora',
  printCopies: 'Imprimir copias',
  configurePrinter: 'Configurar impresora',
  allowAppointmentAtKiosk: 'Permitir cita en Kiosco',
  toleranceQuotedTimeToGetTurns: 'Tolerancia tiempo citado para obtener turno',
  chineseVersionKiosk: 'Kiosco versión chino',
  kioskButtonCode: 'Código botón kiosco',
  toleranceQuotedTimeToGetTurn:
    'Tolerancia tiempo citado para obtener turno, en minutos',
  numberOfTurnsToShowWhenTheyAreMissingBeforeYou:
    'Cantidad de turnos para mostrar cuando faltan antes que usted',
  showNameOnDisplay: 'Mostrar nombre en display',
  showNumberOnDisplay: 'Mostrar número en display',
  voiceOnDisplay: 'Voz en display',
  volumeInVideos: 'Volúmen en videos',
  activateSurvery: 'Activar encuesta',
  surveryId: 'ID de Encuestas',
  officeIdInSurverys: 'ID de oficina en encuestas',
  activeAppointments: 'Activar Citas',
  filterTypeOfServicesByNumberOfLoans:
    'Filtrar tipo de servicios por numero de prestamos',
  companionsAllowedPerShift: 'Acompañantes permitidos por Turno',
  etiquetteEscorts: 'Etiqueta Acompañantes',
  activateDigitalCollection: 'Activar Colecturía Digital ',
  askForIdWhenCancelingStamp: 'Preguntar por ID al cancelar sello',
  digitalCollection: 'Colecturía Digital',
  activateSingleSignOn: 'Activar Single Sign On',
  activateMSTeams: 'Activar Microsoft Teams®',
  urbCondResAptdo: 'Urb/Cond/Res/Aptdo',
  numberStreetApt: 'Número, Calle, Apt',
  city: 'Ciudad',
  state: 'Estado',
  country: 'País',
  postalCode: 'Código Postal',
  postalCodeExt: 'Código Postal Ext',
  physicalAddressEqualToPostal: 'Dirección física igual a la postal',
  addressPostal: 'Dirección Postal',
  physicalAddress: 'Dirección Física',
  latitude: 'Latitud',
  longitude: 'Longitud ',
  getCoordinates: 'Obtener Coordenadas',
  activateSpecialField: 'Activar campo especial',
  showInTurnHandler: 'Mostrar en manejador de turnos',
  showMobileAppointments: 'Mostrar citas movil',
  showWebQuotes: 'Mostrar citas web',
  showMobileTurns: 'Mostrar turnos movil',
  specialFieldName: 'Nombre campo especial',
  required: 'Requerido',
  values: 'Valores',
  miscellaneousFieldValues: 'Valores del campo misceláneo',
  localAlertsConfiguration: 'Configuración de alertas del local',
  alert: 'Alerta',
  printerSetup: 'Configuración de impresora',
  selectThePrinterMarkedByDefault:
    'Seleccionar la impresora marcada por defecto',
  selectTheSpecificPrinter: 'Seleccionar la impresora en específico',
  searchPrinter: 'Buscar impresora',
  selectedPrinter: 'Impresora Selecccionada',
  notifications: 'Notificaciones',
  officeNotifications: 'Notificaciones de oficina',
  maintenanceOfTypeOfServices: 'Mantenimiento de Tipo de Servicios',
  typeOfServicesFilter: 'Filtro de Tipo de Servicios',
  visibleToThePublic: 'Visible para el público',
  warning: 'Aviso',
  requireEmail: 'Requerir Correo Electrónico',
  requirePhone: 'Requerir Teléfono',
  requireComment: 'Requerir Comentario',
  hideComment: 'Ocultar Comentario',
  youCanAttachDocument: 'Puede Adjuntar Documento',
  inactive: 'Inactivo',
  listOfTypeOfServices: 'Listado de Tipo de Servicios',
  maintenanceOfTypeOfServicesByOffice:
    'Mantenimiento de Tipo de Servicios por Oficina',
  typeOfServicesByOfficeFilter: 'Filtro de Tipo de Servicios por Oficina',
  listOfTypeOfServicesByOffice: 'Listado de Tipo de Servicios por Oficina',
  warningMessage: 'Mensaje de aviso',
  history: 'Historial',
  historyTitle: 'Historial de Cambio de Cuenta de Usuario',
  historySubTitle: 'Información de Cambio de Cuentas Usuarios',
  eventDate: 'Fecha de Evento',
  changedField: 'Campo cambiado',
  valueBeforeChange: 'Valor Antes del Cambio',
  valueAfterChange: 'Valor Despues del Cambio',
  userMakingChange: 'Usuario que Realiza Cambio',
  locationSchoolId: 'ID de Oficina en Encuestas',
  surveyType: 'Tipo de Encuesta',
  surveyId: 'ID Encuesta',
  surveyName: 'Nombre de la Encuesta',
  surveyList: 'Listado de Encuestas',
  surveyIdentifierMaintenance: 'Identificador de Encuentas en Oficina',
  filterSurvey: 'Filtrar Encuestas',
  securityProfileInformation: 'Información del Perfil de Seguridad',
  securityProfileMaintenance: 'Mantenimiento de Perfil de Seguridad',
  securityProfilesMaintenance: 'Mantenimiento de Perfiles de Seguridad',
  securityProfileNameIsNotCorrect:
    'El nombre del Perfil de Seguridad no es correcto',
  securityProfiles: 'Perfiles de Seguridad',
  filterSecurityProfile: 'Filtrar Perfil de Seguridad',
  securityProfileName: 'Nombre del Perfil de Seguridad',
  listOfSecurityProfiles: 'Listado de Perfiles de Seguridad',
  minimalCharacters: 'Caracteres Mínimos',
  validityDayPassword: 'Días para Cambiar la Contraseña',
  needLowerCaseLetterFlag: 'Letras Minúsculas',
  needUpperCaseLetterFlag: 'Letras Mayúsculas',
  needNumberFlag: 'Número',
  needSpecialCharactersFlag: 'Caracter Especial',
  failedAttempsAllow: 'Número de Intento de Ingreso',
  unlockDatabase: 'Desbloquear la Base de Datos',
  dateProcess: 'Fecha de Proceso',
  lastProcess: 'Último Proceso',
  totalCellPhones: 'Total de Celulares',
  totalEmails: 'Total de Emails',
  totalClientsDashboard: 'Total de Clientes',
  subcode: 'Sub código',
  path: 'Ruta',
  domain: 'Dominio',
  maintenanceOfLogos: 'Mantenimiento de logos',
  filterLogos: 'Filtrar logos',
  listOfLogos: 'Listado de logos',
  schedulesForFrequencyOfSendingExceptions:
    'Horarios para frecuencia de envío de excepciones',
  schedulesForFrequencyOfSendingException:
    'Horarios para frecuencia de envío de excepción',
  exceptionSubmissionSchedule: 'Horario de envíos de excepción',
  listOfSchedules: 'Listado de horarios',
  category: 'Categoría',
  findAddress: 'Buscar dirección',
  enterLocation: 'Introduce una ubicación',
  selectAddress: 'Seleccionar dirección',
  adsMaintenance: 'Mantenimiento de anuncios',
  filterAds: 'Filtro de anuncios',
  listOfAds: 'Listados de anuncios',
  url: 'Url',
  keyword: 'Keyword',
  image: 'Imagen',
  video: 'Video',
  time: 'Tiempo',
  group: 'Grupo',
  monitor: 'Monitor',
  volume: 'Volumen',
  cloneOffice: 'Clonar oficina',
  maintainQueueRelationshipWithService: 'Mantener relación filas a servicios',
  legacyPath: 'Ruta Legacy',
  addLogo: 'Añadir Logo',
  createLogo: 'Crear logo',
  editLogo: 'Editar logo',
  domainName: 'Nombre del Dominio',
  sponsorName: 'Nombre del Anunciante',
  sponsorMaintenance: 'Mantenimiento de Anunciantes',
  sponsorAdMaintenance: 'Mantenimiento de Anuncios de Patrocinadores',
  loginAdMaintenance: 'Mantenimiento de Anuncios en Acceso a Sistema (Login)',
  filterSponsors: 'Filtrar anunciantes',
  filterSponsorAds: 'Filtrar anuncios de patrocinadores',
  filterLoginAds: 'Filtrar anuncios en acceso al sistema (Login)',
  listOfSponsors: 'Listado de Anunciantes',
  listOfSponsorAds: 'Listado de Anuncios de Patrocinadores',
  listOfLoginAds: 'Listado de Anuncios de acceso al sistema (Login)',
  sponsorNameIsNotCorrect: 'El nombre del anunciante no es correcto.',
  createSponsor: 'Crear anunciante',
  noShowHeaderLogo: 'Ocultar logo de encabezado',
  noShowContentDisclaimer: 'No presentar Disclaimer',
  editSponsor: 'Editar anunciante',
  downloadTemplate: 'Descargar Plantilla',
  advertisers: 'Anunciantes',
  adCategory: 'Categoría de Anuncio',
  beginning: 'Comienzo',
  expiration: 'Expiración',
  massCreateSpaces: 'Creación de Espacios en Masa',
  validate: 'Validar',
  documentToProcess: 'Documento a Procesar',
  downloadValidatedDocument: 'Descargar Documento Validado',
  rowsWithErrors: 'Registros Erróneos',
  validRows: 'Registros Válidos',
  download: 'Descargar',
  createInBatch: 'Crear en Lote',
  createdSpaces: 'Espacios Creados',
  spacesToCreate: 'Espacios a Crear',
  validatedSpaces: 'Espacios Validados',
  totalRecords: 'Total de registros',
  createdRecords: 'Registros creados',
  recordsProcessed: 'Registros procesados',
  processesList: 'Listado de Procesos',
  watchRunningProcessStatus: 'Ver estado del proceso',
  openCloseLocationNotConfigured:
    'Esta localidad no tiene la hora de apertura y cierre debidamente configurada.',
  turnNumber: 'Número de turno',
  areYouSureYouWantToFinalizeTheTurn:
    '¿Está seguro que desea finalizar el turno?',
  toFinalizeTheTurnWriteTheWord:
    'Para finalizar el turno favor de escribir la palabra',
  toCreateSpaceOfficeMustHaveTimes:
    'Para poder crear espacio la oficina debe tener hora de apertura y de cierre',
  code: 'Código',
  assignClients: 'Asignar clientes',
  listOfClients: 'Listado de clientes',
  minimumCharacters: 'Carácteres mínimo',
  lowLetter: 'Letra Minúscula',
  upLetter: 'Letra Mayuscula',
  numberNeeded: 'Necesita Número',
  specialCharacter: 'Carácter Especial',
  comments: 'Comentarios',
  commentsHistory: 'Historial de Comentarios',
  hasOffice: 'Tiene oficina',
  isAttender: 'Puede ser visitado',
  visibleInVisitTo: "Visible en 'Visita a'",
  hiddenInReports: 'No visible en Informes',
  lastUpdate: 'Última actualización',
  allowCoordinateOtherService: 'Coordinar citas para múltiples servicios',
  confirmCoordinateAnotherService: '¿Quieres coordinar otro servicio?',
  startMessage: 'Mensaje de inicio',
  mainScreen: 'Pantalla principal',
  cancelButtonBackgroundColor: 'Color de fondo del botón de cancelar',
  cancelButtonTextColor: 'Color de texto del botón de cancelar',
  commentScreen: 'Pantalla de comentario',
  requestComment: 'Requerir comentario',
  screenHeader: 'Encabezado de la pantalla',
  dateTime: 'Fecha/Hora',
  locationName: 'Localidad',
  transactionList: 'Lista de Transacciones',
  notesResult: 'Resultados Notas',
  transTracking: 'Rastreo de Transacciones',
  keyboardSize: 'Tamaño del teclado',
  zoom: 'Zoom',
  maxCharacters: 'Máximo de caracteres',
  hideInPWA: 'Ocultar en PWA',
  displayName: 'Nombre del Display',
  inactivate: 'Inactivar',
  duration: 'Duración',
  createLoginAd: 'Crear Anuncio (Login)',
  createSponsorAd: 'Crear Anuncio (Patrocinador)',
  editLoginAd: 'Editar Anuncio (Login)',
  editSponsorAd: 'Editar Anuncio (Patrocinador)',
  adFile: 'Archivo de Anuncio',
  viewAd: 'Ver Anuncio',
  backToStartCounter: 'Contador de volver a inicio (milisegundo)',
  listOfOfficeMonitors: 'Listado de Monitores de las Oficinas',
  officeMonitorsMaintenance: 'Ventas - Monitores de las oficinas',
  officeMonitorsFilters: 'Filtro de Monitores de las oficinas',
  salesAvailableSpaces: 'Ventas - Espacios Disponibles',
  filterSpaces: 'Filtro de Espacios Disponibles',
  listOfAvailableSpaces: 'Listados de Espacios Disponibles',
  town: 'Pueblo',
  secondsAvailable: 'Segundos disponibles',
  secondsAvailablePaying: 'Segundos disponibles pagando',
  availableSpacesPaying: 'Espacios disponibles pagando',
  officeWithAvailableSpaces: 'Oficina Con Espacios Disponibles',
  officesWithAvailableSpacesPaying: 'Oficinas Con Espacios Disponibles Pagando',
  editAd: 'Editar Anuncio (Cliente)',
  createAd: 'Crear Anuncio (Cliente)',
  nextDayAttentionNotification: 'Notificación Atención Día Siguiente',
  appointmentUrl: 'Cita Url',
  virtualUrl: 'Virtual Url',
  hasPresential: 'Tiene presencial?',
  capsLockOnKeyboard: 'Caps Lock en el teclado',
  showZoomOnKeyboard: 'Mostrar Zoom en el teclado',
  turn: 'Turno',
  sender: 'Remitente',
  turnModule: 'Módulo de Turnos',
  notificationManagement: 'Manejo de Notificaciones',
  senderAppointmentCarrierDuplicityError:
    'Para el módulo de citas, el proveedor telefónico seleccionado ya existe en otro remitente.',
  senderTurnCarrierDuplicityError:
    'Para el módulo de turnos, el proveedor telefónico seleccionado ya existe en otro remitente.',
  activatePreferentialTurnOption: 'Activar opción de turno preferencial',
  priorityWhenCallingTurn: 'Prioridad al llamar turno',
  turnPriority: 'Prioridad del turno',
  notificationMaintenance: 'Mantenimiento de notificación',
  notificationsMaintenance: 'Notificationes',
  listOfNotifications: 'Lista de notificaciones',
  filterNotifications: 'Filtrar notificaciones',
  createNotification: 'Crear notificación',
  editNotification: 'Editar notificación',
  subjectSpanish: 'Asunto en español',
  subjectEnglish: 'Asunto en inglés',
  textSpanish: 'Texto en español',
  textEnglish: 'Texto en inglés',
  shortTextSpanish: 'Texto corto en español',
  shortTextEnglish: 'Texto corto en inglés',
  default: 'Por defecto',
  searchCriteria: 'Criterio de búsqueda',
  verifyInformation: 'Verificar información registrada en el formulario.',
  notificationNameIsNotCorrect: 'El nombre de la notificación no es correcto.',
  linkOffice: 'Vincular cliente',
  link: 'Vincular',
  maintenanceApplicationPortals: 'Mantenimiento de Portales de Solicitudes',
  informationApplicationPortals: 'Información de Portales de Solicitudes',
  filterRequestPortals: 'Filtrar Portales de Solicitudes',
  listOfApplicationPortals: 'Listado de Portales de Solicitudes',
  contentPageBackgroundColor: 'Color de fondo de la página de contenido',
  contentTitleFontWeight: 'Negrita del título del contenido',
  contentTitleFontColor: 'Color del título del contenido',
  fontWeight: 'Negrita',
  color: 'Color',
  size: 'Tamaño',
  elements: 'Elementos',
  continueButton: 'Botón de continuar',
  backButton: 'Botón de regresar',
  labelTextColor: 'Color de letra de etiqueta',
  labelTextSize: 'Tamaño de texto de etiqueta',
  contentLabelFontWeight: 'Negrita de la etiqueta de contenido',
  contentLabelFontColor: 'Color de etiqueta de contenido',
  newCompany: 'Nueva compañia',
  contentLabelFontSize: 'Tamaño de la etiqueta de contenido',
  continueButtonBackgroundColor: 'Color de fondo del botón de continuar',
  buttonContinueFontColor: 'Color de la letra del botón de continuar',
  buttonContinueFontSize: 'Tamaño del texto del botón de continuar',
  buttonBackBackgroundColor: 'Color de fondo del botón de regresar',
  buttonBackFontColor: 'Color de la letra del botón de regresar',
  buttonBackFontSize: 'Tamaño del texto del botón de regresar',
  hyperLinkColor: 'Color de hipervínculo',
  companyName: 'Nombre de la compañia',
  defaultCountryCode: 'Código de país predeterminado',
  agencies: 'Agencias',
  displayAppointmentCoordinationDate:
    'Mostrar fecha de coordinación de cita al atender turno',
  coordinatedDate: 'Fecha de coordinación',
  maintenanceLayoutDesignConfigurations:
    'Mantenimiento de Configuraciones de Diseño',
  filterLayoutDesignConfigurations: 'Filtrar Configuraciones de Diseño',
  layoutDesignConfigurationList: 'Configuraciones de Diseño',
  layoutDesignConfigurationInformation:
    'Información de Configuración de Diseño',
  listOfLayoutDesignConfigurations: 'Listado de Configuraciones de Diseño',
  defaultConfiguration: 'Configuración Por Defecto',
  agencyInformation: 'Información de Agencia',
  agencyCode: 'Código Agencia',
  agencyName: 'Nombre Agencia',
  agencyInUse: 'Agencia en uso por Configuración de Diseño',
  agencyRepeated: 'Agencia Repetida',
  preferentialTurn: 'Turno preferencial',
  notPresentNotification: 'Notificación de No Presente',
  priorityWhenCallingTurnByRow: 'Prioridad al llamar turno (Fila)',
  priorityWhenCallingTurnByService: 'Prioridad al llamar turno (Servicio)',
  createLayoutConfiguration: 'Crear configuración de diseño',
  editLayoutConfiguration: 'Editar configuración de diseño',
  addNonBusinessDays: 'Agregar días no hábiles',
  removeNonBusinessDays: 'Remover días no hábiles',
  selectDate: 'Seleccionar fecha',
  availableLocations: 'Localidades disponibles',
  selectedLocations: 'Localidades seleccionadas',
  citizenInformation: 'Información del Ciudadano',
  specialistInformation: 'Información del Especialista',
  dateAndTime: 'Fecha y Horario',
  haveToleranceBeforeAppointmentHour:
    'Tiene tolerancia antes de la hora de la cita',
  toleranceBeforeAppointmentHour:
    'Tolerancia antes de la hora de la cita (minutos)',
  specialistWithSpacesCreated:
    'Los siguientes especialistas ya tienen espacios creados con la configuración seleccionada',
  doNotAllowCoordinatingInternalAppointments:
    'No permitir coordinar citas internas',
  showInformativeMessage: 'Mostrar mensaje informativo',
  informativeMessage: 'Mensaje informativo',
  informativeMessageColor: 'Color de mensaje informativo',
  youCannotCoordinateAppointmentsForThisLocation:
    'No puede coordinar citas para esta localidad',
  priorityOrder: 'Orden de prioridad',
  showConfirmationButton: 'Mostrar botón de confirmación',
  confirmationText: 'Texto de confirmación',
  informationText: 'Texto de información',
  confirmationTextColor: 'Color de texto de confirmación',
  doNotAllowCoordinatingInternalAppointmentsToday:
    'No permitir coordinar citas internas hoy',
  breakComment: 'Comentario de Receso',
  showSimpleKeyboard: 'Mostrar teclado simple',
  keyboardBackgroundColor: 'Color de fondo del teclado',
  keyboardTextColor: 'Color del texto del teclado',
  datePickerBackgroundColor: 'Color de fondo del selector de fecha',
  datePickerTextColor: 'Color del texto del selector de fecha',
  customNotifications: 'Notificaciones personalizadas',
  showTurnTrackingButton: 'Mostrar botón de seguimiento de turno',
  searchTurnPrimaryText: 'Texto principal botón Buscar Turno ESP',
  searchTurnPrimaryTextEN: 'Texto principal botón Buscar Turno ENG',
  searchTurnSecondaryText: 'Texto secundario botón Buscar Turno ESP',
  searchTurnSecondaryTextEN: 'Texto secundario botón Buscar Turno ENG',
  serviceQueueNotifications: 'Notificationes de Fila',
  serviceRequiredOnTransfer: 'Servicio requerido al transferir',
  receiptNumber: 'Número de recibo',
  totalNumberActiveStampsIsNotSufficient:
    'La totalidad de los sellos activos no cubren la transaccion solicitada en la cita, debe requerirle al ciudadano la totalidad de los sellos para esta transaccion ({0}: total en ${1})',
  contactDigitalCollectionAssistance:
    'Si tiene dudas sobre el cobro de los Sellos y Comprobantes puede comunicarse con Colecturía Digital al 787-291-9292 o por email a servicioalcliente@colecturiadigital.com',
  wantToCancelTheStamp: 'Desea proceder a cancelar los sellos?',
  documentId: 'Número de ID',
  belongsToGovernment: 'Pertenece al gobierno',
  surveyQuestion: 'Preguntas',
  surveyAnswer: 'Respuestas',
  noSurveyData: 'No contiene encuesta',
  sureToConfirmAppointment: '¿Está seguro que desea confirmar esta cita?',
  preventCoordinateAppointment: 'Prevenir coordinar cita',
  maxDayCoordinateAppointment: 'Días máximo para coordinar citas',
  videoCallNotificationOnCreateTurn:
    'Notificación de video llamada al crear el turno',
  statusInformation: 'Información de Estado',
  statusDate: 'Fecha',
  statusCreatedBy: 'Creado por',
  statusCreatedForReason: 'Motivo',
  withoutReason: 'Sin motivo',
  youCanFollowTurnMobile: 'Puede seguir el turno en el app mobile',
  showInAppMobile: 'Mostar en app mobile',
  hideInApp: 'Ocultar en Aplicaciones',
  disableEmailRequiredForAppointmentCoordination:
    'No requerir correo al coordinar cita',
  metroRegion: 'Región Metro',
  northRegion: 'Región Norte',
  southRegion: 'Región Sur',
  eastRegion: 'Región Este',
  westRegion: 'Región Oeste',
  islandLevel: 'Nivel Isla',
  regionalDashboard: 'Dashboard por Regiones',
  monitoring: 'Monitoreo',
  singlesignon: 'Inicio de sesión único (SSO)',
  ssoAppsRegistration: 'Registro de Applicaciones SSO',
  listOfAuthClients: 'Listado de Clientes de Autorización',
  maintenanceOfAuthClients: 'Mantenimiento de Clientes de Autorización',
  maintenanceOfSSOClients: 'Mantenimiento de Applicaciones SSO',
  regionalCFSERealTime: 'Dashboard Regional Tiempo Real CFSE',
  totalCustomersInjured: 'Total clientes Lesionados',
  totalAttendedVideocall: 'Total atendidos video llamada',
  totalClientEmployers: 'Total clientes Patronos',
  averageVideocallServiceTime: 'Promedio tiempo servicio video llamada',
  resendUnansweredSurveys: 'Reenviar encuestas no contestadas',
  changeToLegacyMode: 'Cambiar a modo legacy',
  removeLegacyMode: 'Remover modo legacy',
  stepperSection: 'Sección stepper',
  completedColor: 'Color de completado',
  loadingColor: 'Color de cargado',
  activeButtonBackgroundColor: 'Color del fondo del botón activo',
  activeButtonFontColor: 'Color de la letra del botón activo',
  activeButtonBorderColor: 'Color del borde del activo',
  fieldTextColor: 'Color del texto del campo',
  fieldValueColor: 'Color del valor del campo',
  informationAreaBorderColor: 'Color del borde del área de información',
  informationAreaBackgroundColor: 'Color del fondo del área de información',
  activeInformationAreaBorderColor:
    'Color del borde del área de información activo',
  activeInformationAreaBackgroundColor:
    'Color del fondo del área de información activo',
  documentUploadButtonColor: 'Color del boton de cargar documento',
  documentUploadButtonTextColor: 'Color texto del boton de cargar documento',
  activeDocumentUploadBackgroundColor: 'Color fondo de cargar documento activo',
  hoverColor: 'Color en hover',
  dotColor: 'Color del punto',
  showLocationTimeInformation: 'Mostrar información del tiempo de la localidad',
  showTimeInformation: 'Mostrar información del tiempo',
  showServiceAverageTime: 'Mostrar tiempo promedio de servicio',
  showLocationTurns: 'Mostrar turnos de la localidad',
  showLocationAddress: 'Mostrar dirección de la localidad',
  showMileage: 'Mostrar millaje',
  showHowToArrive: 'Mostrar como llegar',
  hasCustomText: 'Posee texto personalizado',
  allowMultipleTurnsPerService:
    'Permitir más de un turno por servicio por usuario',
  hideInactiveUsers: 'Ocultar usuarios inactivos',
  hideBlockedUsers: 'Ocultar usuarios bloqueados',
  allowCurrentDayCoordination: 'Permitir coordinar citas en el día corriente',
  hideInactiveUsersFilter: 'Ocultar Usuario Inactivo / Bloqueado',
  hideBlockedUsersInVisitTo: "Ocultar usuarios bloqueados en 'Visita a'",
  isPremium: 'Es cliente premium',
  premiumSiteUrl: 'URL de sitio premium',
  autoSelectSpecialist: 'Auto Seleccionar Especialista',
  thereAreNoDocuments: 'No hay documentos',
  documents: 'Documentos',
  addDocument: 'Agregar documento',
  isRequired: 'Es requerido',
  autoDelete: 'Auto eliminar',
  showAppointmentsPlusToPublic: 'Mostrar citas plus al público',
  plusClientDisablePasteInConfirmationEmail:
    'No permitir Copy/Paste en Confirmación de Email',
  displayClientNameOnRibbon: 'Mostrar nombre de cliente en alerta',
  displayTurnNumberOnRibbon: 'Mostrar número de turno en alerta',
  rescheduleAppointment: 'Reprogramar cita',
  hideAttachDocument: 'Ocultar Adjuntar Documento',
  stationTextSize: 'Tamaño del texto de estación',
  turnNumberTextSize: 'Tamaño del texto del número de turno',
  nameTextSize: 'Tamaño del texto del nombre',
  excludeFromHomeDashboard: 'Excluir para dashboard de inicio',
  attachDocumentsToActiveTurn: 'Adjuntar documentos a turno activo',
  pressDragToUploadDocuments: 'Presione o arrastre para subir documentos',
  uploadedDocuments: 'Documentos cargados',
  loading: 'Cargando',
  systemError: 'Error en el sistema, intente de nuevo mas tarde',
  cannotAttachDocumets: 'No puede adjuntar documentos',
  documentsHaveAlreadySentAgent:
    'Los documentos ya fueron enviados al representante de atención',
  documentsUploadedSuccessfully: 'Los documentos fueron subidos exitosamente',
  invalidDocumentFormat: 'Formato del documento inválido',
  sendDocumentForValidation: 'Enviar documento para validación',
  documentsSentSuccessfully: 'Documentos enviado exitosamente',
  showWaitTime: 'Mostrar tiempo de espera',
  hourFromMinimumCapacity: 'Hora desde capacidad minima',
  hourToMinimumCapacity: 'Hora hasta capacidad minima',
  increasedWaitingTime: 'Aumento de tiempo de espera (%)',
  messageWaitingTime: 'Mensaje de tiempo de espera',
  requestDocuments: 'Solicitar documento(s)',
  documentsRequested: 'Documentos solicitados',
  canUploadDocuments: 'Puede subir documentos',
  enableLanguageBehavior: 'Activar manejo de lenguaje',
  enableTurnManualCompletion: 'Finalizar el turno manualmente en modo visita',
  rowTextSize: 'Tamaño del texto de la fila',
  closeTurn: 'Cerrar turno',
  canCloseTurn: 'Puede cerrar el turno?',
  backgroundMessageTextSize: 'Tamaño del texto mensaje de fondo',
  timeInformationTextSize: 'Tamaño del texto de informacion de tiempo',
  enableVisitMode: 'Activar modo visita',
  displayHistoricData: 'Solo datos historicos',
  ticketMaintenance: 'Mantenimiento Ticket Personalizado',
  filterTicket: 'Filtro de Ticket',
  listTicket: 'Listado de Tickets',
  ticketName: 'Nombre de Ticket',
  languageScreen: 'Pantalla de idioma',
  requestLanguage: 'Requerir idioma',
  languageRequired: 'El idioma es requerido',
  languages: 'Idiomas',
  showCategory: 'Mostrar categorización',
  categoryName: 'Nombre categorización',
  categoryFieldValues: 'Valores del campo',
  showOfficeName: 'Mostrar nombre de oficina',
  showQueueName: 'Mostrar nombre de fila',
  showRegistrationDate: 'Mostrar fecha y hora de registro',
  showURL: 'Mostrar URL',
  showUserName: 'Mostrar nombre del ciudadano',
  showVisitTo: 'Mostrar campo visita a',
  showLogo: 'Mostrar logo',
  miscellaneous1: 'Misceláneo #1',
  miscellaneous2: 'Misceláneo #2',
  miscellaneous3: 'Misceláneo #3',
  miscellaneous4: 'Misceláneo #4',
  miscellaneous5: 'Misceláneo #5',
  miscellaneous6: 'Misceláneo #6',
  miscellaneous7: 'Misceláneo #7',
  showLanguageScreen: 'Mostrar pantalla de idioma',
  showCommentScreen: 'Mostrar pantalla de comentario',
  useR8Appointment: 'Redirección a citas R8',
  appointmentFields: 'Campos de Cita',
  activateChat: 'Activar chat',
  activateInternalChat: 'Activar cuenta chat',
  activateEntrance: "Activar 'Turnos Entrance'",
  youWantToActivateInternalChat: '¿Desea activar cuenta chat a este usuario?',
  categories: 'Categorias',
  activeInternalChat: 'Chat interno activo',
  virtualPhoneExtension: 'Extensión del cuadro virtual',
  cantRemoveLanguage: 'No puede remover el idioma',
  cantRemoveLanguageUntilEndThesTurns:
    'No puede remover el idioma hasta finalizar estos turnos',
  youWantToRemove: 'Desea remover',
  languageWillRemovedFollowingTechnicians:
    'Este idioma se le removera a los siguientes tecnicos',
  categoryWillRemovedFollowingTechnicians:
    'Esta categoria se le removera a los siguientes tecnicos',
  broadcast: 'Difusiones',
  broadcastSettings: 'Configuración de difusiones',
  dayTurns: 'Turnos del día',
  unattendedTurns: 'Turnos sin atender',
  sendEmail: 'Enviar email',
  sendSMS: 'Enviar SMS',
  sendPushNotification: 'Enviar notificacion push',
  scanLicenseMessage: 'Por favor, escanee la licencia.',
  scanLicenseLoadingMessage: 'Escaneando licencia...',
  messageSentSuccessfully: 'Mensaje enviado con éxito',
  syncChatUsers: 'Sync Usuarios de Chat',
  typing: 'Escribiendo',
  send: 'Enviar',
  communications: 'Comunicaciones',
  controlsConversation: 'Controles Conversación',
  participantStatus: 'Estatus de Participantes',
  hold: 'Hold',
  request: 'Solicitar',
  transfer: 'Transferir',
  drop: 'Colgar',
  giveControl: 'Dar control',
  passive: 'Pasivo',
  citizen: 'Ciudadano',
  connection: 'Conexión',
  supervisor: 'Supervisor',
  attentionlanguage: 'Idioma de atención',
  representativeCompanionTypeRequired:
    '¿Se requiere acompañante de representante?',
  notInActiveWaitingRoom: 'Usted no se encuentra en ninguna sesión activa',
  startWaitingRoomPrompt:
    'Para iniciar una sesión debe configurar la compañia y presionar el botón PLUS',
  responsesAndTurnData: 'Respuestas y Datos del Turno',
  startOfConversation: 'Inicio de la conversación',
  userAddedBy: 'Usuario {0} agregado por {1}.',
  userRemovedBy: 'Usuario {0} removido por {1}.',
  userAdded: 'Usuario {0} agregado.',
  userRemoved: 'Usuario {0} removido.',
  here: 'Aquí',
  notifyAllInRoom: 'Notificar a todos en esta sala',
  turnWaitingRoom: 'Sala de Espera de Turnos',
  waitingRequestedForSession: 'Esperando que sea solicitado para una sesión...',
  youSelectedToParticipateSession:
    'Usted fue seleccionado para participar en una sesión',
  passiveUserNotFound: 'No se ha encontrado acompañante',
  supervisorUserNotFound: 'No se ha encontrado supervisor',
  monitorUserNotFound: 'No se ha encontrado monitor',
  companionFound: 'Se encontró acompañante, buscando ciudadano...',
  supervisorUserFound: 'Supervisor entro a la sala de espera',
  monitorUserFound: 'Monitor entro a la sala de espera',
  activeSessionHasEnded: 'Se ha finalizado la sesion activa',
  passiveMode: 'Modo pasivo',
  studentId: 'Identificación de estudiante',
  useChat: 'Utiliza chat',
  useCall: 'Utiliza llamada',
  allowchatrecording:
    '¿Estás de acuerdo con que esta conversación sea guardada?',
  recordingDisclaimer: 'Descargo de responsabilidad de grabación',
  externalChat: 'Chat Externo',
  internalChat: 'Chat Interno',
  turnInformationWasUpdated: 'La información del turno fue actualizada',
  errorLogginInToChat: 'Error al iniciar sesión al chat',
  unhold: 'Unhold',
  endPassiveSearch: 'Finalizar búsqueda de pasivo',
  citizenEnteredRoom: 'El ciudadano ha entrado a la sala',
  citizenLeftRoom: 'El ciudadano ha dejado la sala',
  citizenEndedSesion: 'El ciudadano ha finalizado la sesión',
  citizenPhoneRinging: 'El teléfono del ciudadano está sonando...',
  citizenPhoneIsBusy: 'El teléfono del ciudadano está ocupado',
  citizenCallConnected: 'El ciudadano ha interacuado con la llamada',
  reJoinCall: 'Reunir a llamada',
  sessionConcluded: 'Sesión concluida',
  externalChatSessionCompleteMessage:
    '¡Gracias por elegirnos! Nos alegra haber podido asistirle. Ahora puede cerrar su navegador.',
  enableCommunicationModule: 'Activar módulo de comunicaciones',
  maxRecall: 'Máximo de llamadas',
  dropOut: 'Abandonar',
  fileSizeLimitMessage: 'El tamaño del archivo no debe exceder los 10 MB.',
  finishChat: 'Concluir Chat',
  finishCall: 'Concluir Llamada',
  monitorHasEnteredTheRoom: 'Monitor ha entrado a la sala',
  monitorHasLeftTheRoom: 'Monitor ha salido de la sala',
  supervisorHasEnteredTheRoom: 'Supervisor ha entrado a la sala',
  supervisorHasLeftTheRoom: 'Supervisor ha salido de la sala',
  getInto: 'Ingresar',
  listOfActiveSessions: 'Listado de sesiones activas',
  monitoringActiveSessions: 'Monitoreo de sesiones activas',
  minutes: 'Minutos',
  turnMinutes: 'Minutos del turno',
  created: 'Creado',
  waitingPassive: 'Esperando pasivo',
  waitingCitizen: 'Esperando ciudadano',
  inProgress: 'En proceso',
  language: 'Idioma',
  participants: 'Participantes',
  refresh: 'Refrescar',
  leaveTheRoom: 'Salir de la sala',
  join: 'Unirse',
  requestToJoinWaitingRoom: 'Solicitud de ingreso a sala de espera',
  YouHaveReceivedRequestToJoinWaitingRoom:
    'Has recibido una solicitud para unirte a una sala de espera como {type}',
  decline: 'Rechazar',
  signOut: 'Cierre sesión',
  onLogout: 'En cierre de sesión',
  roomStatus: 'Estado de la sala',
  supervising: 'Supervisando',
  answer: 'Atender',
  reject: 'Rechazar',
  incomingCall: 'Llamada entrante',
  mute: 'Silenciar',
  unmute: 'Activar',
  finish: 'Finalizar',
  endTurnSearch: 'Finalizar búsqueda del turno',
  takeControl: 'Tomar control',
  takeControlWaitingRoom: '¿Desea obtener el control de la sala de espera?',
  grantControlToSupervisor: 'Se otorga control de sala de espera a supervisor',
  rejectControlRequestForSupervisor:
    'Se rechazo solicitud de control a supervisor',
  relations: 'Relaciones',
  markedNotPresentBy: 'Marcado no presente por {0}',
  confirmationButtonSize: 'Tamaño botón de confirmación',
  medium: 'Mediano',
  big: 'Grande',
  plusClientShowAttendeesNumber: 'Mostrar número de personas en Cita',
  writeYourMessage: 'Escriba su mensaje',
  supervisorWillAssistingYou: 'Un supervisor lo estará atendido',
  notes: 'Anotaciones',
  languageToAttend: 'Idioma para atención',
  es: 'Español',
  en: 'Inglés',
  UserCategory: 'Categoría de Usuario',
  lookingForAnotherCompanion: 'Buscando otro acompañante.',
  lookForSpecialist: 'Buscar especialista',
  lookingForSpecialist: 'Buscando especialista',
  specialistNotFound: 'Especialista no encontrado',
  specialistFoundTurnTransferred:
    'Especialista encontrado, el turno ha sido transferido',
  requestToAttendTurn: 'Solicitud para atender un turno',
  youhaveRecievedRequestAsSpecialist:
    'Has recibido una solicitud para atender un turno como especialista',
  specialty: 'Especialidad',
  minimumDaysToCoordinateAppointments: 'Días minimo para coordinar citas',
  accessCodeMessage: 'Favor de ingresar el código de acceso',
  accessCodeInfo:
    '* El código de acceso es el número de cita o su número de identificación',
  accessCode: 'Código de acceso',
  preferenceWillRemovedFollowingLocation:
    'Se le removerá la preferencia a las siguiente localidad',
  assignAppointmentOffice: 'Asignar oficina de cita',
  citizenResponseTimeout: 'El ciudadano no ingresó a la sala',
  areYouSureYouWantToEndSession: '¿Esta seguro que desea finalizar la sesión?',
  endSession: 'Finalizar sesión',
  isWriting: '{names} está escribiendo',
  sessionList: 'Listado de sesiones',
  sessionFilters: 'Filtros de sesiones',
  chat: 'Chat',
  unfinished: 'Inconcluso',
  assess: 'Evaluar',
  holdTime: 'Tiempo en hold',
  ring: 'Llamada',
  spaceCreationProcess: 'Proceso {0} de creación de espacios: {1}% completado',
  endingTime: 'Hora de finalización',
  reCallTo: 'Volver a llamar a ',
  evaluation: 'Evaluación',
  refferEvaluationTo: 'Referir esta evaluación a',
  needSecondEvaluation: 'Necesita segunda evaluación',
  activateChatUse: 'Activar uso chats',
  useMessageNotFound: 'Mostrar mensaje en búsqueda de espacios',
  recording: 'Grabación',
  recordingNotLoaded: 'Esta llamada no fué grabada',
  recordingDetail: 'Grabación detalle',
  chatDetail: 'Mensaje detalle',
  thereAreAttachedDocuments: 'Hay documentos adjuntos',
  turnWithDocuments: 'Turno con documentos',
  contactEmail: 'Email de contacto',
  contactPhone: 'Teléfono de contacto',
  individualCall: 'Llamada individual',
  loanNumber: 'Código de préstamo',
  automaticTransfer: 'Transferencia automática',
  assingLocations: 'Asignar localidades',
  assignAnnouncementLocations: 'Asignar anuncio a localidades',
  selectCompany: 'Seleccione una compañia',
  youHaveNotSavedYetConfigurationCurrentlyFromFirstScreen:
    'Aun no ha guardado, la configuración que ve actualmente es de la primera pantalla',
  activateScanner: 'Activar scanner',
  'validation.allowedCharacters': 'El campo contiene caracteres no válidos.',
  placeholderMask: 'Máscara de campo',
  loadClientInfo: 'Cargar Información de Clientes',
  clientInfoTemplate: 'Archivo de información de clientes (CSV)',
  representative: 'Representante',
  agent: 'Agente',
  communicationCompletedByCitizen: 'Comunicación finalizada por ciudadano',
  communicationCompletionDate: 'Fecha de finalización de comunicación',
  communicationCompletionHour: 'Hora de finalización de comunicación'
};
