import { apiTurnos } from '@/core/api/';

export const getValidationMessages = async (messageCodes) => {
  try {
    const { data } = await apiTurnos.get('/api/validationsMessages', {
      params: { messageCodes: messageCodes + '' }
    });
    return data;
  } catch (error) {
    return [];
  }
};
