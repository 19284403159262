import store, { modulesName } from '@/store';

export const applyRedirect = (router) => {
    router.beforeEach(async (to, from, next) => {
        await store.dispatch(`${modulesName.userModuleName}/init`, { initialLoadOrRefresh: from.path == '/' || to.path == '/' });
        const role = store.getters[`${modulesName.userModuleName}/role`];

        if (to.path == '/' && role != null) {
            const userMenu = store.getters[`${modulesName.userModuleName}/userMenu`];
            let menu = null;

            switch (role) {
                case 'Client':
                    {
                        menu = userMenu.find(x => x.permissionName == "menu.dashboard" && x.isRoot);
                        if (menu) {
                            return next({
                                name: 'Dashboard'
                            });
                        }
                        break;
                    }
                case 'Citas':
                    {
                        menu = userMenu.find(x => x.permissionName == "menu.appointment" && x.isRoot);
                        if (menu) {
                            store.dispatch(`${modulesName.appModuleName}/setSelectedMenuItem`, menu);
                        }
                        break;
                    }
                default:
                    {
                        menu = userMenu.find(x => x.permissionName == "menu.turns.manager" && x.isRoot);
                        if (menu) {
                            return next({
                                name: 'turnsManager'
                            });
                        }
                    }
            }
        }

        return next();
    });
};