import types from './types';
export default {
  async setLoading ({ commit }, isLoading) {
    commit(types.SET_LOADING, isLoading);
  },
  setSelectedMenuItem({commit}, menuItem) {
    commit(types.SET_SELECTED_MENU_ITEM, menuItem);
  },
  setHubLoaded({ commit }, payload) {
    commit(types.SET_HUB_LOADED, payload);
  },
};
