import { apiTurnos } from '@/core/api/';

export const getWaitingRoom = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}`);

export const createWaitingRoom = (payload) =>
  apiTurnos.post(`/api/waitingroom`, payload);

export const updateWaitingRoomStatus = (payload) =>
  apiTurnos.put(`/api/waitingroom/status`, payload);

export const finalizeWaitingRoom = (waitingRoomId) =>
  apiTurnos.post(`/api/waitingroom/finalize/${waitingRoomId}`);

export const searchActive = (payload) =>
  apiTurnos.get(`/api/waitingroom/search/active`, { params: payload });

export const addToWaitingRoom = (payload) =>
  apiTurnos.post(`/api/waitingroom/participant`, payload);

export const exitWaitingRoom = (waitingRoomId, userId) =>
  apiTurnos.delete(`/api/waitingroom/participant/${waitingRoomId}/${userId}`);

export const userAcceptWaitingRoom = (payload) =>
  apiTurnos.post('/api/waitingroom/user/accept', payload);

export const getParticipantMembers = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}/members`);

export const saveNotes = (payload) =>
  apiTurnos.post('/api/waitingroom/participant/notes', payload);

export const specialistAcceptWaitingRoom = (payload) =>
  apiTurnos.post('/api/waitingroom/specialist/accept', payload);

export const initCalls = (waitingRoomId) =>
  apiTurnos.post(`/api/waitingroom/${waitingRoomId}/calls/init`, null);

export const acceptCall = (payload) =>
  apiTurnos.post('/api/waitingroom/participant/call/accept', payload);

export const declineCall = (payload) =>
  apiTurnos.post('/api/waitingroom/participant/call/decline', payload);

export const searchQualityEvaluation = (payload) =>
  apiTurnos.get(`/api/waitingroom/search/quality/evaluation`, { params: payload });

export const callParticipantToJoinWaitingRoom = (waitingRoomId, isPassive) =>
  apiTurnos.post(`/api/waitingroom/${waitingRoomId}/calls/join/participant`, isPassive);

export const getEvaluationToUpdate = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}/evaluation`);

export const getEvaluationReferrals = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}/evaluation/referral`);

export const saveEvaluation = (payload) =>
  apiTurnos.post('/api/waitingroom/evaluation/save', payload);

export const getCallRecording = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}/recording/uri`);

export const getChatHistory = (waitingRoomId) =>
  apiTurnos.get(`/api/waitingroom/${waitingRoomId}/chat/history`);
  