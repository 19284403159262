export default {
  methods: {
    saveLocalData(key, value) {
      const mainKey = 't2.' + key;

      if (this.hasLocalData(mainKey)) {
        this.removeLocalData(mainKey);
      }

      localStorage.setItem(mainKey, JSON.stringify(value));
    },
    loadAndRemoveLocalData(key) {
      const mainKey = key;

      const localData = this.loadLocalData(mainKey);

      if (localData != null) {
        this.removeLocalData(mainKey);
      }

      return localData;
    },
    loadLocalData(key) {
      const mainKey = 't2.' + key;

      const strItem = localStorage.getItem(mainKey);

      if (!strItem) {
        return null;
      }

      const data = JSON.parse(strItem);

      return data;
    },
    removeLocalData(key) {
      localStorage.removeItem('t2.' + key);
    },
    hasLocalData(key) {
      return localStorage.getItem('t2.' + key) !== null;
    },
    clearLocalData() {
      localStorage.clear();
    },
    clearLocalDataForLogout() {
      let n = localStorage.length;

      while (n--) {
        const key = localStorage.key(n);
        if (/t2./.test(key)) {
          localStorage.removeItem(key);
        }
      }

      localStorage.removeItem('locale')
    },
  },
};
