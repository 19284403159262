import Vue from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading);

const Loader = {
  data () {
    return {
      isLoading: null
    };
  },

  methods: {
    ShowWait (parentcomponent) {
      const self = this;
      self.isLoading = self.$loading.show({
        container: parentcomponent,
        canCancel: false,
        onCancel: self.onCancel,
        loader: 'spinner',
        color: '#ff8105',
        backgroundColor: 'gray',
        width: 90,
        height: 90,
        zIndex: 9999
      });
    },
    HideWait () {
      const self = this;
      if (self.isLoading !== null) {
        self.isLoading.hide();
      }
    }
  }
};

// eslint-disable-next-line semi
export default Loader
