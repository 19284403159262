import Vue from 'vue';
import Swal from 'sweetalert2';

const SwAlert = {
  data: () => ({}),
  methods: {
    ShowToast: (Title, Message, MsjType, Callback) => {
      const defautlTitle = {
        success: 'Proceso exitoso',
        warning: 'Atención',
        error: 'Error',
        info: 'Info',
      };
      let title = Title ? Title : defautlTitle[MsjType];
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 6000,
        didOpen: Callback,
      });
      Toast.fire(title, Message, MsjType);
    },
    ShowSuccessToast(Title, Message) {
      this.ShowToast(Title, Message, 'success');
    },
    ShowSuccessSaveToast() {
      this.ShowToast(
        this.$t('saveSuccessful'),
        this.$t('operationCompleted'),
        'success',
      );
    },
    ShowErrorToast(message, Callback) {
      this.ShowToast('Error', message, 'error', Callback);
    },
    ShowInformationToast(message, Callback) {
      this.ShowToast('Info', message, 'info', Callback);
    },
    ShowAlert: (Title, Message, MsjType, Callback) => {
      Swal.fire({
        title: Title,
        html: Message,
        icon: MsjType,
        confirmButtonColor: '#FF8105',
        onClose: Callback,
      });
    },
    ShowModalComponents(ComponentName) {
      Swal.fire({
        html: ComponentName,
      });
    },
    ShowConfirm: async (title, Question) => {
      let answer = false;
      await Swal.fire({
        title: title,
        text: Question,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
      }).then(function (response) {
        if (response.value) {
          answer = response.value;
        }
      });
      return answer;
    },
    ShowCancelModal: async (
      title,
      message,
      confirmButtonText = 'Sí',
      cancelButtonText = 'No',
    ) => {
      let answer = false;
      await Swal.fire({
        title: '',
        html: `
                <div class="custom-cancel-popup">
                  <div class="header shadow">
                      <div class="title">
                          <div class="icon-container">
                            <i class="fas fa-bell-exclamation circle-icon"></i>
                          </div>
                          <span>${title}</span>
                      </div>
                  </div>
                  <div class="body mt-5">
                      <div class="content py-3 px-4">
                          <span >${message}</span>
                      </div>
                  </div>
                </div>
                    `,
        icon: 'warning',
        focusConfirm: false,
        focusCancel: false,
        showCancelButton: true,
        showConfirmButton: true,
        showCloseButton: false,
        confirmButtonColor: '#2DB782',
        cancelButtonColor: '#D01C1C',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        customClass: 'custom-cancel-popup',
      }).then(function (response) {
        if (response.value) {
          answer = response.value;
        }
      });
      return answer;
    },
  },
};

Vue.mixin(SwAlert);
