import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import { ROCKET_CHAT } from '@/config';
import { loginChat } from './AccountService';
import { first } from 'rxjs/operators';
import { getChatTurnInfo as _getChatTurnInfo } from './UserService';
import { getParticipantMembers as _getParticipantMembers } from './WaitingRoomService';
import { getRoomMembers as _getRoomMembers } from './ChatService';
import NewMessageAudio from '@/assets/sounds/new-message.mp3';
import NewMentionAudio from '@/assets/sounds/new-mention.mp3';

let instance;
const newMessageAudio = new Audio(NewMessageAudio);
const newMentionAudio = new Audio(NewMentionAudio);

class RocketChatService {
  loggedIn = false;
  userId = null;
  authToken = null;
  rooms = [];
  loadingRooms = false;
  currentRoomId = 0;
  #userSubscriptions$ = null;
  #roomsSubscriptions$ = {};

  constructor() {
    if (instance) throw new Error('New instance cannot be created!!');

    this.api = new RealTimeAPI(ROCKET_CHAT.BASE_SOCKET);

    this.api.connectToServer().subscribe(() => {
      this.api.keepAlive().subscribe(); // Ping Server
    });

    this.onMessage();

    instance = this;
  }

  startUserSubscriptions() {
    this.#userSubscriptions$ = this.api
      .getSubscription(
        'stream-notify-user',
        `${this.userId}/subscriptions-changed`,
        false,
      )
      .subscribe();
  }

  stopUserSubscriptions() {
    this.#userSubscriptions$.unsubscribe();
  }

  async login() {
    if (this.loggedIn) return;
    const { data } = await loginChat();

    if (!data) return;

    this.userId = data.userId;
    this.authToken = data.authToken;

    this.api.loginWithAuthToken(this.authToken).subscribe((apiEvent) => {
      if (apiEvent.msg === 'result') {
        this.loggedIn = true;
      }
    });
  }

  async setAuthenticationInfo(id, token) {
    if (this.loggedIn) return;

    this.userId = id;
    this.authToken = token;

    this.api.loginWithAuthToken(this.authToken).subscribe((apiEvent) => {
      if (apiEvent.msg === 'result') {
        this.loggedIn = true;
      }
    });
  }

  setMessage(value) {
    const message = this.mapMessage(value);
    const room = this.rooms.find((room) => room.id === message.roomId);
    if (!room) return;
    room.messages.unshift(message);

    if (room.id !== this.currentRoomId && message.owner.id !== this.userId) {
      if (
        message.mentions.some((x) =>
          [this.userId, 'all', 'here'].includes(x.id),
        )
      ) {
        newMentionAudio.play();
        room.mention = true;
      } else {
        newMessageAudio.play();
        room.unread = true;
      }
    }

    if (message.t) return;
    room.lastMessage = {
      mentions: message.mentions,
      msg: message.msg,
      userId: message.owner.id,
    };
  }

  onMessage() {
    this.api.onMessage((apiEvent) => {
      const { msg, collection, fields } = apiEvent;
      if (msg !== 'changed') return;

      // New message
      if (collection === 'stream-room-messages') {
        const message = fields.args[0];
        if (['au', 'ru'].includes(message.t)) {
          this.loadRoomMembers(message.rid);
        }
        this.setMessage(message);
      }

      // User removed o added to room
      if (collection === 'stream-notify-user') {
        const [event, value] = fields.args;
        const roomIndex = this.rooms.findIndex((x) => x.id === value.rid);

        if (event === 'inserted') {
          if (roomIndex !== -1) return;

          this.setRoom({ ...value, _id: value.rid }, { inserted: true });
          newMessageAudio.play();
        } else if (event === 'removed') {
          if (roomIndex === -1) return;
          this.#closeRoom(roomIndex);
        }
      }
    });
  }

  closeRoomById(roomId) {
    const roomIndex = this.rooms.findIndex((x) => x.id === roomId);
    if (roomIndex === -1) return;
    this.#closeRoom(roomIndex);
  }

  #closeRoom(roomIndex) {
    const room = this.rooms[roomIndex];

    if (room.id === this.currentRoomId) this.currentRoomId = 0;

    this.rooms.splice(roomIndex, 1);
    this.stopRoomHistorySubscription(room.name);
  }

  loadRooms() {
    return this.api
      .callMethod('rooms/get', { $date: 0 })
      .pipe(first())
      .toPromise();
  }

  async loadRoom(roomId, { waitingRoomId } = { waitingRoomId: null }) {
    if(this.rooms.some(x => x.id == roomId))
      return;

    const response = await this.api
                      .callMethod('getRoomById', roomId)
                      .pipe(first())
                      .toPromise();

    if(response.error == undefined) {
      if(this.rooms.some(x => x.id == roomId))
        return;
      response.result.waitingRoomId = waitingRoomId;
      await this.setRoom(response.result);
    }

  }

  async loadPublicRoom(roomId) {
    if(this.rooms.some(x => x.id == roomId))
      return;

    const response = await this.api
                      .callMethod('getRoomById', roomId)
                      .pipe(first())
                      .toPromise();

    if(response.error == undefined) {
      if(this.rooms.some(x => x.id == roomId))
        return;
      const room = this.mapRoom(response.result);
      this.rooms.push(room);

      room.loading = true;

      this.loadRoomHistory(room.id).then((resp) => {
        room.messages = resp.result.messages.map((item) => this.mapMessage(item));
      }).finally(() => (room.loading = false));

      this.loadRoomMembers(room.id);
      this.startRoomHistorySubscription(room.id, room.name);
    }
  }

  loadRoomMembers(roomId) {
    const room = this.rooms.find(x => x.id === roomId);
    room.loadingMembers = true;

    const getMembers = room.waitingRoomId ?  _getParticipantMembers(room.waitingRoomId) : _getRoomMembers(roomId);

    getMembers.then(({data}) => {
      room.members = data.map(x => ({...x, typing: false, typingTimeout: null }));
    }).finally(() => room.loadingMembers = false)
  }

  // To activate the watch that will always be aware of messages from that room
  startRoomHistorySubscription(roomId, roomName) {
    if (this.#roomsSubscriptions$[roomName]) return;
    this.#roomsSubscriptions$[roomName] = this.api
      .getSubscription('stream-room-messages', roomId, false)
      .subscribe();
  }

  // Stop watching messages from a specific room
  stopRoomHistorySubscription(roomName) {
    this.#roomsSubscriptions$[roomName].unsubscribe();
    delete this.#roomsSubscriptions$[roomName];
  }

  mapRoom(item) {
    let lastMessage = {
      mentions: [],
      msg: null,
      userId: null,
    };
    if (item.lastMessage) {
      lastMessage.msg = item.lastMessage.msg;
      lastMessage.mentions =
        item.lastMessage.mentions?.map((x) => ({ ...x, id: x._id })) || [];
      lastMessage.userId = item.lastMessage.u._id;
    }
    const metadata = item.name.split('_');
    return {
      id: item._id,
      turnId: metadata[1],
      turnNumber: null,
      abbreviation: metadata[3],
      owner: { ...item.u, id: item.u._id },
      name: item.name,
      lastMessage,
      loading: !!item.msgs,
      msgs: item.msgs,
      mention: false,
      unread: false,
      serviceQueueId: null,
      serviceQueueName: null,
      locationConfigurationId: null,
      locationName: null,
      members: [],
      loadingMembers: false,
      messages: [],
      waitingRoomId: item.waitingRoomId
    };
  }

  mapMessage(value) {
    return {
      id: value._id,
      mentions: value?.mentions?.map((x) => ({ ...x, id: x._id })) || [],
      roomId: value.rid,
      type: value.t,
      msg: value.msg,
      owner: { ...value.u, id: value.u._id },
      date: new Date(value.ts.$date),
    };
  }

  // Obtain the history (messages) of a room
  async loadRoomHistory(roomId) {
    return this.api
      .callMethod('loadHistory', roomId, null, 100)
      .pipe(first())
      .toPromise();
  }

  async setRoom(item, { inserted } = { inserted: false }) {
    const room = this.mapRoom(item);
    this.rooms.push(room);
    if (room.loading || inserted) {
      room.loading = true;

      Promise.all([_getChatTurnInfo(room.turnId), this.loadRoomHistory(room.id)])
        .then((results) => {
          const [
            { data: turnInfo },
            {
              result: { messages },
            },
          ] = results;
          room.turnNumber = `${turnInfo.serviceQueueStartingLetter || ''}${
            turnInfo.turnNumber
          }`;
          room.serviceQueueId = turnInfo.serviceQueueId;
          room.serviceQueueName = turnInfo.serviceQueueName;
          room.locationConfigurationId = turnInfo.locationConfigurationId;
          room.locationName = turnInfo.locationName;

          room.messages = messages.map((item) => this.mapMessage(item));

          if (inserted) {
            room.unread = true;
            if (room.messages.length === 0) return;
            const message = room.messages.find((x) => !x.type);
            if (message === null || message === undefined) return;
            room.lastMessage = {
              mentions: message.mentions,
              msg: message.msg,
              userId: message.owner.id,
            };
          }
        })
        .finally(() => (room.loading = false));
    }
    this.loadRoomMembers(room.id);
    this.startRoomHistorySubscription(room.id, room.name);
  }
  async initilize() {
    this.loadingRooms = true;
    const { result } = await this.loadRooms();
    this.loadingRooms = false;
    this.startUserSubscriptions();

    for (const item of result.update.filter((x) => x.t === 'c')) {
      await this.setRoom(item);
    }
  }

  sendMessage(roomId, msg) {
    const id = `${new Date().getTime()}`;
    return this.api.sendMessage({
      msg: 'method',
      method: 'sendMessage',
      id: id,
      params: [
        {
          _id: id,
          rid: roomId,
          msg: msg,
        },
      ],
    });
  }
}

export default new RocketChatService();
