import Vue from 'vue';

const Insights = {
  data: () => ({}),
  methods: {
    trackEvent(name, properties){
      if (this.$appInsights && this.$appInsights.trackException) {
        this.$appInsights.trackEvent(name, properties);
      }
    },
    logErrorsAndExceptions(location, error) {
      if (this.$appInsights && this.$appInsights.trackException) {
       this.$appInsights.trackException({
        exception: new Error(location),
        properties: error
       });
      }
    },

    logInfoAndDebug(location, message) {
      if (this.$appInsights && this.$appInsights.trackEvent) {
        this.$appInsights.trackEvent({
        name: location,
        properties: message
        });
      }
     }
  },
};

Vue.mixin(Insights);
