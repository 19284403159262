import { apiTurnos } from '@/core/api/';

const qs = require('querystring');

export const massCreateUsers = (payload) => {
  return apiTurnos.post('api/user/create/bulk', payload);
};

export const getUserById = (payload) => {
  return apiTurnos.get(`api/user/${payload}`);
};

export const getUsers = (payload) => {
  /// Payload fields:
  //  userName
  //  email
  //  applicationId
  //  roleIds
  //  isActive
  //  companyId
  //  locationConfigurationId
  let qstring = '';

  if (payload.roleIds) {
    for (let i = 0; i < payload.roleIds.length; i++) {
      qstring += `roleIds=${payload.roleIds[i]}&`;
    }
  }

  if (payload.companyId) {
    qstring += `companyId=${payload.companyId}&`;
  }

  if (payload.locationConfigurationId) {
    qstring += `locationConfigurationId=${payload.locationConfigurationId}&`;
  }

  if (payload.user) qstring += `user=${payload.user}&`;

  if (payload.isActive) qstring += `isActive=${payload.isActive}&`;
  if (payload.isApproved) qstring += `isApproved=${payload.isApproved}&`;

  qstring += `pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}`;

  return apiTurnos.get(`api/user/search?${qstring}`);
};

export const createUser = (payload) => {
  return apiTurnos[payload.userId ? 'put' : 'post']('api/user', payload);
};

export const changeUserToOfficeAccount = (payload) => {
  return apiTurnos.post('api/user/ChangeUserToOfficeAccount', payload);
};

export const assignLocations = (payload) => {
  return apiTurnos.post('api/user/location/assign', payload);
};

export const getUserOffices = (userId, getAll = false, includeFromServiceQueueGroups = false) => {
  return apiTurnos.get(`api/user/locations/${userId}`, {
    params: { all: getAll, includeFromServiceQueueGroups: includeFromServiceQueueGroups},
  });
};
export const assignAppointmentLocations = (payload) => {
  return apiTurnos.post(`api/user/${payload.userId}/appointment/location/assign`, payload.locationConfigurationIds);
};

export const getUserAppointmentOffices = (userId) => {
  return apiTurnos.get(`api/user/${userId}/appointment/locations`);
};

export const getUserAndRolePermissionsList = (userId) => {
  return apiTurnos.get(`api/user/${userId}/permissions`);
};

export const addUserToPermissions = (payload) =>
  apiTurnos.post(`api/user/${payload.userId}/permissions`, payload.permissions);

export const getUserAttendantsOverWaiting = (locationConfigurationId) => {
  return apiTurnos.get('api/user/overwait/list', {
    params: { locationConfigurationId },
  });
};

export const getClientUsersList = (locationConfigurationId) => {
  return apiTurnos.get('api/user/client/list', {
    params: { locationConfigurationId },
  });
};

export const getClientUsersByMultiLocationList = (locationConfigurationIds) => {
  return apiTurnos.get('api/user/client/multilocation/list', {
    params: { locationConfigurationIds },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getTechnicianSetup = (payload) => {
  let qstring = '';

  if (payload.locationConfigurationId)
    qstring += `locationConfigurationId=${payload.locationConfigurationId}&`;

  if (payload.user) qstring += `user=${payload.user}&`;

  qstring += `pageIndex=${payload.pageIndex}&pageSize=${payload.pageSize}`;

  return apiTurnos.get(`api/user/search/techniciansetup?${qstring}`);
};

export const updateTechnicianSetup = (payload) =>
  apiTurnos.put(`api/user/techniciansetup`, payload);

export const getDefaultStationAndServiceQueue = (payload) => {
  return apiTurnos.get('api/user/default/stationservicequeue', {
    params: { ...payload },
  });
};

export const getAttenderList = (locationConfigurationId) => {
  return apiTurnos.get(`api/user/${locationConfigurationId}/attend/list`);
};

export const getProfileLists = (code) => {
  return apiTurnos.get(`api/user/profile/lists/${code}`);
};

export const updateProfileBasicInfo = (payload) => {
  return apiTurnos.put('api/user/profile/info', payload);
};

export const lockOrUnlock = (userId) =>
  apiTurnos.post(`api/user/${userId}/lock`);

export const getSecurityProfile = () =>
  apiTurnos.get('api/user/securityprofile');

export const changePassword = (payload) =>
  apiTurnos.post('api/user/password/change', payload);

export const getUsersForCompany = (payload) => {
  return apiTurnos.get('api/user/search/list', {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getUserDataDocument = (payload) => {
  let qstring = '';

  if (payload.roleIds) {
    for (let i = 0; i < payload.roleIds.length; i++) {
      qstring += `roleIds=${payload.roleIds[i]}&`;
    }
  }

  if (payload.companyId) {
    qstring += `companyId=${payload.companyId}&`;
  }

  if (payload.locationConfigurationId) {
    qstring += `locationConfigurationId=${payload.locationConfigurationId}&`;
  }

  if (payload.user) qstring += `user=${payload.user}&`;

  if (payload.isActive) qstring += `isActive=${payload.isActive}&`;

  return apiTurnos.get(`api/report/users?${qstring}`);
};

export const getUserAudit = (userId) =>
  apiTurnos.get(`api/user/${userId}/audit`);

export const isInternalChatActive = (payload) =>
  apiTurnos.get(`api/user/${payload.userId}/${payload.locationConfigurationId}/hasinternalchat`);

export const syncChatUserAccounts = (payload) =>
  apiTurnos.get("api/user/chat/account/sync",
  {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

export const getChatTurnInfo = (turnId) =>
  apiTurnos.get(`api/user/chat/turn/${turnId}`);

export const isRepresentativeTypeActive = (payload) =>
  apiTurnos.get(`api/user/${payload.userId}/${payload.locationConfigurationId}/isrepresentativetypeactive`);
