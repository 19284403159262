const IdentityPermission = Object.freeze({
  profile: {
    basic: {
      extra: {
        edit: 'profile.basic.extra.edit',
      },
    },
  },
  maintenance: {
    employeeStatus: {
      notPresent: 'maintenance.employeestatus.notpresent',
    },
  },
  sidebar: {
    none: 'sidebar.none',
  },
  multi: {
    office: 'multi.office',
    station: {
      ask: 'multi.office.station.ask',
    },
  },
  turns: {
    access: {
      record: 'turns.access.records',
      client: {
        manager: 'turns.access.client.manager',
      },
      list: {
        default: 'turns.access.list',
        actions: 'turns.access.list.actions',
        reprint: 'turns.access.list.reprint',
        edit: {
          turn: 'turns.access.list.edit.turn',
        },
        remove: {
          attended: 'turns.access.list.remove.attended',
        },
        surveys: 'turns.access.list.surveys',
        documents: 'turns.access.list.documents',
      },
      turn: {
        manager: {
          all: 'turns.access.turn.manager.all',
        },
      },
      broadcast: 'turns.access.broadcast',
    },
    manager: {
      automatic: {
        call: 'turns.manager.automatic.call',
      },
    },
  },
  break: {
    admin: {
      push: {
        enabled: 'break.admin.push.enabled',
      },
    },
    check: {
      enabled: 'break.check.enabled',
    },
  },
  station: {
    delete: 'stations.delete',
  },
  service: {
    queue: {
      custom: {
        notification: {
          manage: 'service.queue.custom.notification.manage',
        },
      },
      survey: {
        edit: 'service.queue.survey.edit',
      },
    },
  },
  user: {
    turn: {
      close: 'user.turn.close'
    }
  }
});

export default IdentityPermission;
