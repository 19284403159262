export default {
  annually: 'Annually',
  hello: 'Hello',
  welcome: 'Welcome',
  password: 'Password',
  createNewUserAccount: 'Create new user account',
  createdAccount: 'Created account',
  confirmPassword: 'Confirm password',
  name: 'Name',
  lastName: 'Last Name',
  paternalLastName: 'Paternal last name',
  maternalLastName: 'Maternal last name',
  cellPhone: 'Cellphone',
  cellPhones: 'Cellphones',
  provider: 'Provider',
  deliveryProvider: 'Delivery Provider',
  genre: 'Genre',
  birthDate: 'Birth date',
  residencePostalCode: 'Residence postal code',
  createNewUser: 'Create new user',
  company: 'Company',
  localization: 'Localization',
  services: 'Services',
  availableSpaces: 'Available Spaces',
  createSpaces: 'Spaces Creation',
  verifySpaces: 'Spaces Verification',
  view: 'View',
  return: 'Return',
  confirm: 'Confirm',
  select: 'Select',
  selectionofspecialists: 'Selection of Specialists',
  available: 'Available',
  createAppointment: 'Create an appointment',
  availability: 'Availability',
  availabilityOf: 'Availability of',
  confirmAppointmentAndVerify:
    'CONFIRM APPOINTMENT AND VERIFY CONTACT INFORMATION',
  confirmContactInformation: 'Please verify your contact information',
  confirmAppointmentValidation:
    'Are you sure you want to confirm the appointment?',
  confirmAppointmentValidInputErrorMessage:
    'Characters in name, paternal last name or maternal last name invalid, please verify',
  editAppointmentTitle: 'EDIT APPOINTMENT',
  selected: 'Selected',
  remove: 'Remove',
  removeAttended: 'Remove Attended',
  cantreactivateturn: "This turn can't be reactivated",
  delete: 'Delete',
  add: 'Add',
  availablespecialist: 'Available Specialist',
  selectedspecialist: 'Selected Specialist',
  selectall: 'Select All',
  filterspecialist: 'Filter Specialist',
  month: 'Month',
  week: 'Week',
  year: 'Year',
  day: 'Day',
  lun: 'Mon',
  mar: 'Tue',
  mie: 'Wed',
  jue: 'Thu',
  vie: 'Fri',
  sab: 'Sat',
  dom: 'Sun',
  trimester: 'Trimestre',
  of: 'of',
  spaces: 'spaces',
  space: 'space',
  notavailable: 'Not Available',
  changelanguage: 'Change Language',
  specialist: 'Specialist',
  durationHoursAndMinutes: 'Duration (Hours:Minutes)',
  start: 'Start',
  end: 'End',
  createspace: 'Create space',
  generalInformation: 'General Information',
  startDate: 'Start date',
  endDate: 'End date',
  fromDate: 'From Date',
  toDate: 'To Date',
  durationInMinutes: 'Duration (Minutes)',
  fromHour: 'From hour',
  toHour: 'To hour',
  ignoredHours: 'Hours to ignore',
  recurrency: 'Recurrency',
  repeatEach: 'Repeat each',
  occurrences: 'Occurrences',
  selectDatesToIgnore: 'Select dates to ignore',
  specialists: 'Specialists',
  days: 'Days',
  totalSpaces: 'Total spaces',
  selectedDays: 'Selected days',
  ignoredDates: 'Ignored dates',
  daily: 'Daily',
  weekly: 'Weekly',
  monthly: 'Monthly',
  annual: 'Annual',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  finishAfter: 'Finish after',
  finishOnThisDay: 'Finish on this day',
  reviewInfo: 'Review the information to be submitted',
  confirmation: 'Confirmation',
  office: 'Office',
  error_logout:
    'You have an active turn that you must close before exiting the system',
  attention: 'Attention',
  date: 'Date',
  cancel: 'Cancel',
  reset: 'Reset',
  save: 'Save',
  deleteavailablespace: 'Delete Available Space',
  areyousure: 'Are you sure',
  yes: 'Yes',
  no: 'No',
  lastlogin: 'Last Login',
  logout: 'Logout',
  changepassword: 'Change password',
  myprofile: 'My profile',
  review: 'Review',
  goBack: 'Go Back',
  turnsList: 'Turns List',
  number: 'Number',
  status: 'Status',
  visitTo: 'Visit to',
  visiting: 'Visiting',
  row: 'Row',
  customer: 'Customer',
  comment: 'Comment',
  attended: 'Attended',
  pending: 'Pending',
  arrivalTime: 'Arrival Time',
  waitTimeInMinutes: 'Wait Time (Minutes)',
  estimatedWait: 'Estimated Wait',
  appointment: 'Appointment',
  actions: 'Actions',
  simpleLastName: 'Last Name',
  email: 'Email',
  emails: 'Emails',
  installationNumber: 'Installation Number',
  caseNumber: 'Case Number',
  appointmentNumber: 'Appointment number',
  cited: 'Cited',
  mustEnterCited: 'Cited is required',
  blind: 'Visually Impaired',
  attendByPhone: 'Attend by Phone',
  phoneProvider: 'Phone provider',
  phoneNotification: 'Phone Notification',
  service: 'Service',
  appointmentService: 'Appointment Service',
  rowsAndAppointmentService: 'Rows and Appointment Service',
  listMaintenance: 'List Maintenance',
  customerMaintenance: 'Customer Maintenance',
  turnsManager: 'Turns Manager',
  advanceToRow: 'Advance to Row',
  advanceToStation: 'Advance to Station',
  calledRow: 'Called Row',
  startAList: 'Start a List',
  startNumberOrRowContinuation: 'Start Number or Row Continuation',
  unattendedTurnsQuestion:
    'There are still unattended turns on the list. What would you like to do?',
  keepOnlyUnattended: 'Keep only unattended turns on the list.',
  moveAllUnattendedTurns: 'Move all unattended turns to a specific row.',
  clearTheEntireList: 'Clear the entire list.',
  startOfTheList: 'Start of the list',
  auto: 'Auto',
  technicalSupport: 'Technical Support',
  continue: 'Continue',
  automatic: 'Automatic',
  process: 'Process',
  officedetailsmap: 'Office Details Map',
  filters: 'Filters',
  organization: 'Organization',
  period: 'Period',
  attentionperformance: 'Attention Performance',
  survey: 'Survey',
  surveybyoperator: 'Surveys By Operator',
  mainmetrics: 'Main Metrics',
  sessionexpiredtitle: 'Session expired',
  sessionexpiredcontent: 'Your session has expired due to lack of activity.',
  areyousuredeletespace: 'Are you sure you want to delete this space?',
  areyousurecloseturn: 'Are you sure you want to close this turn?',
  error: 'Error',
  incompleteinformation: 'Incomplete information',
  mustselectcompany: 'You must select a company',
  mustselectlocalization: 'You must select a location',
  mustselectorganization: 'You must select an organization',
  mustSelectService: 'You must select a service',
  mustselectservices: 'You must select at least one service',
  mustselectspecialist: 'You must select at least one specialist',
  mustselectcoordinator: 'You must select at least one coordinator',
  mustselecttime: 'You must select a time',
  mustselectdate: 'You must select a date',
  mustAddField: 'Must add your',
  requiredField: 'This field is required',
  areyousuregoback: 'Are you sure you want to return?',
  irreversibleoperationmessage:
    'This operation is irreversible, the fields selected will be deleted',
  all: 'All',
  noResultsFound: 'No results found',

  day_0: 'Sunday',
  day_1: 'Monday',
  day_2: 'Tuesday',
  day_3: 'Wednesday',
  day_4: 'Thursday',
  day_5: 'Friday',
  day_6: 'Saturday',

  frequency_type_1: 'Daily',
  frequency_type_2: 'Weekly',
  frequency_type_3: 'Monthly',
  frequency_type_4: 'Annually',
  finalize: 'Finalize',
  stamps: 'Stamps',
  close: 'Close',
  see: 'See',
  downloadAll: 'Download all',
  downloadStartedSuccessful: 'Download started sucessfully!',
  findDownloadLocally:
    "Please look for the software in your computer's download folder",
  downloadError: 'Error downloading',
  downloadErrorMessage:
    'Try again, if the error persists please contact the service administrator',
  downloadInstaller: 'Download Installer',
  'document(s)': 'Document(s)',
  documentType: 'Document type',
  action: 'Action',
  kiosk: 'kiosk',
  kiosks: 'kiosks',
  kioskName: 'Kiosk name',
  plusClientName: 'Appointment Plus client name',
  edit: 'Edit',
  assignRow: 'Assign row',
  kioskList: 'Kiosk list',
  assignKioskToOffice: 'Assign kiosk to office',
  create: 'Create',
  sendingNotifications: 'Sending notifications',
  screensAmount: 'Screens amount (levels)',
  configuration: 'Configuration',
  integrationWithApiWebService: 'Integration with API / Web Service',
  macAddress: 'Mac address',
  screenType: 'Screen type',
  buttonColor: 'Button color',
  borderColor: 'Border color',
  textColor: 'Text color',
  radiusSize: 'Radius size',
  borderSize: 'Border size',
  shadowSize: 'Shadow size',
  shadowColor: 'Shadow color',
  mustPickColor: 'You must pick a color',
  mustSelectOne: 'You must select one',
  mustSelectAtLeastOne: 'You must select at least one',
  topText: 'Top text',
  invalidField: 'Invalid field',
  columnsQuantity: 'Columns quantity',
  topTextColor: 'Top text color',
  topTextSize: 'Top text size',
  topTextBoldPercentage: 'Bold percentage',
  button: 'Button',
  buttons: 'Buttons',
  buttonText: 'Button text',
  sendTo: 'Send to',
  addButton: 'Add button',
  header: 'Header',
  content: 'Content',
  footer: 'Footer',
  backgroundColor: 'Background color',
  backgroundImage: 'Background image',
  logoPosition: 'Logo position',
  textPosition: 'Text position',
  logo: 'Logo',
  saveContinue: 'Save & Continue',
  text: 'Text',
  homeButtonText: 'Home button text',
  homeButtonTextColor: 'Home button text color',
  homeButtonBackgroundColor: 'Home button background color',
  homeButtonBorderColor: 'Home button border color',
  homeButtonRadiusSize: 'Home button radius size',
  homeButtonBorderSize: 'Home button border size',
  homeButtonShadowColor: 'Home button shadow color',
  backButtonText: 'Back button text',
  backButtonTextColor: 'Back button text color',
  backButtonBackgroundColor: 'Back button background color',
  backButtonBorderColor: 'Back button border color',
  backButtonRadiusSize: 'Back button radius size',
  backButtonBorderSize: 'Back button border size',
  backButtonShadowColor: 'Back button shadow color',
  officeSelection: 'Office Selection',
  apply: 'Apply',
  screen: 'Screen',
  specialField: 'Special field',
  specialField1: 'Special Field 1',
  specialField2: 'Special Field 2',
  specialField3: 'Special Field 3',
  specialField4: 'Special Field 4',
  specialField5: 'Special Field 5',
  registeredAppointments: 'Registered Appointments',
  directlyRegisteredAppointments: 'Directly Registered Appointments',
  filter: 'Filter',
  cancelAppointmentConfimation:
    'Are you sure you want to cancel this appointment?',
  syncChatUsersConfimation:
    'Are you sure you want to sync (create/update) every user from this company?',
  cancelAppointment: 'Cancel Appointment',
  sendAppointmentConfirmation:
    'Are you sure you want to resend the coordinated appointment notification?',
  resend: 'Resend',
  resendVideoCall: 'Resend video call link',
  linkResent: 'Link resent',
  dataEntryScreen: 'Data entry screen',
  termsAndConditions: 'Términos y Condiciones',
  requestPhone: 'Request phone',
  requestEmail: 'Request email',
  displayKeyboardForEmail: 'Display keyboard for email',
  displayKeyboardForPhone: 'Display keyboard for phone',
  dateFrom: 'Date from',
  dateTo: 'Date to',
  hourFrom: 'Hour from',
  hourTo: 'Hour to',
  hour: 'Hour',
  coordinator: 'Coordinator',
  phone: 'Phone',
  mustEnterEmail: 'Must enter an email',
  mustentercorrectemail: 'Must type a valid email',
  mustEnterName: 'Must enter your name',
  mustEnterLastName: 'Must enter last name',
  mustEnterAttendees: 'Must enter the attendee count',
  mustEnterAppointmentNumber: 'Must enter appointment number',
  maxNumberOfAttendees: 'Exceeded the max of {0} atendees',
  schedule: 'Schedule',
  client: 'Client',
  complete: 'Complete',
  resendconfirmation: 'Resend Confirmation',
  address: 'Address',
  attendees: 'Attendees',
  previous: 'Previous',
  appointmentinformation: 'Appointment information',
  cancelreasons: 'Cancel Reason',
  mustselectreason: 'Must select a reason',
  mustwritecomment: 'Must write a comment',
  search: 'Search',
  searchresults: 'Search results',
  requestappointment: 'Request an appointment',
  appointmentInformationBellow:
    'The information of the selected appointment is as follows',
  appointmentadditionalinformation: 'Appointment additional information',
  appointmentinformationreceived:
    'Your appointment and contact information has been received',
  appointmentinformationreceived2: 'Your appointment confirmation number is',
  kioskregistered: 'Registered Kiosks',
  coordinated: 'Coordinated',
  cancelled: 'Cancelled',
  completed: 'Completed',
  percCompleted: '% Completed',
  approved: 'Approved',
  denied: 'Denied',
  extended: 'Extended',
  confirmed: 'Confirmed',
  registered: 'Registered',
  incomplete: 'Incomplete',
  Recoordinated: 'Recoordinated',
  NotPresent: 'Not Present',
  Unknown: 'Unknown',
  serviceConfiguration: 'Service Configuration',
  mustSelectRow: 'Must select a row',
  selectRow: 'Select Row',
  selectService: 'Select Service',
  selectSpecialField: 'Special row field',
  showRowCounter: 'Show person in row',
  showRowCounterText: 'Text number of people in row',
  requestPaternalLastName: 'Request paternal last name',
  paternalLastNameRequired: 'Paternal last name required',
  requestMaternalLastName: 'Request maternal last name',
  maternalLastNameRequired: 'Maternal last name required',
  displayKeyboard: 'Display keyboard',
  displayPersonTurn: "Display person's turn",
  displayHowMuchIsLeftBefore: 'Display how much is left before',
  saveButtons: 'Save buttons',
  kioskCreation: 'Kiosk creation',
  screenMapping: 'Screen mapping',
  phoneRequired: 'Phone is required',
  center: 'Center',
  right: 'Right',
  left: 'Left',
  activeLocation: 'Active Location',
  assignMacAddress: 'Assign Mac Address',
  enterTheMacAddress: 'Please Enter the MAC Address',
  cantDoSearchHigherThanDays: "Can't do search of more than 1 month.",
  cantDoSearchGreaterThanDays:
    "Can't do search with Date from greater than Date to",
  macAddressError: 'There must be at least one MAC Address.',
  noRecordWasFound: 'No record was found.',
  items: 'Items',
  item: 'Item',
  macAddressExits:
    'Mac address entered already exists registered for another kiosk.',
  macAddressGeneralError: 'There was an error in the Mac Address listing.',
  macAddressRepeated: 'Mac Address Repeated.',
  cancelSpaceConfimation: 'Are you sure you want to cancel this process?',
  clone: 'Clone',
  copyTexts: 'Copy texts',
  copyLogos: 'Copy logos',
  copyImages: 'Copy images',
  copyColors: 'Copy colors',
  kioskClonedSuccessfully: 'Kiosk cloned successfully',
  noKioskFoundForFilter: 'No kiosks found according to the selected filters',
  success: 'Success',
  '100Lightest': '100 - Lightest',
  '400Normal': '400 - Normal',
  '700Bold': '700 - Bold',
  '900Boldest': '900 - Boldest',
  pwaName: 'PWA name',
  mainColors: 'Main colors',
  headerText: 'Header text ESP',
  headerTextEN: 'Header text ENG',
  buttonBorderColor: 'Button border color',
  checkInPrimaryText: 'CheckIn button main text ESP',
  checkInPrimaryTextEN: 'CheckIn button main text ENG',
  checkInSecondaryText: 'CheckIn button secondary text ESP',
  checkInSecondaryTextEN: 'CheckIn button secondary text ENG',
  followPrimaryText: 'Follow turn button main text ESP',
  followPrimaryTextEN: 'Follow turn button main text ENG',
  followSecondaryText: 'Follow turn button secondary text ESP',
  followSecondaryTextEN: 'Follow turn button secondary text ENG',
  appointmentPrimaryText: 'Appointment button main text ESP',
  appointmentPrimaryTextEN: 'Appointment button main text ENG',
  appointmentSecondaryText: 'Appointment button secondary text ESP',
  appointmentSecondaryTextEN: 'Appointment button secondary text ENG',
  iconColor: 'Icon color',
  mainTextColor: 'Main text color',
  secondaryTextColor: 'Secondary text color',
  linksColor: 'Links color',
  mainScreensTextColor: 'Main screens text color',
  requiresReferralToAppointments: 'Show take appointment button',
  pwaRegistered: 'Registered PWAs',
  showStartMessage: 'Show start message',
  showEnglish: 'Show in english',
  showTakeTurnButton: 'Show take turn button',
  showing: 'Showing',
  results: 'results',
  to: 'to',
  noPwaFoundForFilter: 'No PWAs found according to the selected filters',
  pwaCreation: 'PWA creation',
  pwaClonedSuccessfully: 'PWA cloned successfully',
  systemDoesNotLetMore: 'The system does not allow more than',
  spaceAmount: 'current amount of spaces',
  dateLimitExceeded: 'Date limit exceed',
  defaultKiosk: 'Default Kiosk',
  preview: 'Preview',
  previewView: 'Preview View',
  massCreateUsers: 'Mass Create Users',
  noUserFoundForFilter: 'No users found according to the selected filters',
  assignOffice: 'Assign Office',
  mobile: 'Mobile',
  mobileNumber: 'Mobile Number',
  securityProfile: 'Security profile',
  roles: 'Roles',
  filterRole: 'Filter Role',
  roleName: 'Role Name',
  listOfRoles: 'List of Roles',
  userName: 'UserName',
  active: 'Active',
  blocked: 'Blocked',
  userslist: 'Users List',
  users: 'Users',
  filterusers: 'Filter Users',
  filterkiosks: 'Filter Kiosks',
  pwas: 'PWAs',
  filterpwas: 'Filter PWAs',
  usermaintenance: 'User Maintenance',
  kioskmaintenance: 'Kiosks Maintenance',
  pwamaintenance: 'PWAs Maintenance',
  parametermaintenance: 'Parameters Maintenance',
  appointmentplusmaintenance: 'Appointment Plus Maintenance',

  // Start: These items are linked to roles, cannot be camel cased
  roleadministrator: 'Administrator',
  rolecitas: 'Appointment user',
  roleclient: 'Supervisor',
  roleclientautologin: 'Auto-Register',
  roleclientlist: 'Registry',
  roleclientuser: 'Client user',
  roledisplayuser: 'User for display',
  rolelocaluseradministrator: 'Local user administrator',
  rolesalesuser: 'User for sales',
  rolesponsoruser: 'User for Advertisers',
  rolesystemadmin: 'System administrator',
  roleuseradmin: 'User administrator',
  rolewebuser: 'Citizen',
  rolemonitor: 'Monitor',
  // End

  noCompany: 'No Company',
  noOffice: 'No Office',
  headerLogoImage: 'Header Logo image',
  headerSecondaryText: 'Header Secondary text ESP',
  headerSecondaryTextEN: 'Header Secondary ENG',
  headerSecondaryTextColor: 'Header Secondary text color',
  createuser: 'Create User',
  edituser: 'Edit User',
  userinformation: 'User Information',
  role: 'Role',
  specialid: 'Special ID',
  userparameters: 'User Parameters',
  temporalpassword: 'Temporal password',
  receiptappointmentconfirmation: 'Receipt Appointments Confirmation',
  appointmentNumberuser: 'Appointment User',
  activeaccount: 'Active Account',
  blockedaccount: 'Blocked Account',
  tempPassword: 'Temporary password',
  receiveappointmentconfirmation: 'Receives appointment confirmation',
  appointmentUser: 'Appointment user',
  usernamerequired: 'Username is required',
  passwordrequired: 'Password is required',
  namerequired: 'Name is required',
  mustselectrole: 'Must select a role',
  mustselectsecurityprofile: 'Must select a security profile',
  specialidrequired: 'Special ID is required',
  usertemplate: 'User template',
  loadcsv: 'Load CSV',
  proceedToSaveAreYouSure:
    'Proceeding to save the information detailed in the screen. Are you sure you want to continue? If you want to edit the information, press back.',
  pleaseSelectOptionsSearch:
    'Please select the options and press ‘Search’ to view the available spaces for your appointment.',
  previousAppointmentFound:
    'This citizen already has a prior appointment for the same service requested',
  buttonOrder: 'Button order',
  order: 'Order',
  changeRole: 'Change role',
  changeRoleToOffice: 'Change to office role',
  emailRequired: 'Email is required',
  monitors: 'Monitors',
  monitorsRegistered: 'Registered Monitors',
  filterMonitors: 'Filter Monitors',
  monitorName: 'Monitor name',
  noMonitorsFoundForFilter:
    'No Monitors found according to the selected filters',
  viewMacAddress: 'View Mac Address',
  monitorCreation: 'Monitor creation',
  parameters: 'Parameters',
  voiceOnMonitor: 'Voice on monitor',
  showName: 'Show name',
  showTurnNumber: 'Show turn number',
  videoVolume: 'Video volume',
  ringVolume: 'Ring volume (voice)',
  displayFollowUpMessage: 'Display follow-up message',
  sampleTown: 'Sample Town',
  showLiabilityRelease: 'Show liability release',
  displayMessageOnMonitorBackground: 'Display message on monitor background',
  messageOnMonitorBackground: 'Monitor message background',
  monitorStyles: 'Monitor Styles',
  headerPrimaryLogo: 'Left main logo',
  textSize: 'Text size',
  rightSecondaryLogo: 'Right secondary logo',
  wallpaperColor: 'Wallpaper color',
  callbar: 'Call bar',
  numberTextColor: 'Number text color',
  nameTextColor: 'Name text color',
  stationTextColor: 'Station text color',
  sideTurns: 'Side turn',
  editTurn: 'Edit Turn',
  headerRowBackgroundColor: 'Row header background color',
  headerRowTextColor: 'Header row text color',
  turnContentBackgroundColor: 'Turn content background color',
  turnNumberColor: 'Turn number color',
  stationColor: 'Station color',
  adsContainer: 'Ads container',
  monitorFoot: 'Monitor foot',
  pleaseEnterDisplayName:
    'Are you sure you want to delete the display? To delete it please write the word ',
  inTheBox: 'in the box',
  sideTurnBorderColor: 'Border color',
  adLiabilityReleaseTextColor: 'Liability release text color',
  footerBackgroundColor: 'Backgroudn color',
  displayType: 'Display Type',
  displayNameIsNotCorrect: 'Display name is not correct.',
  nameColor: 'Name color',
  pleaseEnterMonitorName: 'Please enter monitor name',
  fileRequired: 'The file is required',
  assignUserToOffices: 'Assing User To Offices',
  listOfOffices: 'List of offices',
  onlyLocationsThatDoNotHaveAPWA:
    'Only locations that do not have a PWA configuration will be displayed',
  saveClose: 'Save & close',
  userParameters: 'User parameters',
  userIdentifier: 'User identifier',
  assignUser: 'Assign user',
  assignTicket: 'Ticket user',
  toOffices: 'to offices',
  maxImageSize:
    'The image does not comply with the corresponding dimensions, it must be a maximum of',
  fontSize: 'Font size',
  saveSuccessful: 'Save successful',
  operationCompleted: 'Operation completed',
  displayClonedSuccessfully: 'Display cloned successfully',
  DisplayMessageCost: 'Display message cost',
  listOfParameters: 'List of Parameters',
  displayNameOrNumberRequired:
    "You must check at least one option between 'Show Name' and 'Show Turn'",
  key: 'Key',
  value: 'Value',
  description: 'Description',
  nameApptPlus: 'Appointment Plus Name',
  appointmentPlusName: 'Appointment Plus Name',
  listOfAppointmentPlus: 'List Of Appointments Plus',
  noAppointmentPlusFoundForFilter:
    'No appointments plus found according to the selected filters',
  pleaseEnterTheName: 'Please enter name',
  nameIsNotCorrect: 'Name is not correct',
  areYouSureYouWantToDelete: 'Are you sure you want to delete?',
  successfullyDeleted: 'Successfully deleted',
  pleaseEnterParameterKey: 'Please enter parameter key',
  pleaseEnterParameterValue: 'Please enter parameter value',
  pleaseEnterParameterDescription: 'Please enter parameter description',
  completeParameterInformation: 'Complete parameter information',
  contentTitle: 'Content title',
  proceedToSave:
    'Proceeding to save the information detailed in the screen. Are you sure you want to continue?',
  parameterKey: 'Parameter key',
  parameterKeyIsNotCorrect: 'Parameter key is not correct',
  kioskNameIsNotCorrect: 'Kiosk name is not correct',
  pwaNameIsNotCorrect: 'PWA name is not correct',
  plusClientNameIsNotCorrect: 'Appointment Plus client name is not correct',
  toDeleteWriteTheWord: 'To delete it please write the word',
  toCloseWriteTheNumber: 'To close it please write the number',
  specialFields: 'Special fields',
  continueButtonText: 'Continue button text',
  keyboardType: 'Keyboard type',
  numeric: 'Numeric',
  alphanumeric: 'Alphanumeric',
  valueDoesNotMatch: 'The value does not match the legacy system',
  plusClientCreation: 'Plus client creation',
  plusClientWebConfiguration: 'Web configuration',
  plusClientMobileConfiguration: 'Mobile configuration',
  plusClientPersonalizedFields: 'Personalized Fields',
  plusClientPreloadService: 'Preload service',
  plusClientAllowServiceChange: 'Allow service change',
  plusClientAllowSubService: 'Allow sub service',
  plusClientAllowCitySelection: 'Allow city selection',
  plusClientDisplayOnlyWithAvailableSpaces:
    'Display only with available spaces',
  plusClientAllowDocumentUpload: 'Allow document upload',
  plusClientClonedSuccessfully: 'Plus Client cloned successfully',
  only3FieldsCanBeConfiguredPerScreen:
    'Only 3 fields can be configured per screen',
  blurRate: 'Blur rate',
  headerBackgroundColor: 'Header background color',
  headerBottomBorderColor: 'Header bottom border color',
  headerTextColor: 'Header text color',
  headerTextSize: 'Header text size',
  buttonBackgroundColor: 'Button background color',
  buttonFontColor: 'Button font color',
  buttonFontSize: 'Button font size',
  buttonBorderSize: 'Button border size',
  modalButtonBackgroundColor: 'Modal button background color',
  modalButtonFontColor: 'Modal button font color',
  modalButtonFontSize: 'Modal button font size',
  modalButtonBorderColor: 'Modal button border color',
  modalButtonBorderSize: 'Modal button border size',
  tableButtonBackgroundColor: 'Table button background color',
  tableButtonFontColor: 'Table button font color',
  tableButtonFontSize: 'Table button font size',
  tableButtonBorderColor: 'Table button border color',
  tableButtonBorderSize: 'Table button border size',
  headerSection: 'Header section',
  buttonsSection: 'Buttons section',
  controlsSection: 'Controls section',
  calendarSection: 'Calendar section',
  tablesSection: 'Tables section',
  footerSection: 'Footer section',
  backgroundColorElement: 'Background color element',
  elementTextColor: 'Element text color',
  elementTextSize: 'Element text size',
  elementBorderColor: 'Element border color',
  selectedDateBackgroundColor: 'Selected date background color',
  textBackgroundColorSelectedDate: 'Text background color selected date',
  rowColor: 'Row color',
  alternateRowColor: 'Alternate row color',
  completedIconColor: 'Completed icon color',
  clear: 'Clear',
  shapeTextColor: 'Shape text color',
  controlColor: 'Control color',
  controlTextSize: 'Control text size',
  controlLabelColor: 'Control label color',
  boldPercentOfTheField: 'Bold percent of the field',
  fieldBackgroundColor: 'Field background color',
  hypertextColor: 'Hypertext color',
  colorOfTheSelectedCheckbox: 'Color of the selected checkbox',
  checkboxColor: 'Checkbox color',
  boldPercentOfModalField: 'Bold percent of modal field',
  controlIconColor: 'Control icon color',
  modalFieldLetterSize: 'Modal field letter size',
  modalFieldFontColor: 'Modal field font color',
  modalFieldBackgroundColor: 'Modal field background color',
  fieldLetterSize: 'Field letter size',
  fieldFontColor: 'Field font color',
  copyWebStyle: 'Copy web style',
  cleanStyle: 'Clean style',
  doYouWantToRemoveAllStyles:
    'Are you sure you want to clean the fields? this action is irreversible.',
  contentBackgroundColor: 'Content background color',
  contentTitleFontSize: 'Content title font size',
  boldPercentOfTheTitle: 'Bold percent of the title',
  titleFontColor: 'Title font color',
  contentSection: 'Content section',
  documentUploadBackgroundColor: 'Document upload background color',
  documentUploadFontColor: 'Document upload font color',
  documentDownloadBackgroundColor: 'Document download background color',
  documentDownloadFontColor: 'Document download font color',
  rowsPerPage: 'Rows per page',
  roleCreation: 'Role creation',
  assignPermission: 'Assign permission',
  userCreation: 'User creation',
  userEdit: 'User edit',
  permissions: 'Permissions',
  usersMaintenance: 'Users maintenance',
  userCompleteParameterInformation: 'You must select at least 2 parameters',
  activeAccount: 'Active account',
  blockedAccount: 'Blocked acount',
  roleInformation: 'Role information',
  roleMaintenance: 'Role maintenance',
  rolesMaintenance: 'Roles maintenance',
  employeeStatus: 'Employee Status',
  timeOfBreak: 'Break Time',
  generate: 'Generate',
  lastActivityDate: 'Last Activity Date',
  lastStation: 'Last Station',
  lastRow: 'Last Row',
  statusTimeMin: 'Status Time (Min)',
  type: 'Type',
  approveOrDenyBreak: 'Approve or Deny Break',
  beginningOfBreak: 'Beginning of Break',
  breakReason: 'Break reason',
  maximumTime: 'Maximum Time',
  timeOverBreak: 'Time Over Break',
  technicalSetup: 'Technical Setup',
  operationLog: 'Operation Log',
  operationAdministrativeLog: 'Operation Administrative Log',
  closeTechnicianSection: 'Close Technician Session',
  callTurn: 'Call Turn',
  endTurn: 'End Turn',
  transferTurn: 'Transfer Turn',
  placeInHold: 'Place in Hold',
  placeInRecess: 'Place in Recess',
  notPresent: 'Not Present',
  attending: 'Attending',
  openStations: 'Open Stations',
  breaks: 'Breaks',
  takeABreak: 'Take a Break',
  customerToAttend: 'Client to Attend',
  aboutWaitingTime: 'Overwaiting time exceeded',
  customerAttended: 'Customer Attended',
  roleNameIsNotCorrect: 'Role name is not the correct',
  clients: 'Clients',
  regular: 'Regular',
  clientsWaitingEstimatedTime: 'Clients Waiting Over Estimated Time',
  waitingClients: 'Waiting clients',
  attendedClients: 'Attended clients',
  overwaiting: 'Overwaiting',
  totalTechnicians: 'Total technicians',
  totalTechniciansConfigured: 'Total technicians configured',
  totalTechniciansNotConfigured: 'Total technicians not configured',
  listOfTechnicians: 'List of technicians',
  processLogs: 'Process Logs',
  filterProcessLogs: 'Filter Process Logs',
  processStatus_P: 'Pending',
  processStatus_R: 'Running',
  processStatus_S: 'Success',
  fixedRow: 'Fixed row',
  baseRow: 'Base row',
  fixedBaseRow: 'Fixed base row',
  station: 'Station',
  fixedStation: 'Fixed station',
  individualCallAvailable: 'Individual call available',
  viewListOfTurns: 'View List of turns',
  removeTurns: 'Remove turns',
  officeRequired: 'Office is required',
  break: 'Break',
  cannotCloseSessionUserAttendingClient:
    'User is attending client, please finish it before closing the session',
  youMustSelectAnOptionOfViewShiftList:
    'You must select an option of view shift list',
  breakType: 'Break Type',
  call: 'Call',
  turnCalledSuccessfully: 'Turn called successfully',
  turnFinalizedSuccessfully: 'Turn finalized successfully',
  turnMarkedAsNotPresentSuccessfully: 'Turn marked as not present successfully',
  statusCalled: 'Called',
  statusUncalled: 'Uncalled',
  subService: 'Sub Service',
  turnTransferredSuccessfully: 'Turn Transferred Successfully',
  registeredRepresentative: 'Registered representatives',
  employeeStatusList: 'Employee status list',
  requestingRecess: 'Requesting break',
  excessRecess: 'Excess break',
  currentDashboardData: 'Current Data Dashboard',
  currentStatisticsSummary: 'Current Statistics Summary',
  statisticsSummary: 'Statistics Summary',
  callStatisticsSummary: 'Call Statistics Summary',
  considerTimes: 'Consider Times 0',
  considerAppointmentDate: 'Consider Appointment Date',
  externalDisplay: 'External Display',
  includeWaitingQueues: 'Include waiting queues',
  dailyList: 'Daily List',
  dailyListByService: 'Daily List by Service',
  videoCall: 'Start video call',
  currentList: 'Current List',
  currentSimpleList: 'Current Simple List',
  realTimeRegionalDashboard: 'Real time regional dashboard',
  refreshReport: 'Refresh report',
  totalClients: 'Total clients',
  totalAttended: 'Total attended',
  totalUnattended: 'Total unattended',
  transactionsToAttend: 'Transactions to attend',
  averageWaitingMinutes: 'Average waiting minutes',
  averageServiceTime: 'Average service time',
  totalOfficeAverage: 'Total office average',
  totalOpenStations: 'Total open stations',
  export: 'Export',
  localityName: 'Locality name',
  inRecess: 'In recess',
  multiLanguage: 'Card Reader',
  hasCardReader: 'Has Card Reader',
  displayRowName: 'Display row name',
  peopleNotPresent: 'People Not Present',
  turnReactivated: 'Turn reactivated successfully',
  dashboard: 'Dashboard',
  timeFrom: 'Time From',
  timeTo: 'Time To',
  format: 'Format',
  displayBy: 'Display By',
  rowCategories: 'Row Categories',
  serviceCategory: 'Service Categories',
  extendedStatisticsReport: 'Extended Statistics Report',
  breaksTakenByEmployee: 'Breaks Taken by Employee',
  deletedTurnsReport: 'Deleted Turns Report',
  employeeHistory: 'Employee History',
  statisticsSummaryMultiOffice: 'Statistics Summary Multi-Office',
  statisticsSummaryServiceMultiOffice:
    'Statistics Summary Service Multi-Office',
  averageServiceTimePerEmployee: 'Average Service Time per Employee',
  averageTimePerService: 'Average Time per Service',
  employee: 'Employee',
  orderBy: 'Order by',
  groupByOffice: 'Group by Office',
  personPerHourByOffice: 'Person per Hour by Office',
  personPerHourServiceByOffice: 'Person per Hour by Office (Service Group)',
  offices: 'Offices',
  peopleCited: 'People Cited',
  stampStatus: 'Stamp Status',
  appointmentsStatus: 'Appointments Status',
  waitingTimeByOffice: 'Waiting time by Office',
  stampCancelation: 'Stamp Cancelation',
  averageServiceTimePerQueue: 'Average Time per Queue',
  averageServiceTimePerService: 'Average Time per Service',
  appointmentsAvailabeVsAppointmentsTaken:
    'Appointments Available vs Appointments Taken',
  queue: 'Queue',
  responseResultsGraphs: 'Response Results Graphs',
  responseResults: 'Response Results',
  answersAndTurnData: 'Surveys answers with comments',
  answers: 'Answers',
  appointmentRecoodinationAndExport: 'Appointment Export/Recoordination',
  emailOrAppointmentNumber: 'Email or Appointment Number',
  selectOfficeForTurnsManager:
    'You must select an office to be able to use the turns manager',
  isBlind: 'Is blind',
  appointmentHour: 'Appointment hour',
  fieldRequired: 'Field is required',
  searchNotFound: 'It was not found. The search for him produced no results.',
  seeAvailableAppointments: 'See available appointments',
  recordsLimit: 'Records Limit',
  statusCalledNotPresent: 'Called not present',
  reactivated: 'Reactivated',
  print: 'Print',
  checkPrinter: 'Please, check if a ZPL capable printer is set as default',
  turnUnCalled: 'Turn uncalled',
  turnMarkedNotPresent: 'Turn marked not present',
  turnWithStamp: 'Turn with stamp',
  turnCalled: 'Turn called',
  turnAttended: 'Turn attended',
  notCalled: 'Not called',
  called: 'Called',
  appointmentForToday: 'Appointment for today',
  appointmentTime: 'Appointment time',
  noAppointmentsAvailable: 'There are no appointments available for today',
  searchCited: 'Search cited by name',
  turnManager: 'Turn Manager',
  rowCall: 'Row Call',
  advanceRow: 'Advance row',
  advanceStation: 'Advance station',
  turnsQuantity: 'Turns quantity',
  clientsQuantity: 'Clients quantity',
  customersServed: 'Customers served',
  transactionsServed: 'Transactions served',
  totalTransactions: 'Total Transactions',
  takeTurn: 'Take Turn',
  reassignToAnotherRow: 'Reassign to another row',
  moveToAnotherRow: 'Move to another row',
  finalizeTurn: 'Finalize Turn',
  ok: 'OK',
  errorCreatingList: 'Error creating list',
  singleCall: 'Single Call',
  removeTurn: 'Remove turn',
  reprint: 'Reprint',
  surveys: 'Surveys',
  turnNumberIsNotCorrect: 'Turn number is not correct.',
  callWithMSTeams: 'Call with Microsoft Teams®',
  showDocuments: 'Show documents',
  instantCall: 'Instant call',
  editTurnInfo: 'Edit turn information',
  attendeds: 'Attended',
  attendedsF: 'Attended',
  notAttended: 'Not Attended',
  notAttendedF: 'Not Attended',
  total: 'Total',
  transactions: 'Transactions',
  allTurnsWereAttended: 'All turns have been attended.',
  reCall: 'Call again',
  statistics: 'Statistics',
  serviceTotalTime: 'Service Total Time',
  reassign: 'Reassign',
  selectMotive: 'Select a motive',
  commentRequired: 'Comment is required',
  commentMinLength: 'The comment must have a minimum of {0} characters',
  commentMaxLength: 'The comment must have a maximum of {0} characters',
  selectOfficeForSystem:
    'You must select an office to be able to use the system',
  errorLogginIntoOffice: 'Error logging into the office',
  breakNotification:
    'At the end of the shift, the system will automatically take you out for the requested break - {0}',
  breakNotificationHtml:
    'At the end of the shift, the system will automatically take you out for the requested break <br/><br/><h4 class="red">{0}</h4>',
  notification: 'Notification',
  breakExistMessage: 'You started your break at',
  timeInBreak: 'Time in break',
  continueWorking: 'Continue Working',
  cantChangeLocationActiveTurn:
    'You cannot change location if you have an active turn',
  cantChangeLocationActiveWaitingRoom:
    'You cannot change location if you have an active waiting room',
  youMustEndTurnToLogOut: 'You must end the turn to be able to log out',
  invalidActiveTurn: 'Invalid active turn',
  noAlertsAvailable: 'No alerts available',
  residenceZIPCode: 'Residence ZIP Code',
  ethnicity: 'Ethnicity',
  male: 'Male',
  female: 'Female',
  other: 'Other',
  stationNameIsNotCorrect: 'Station name is not the correct',
  listOfStations: 'List of Stations',
  stationsMaintenance: 'Stations Maintenance',
  filterStations: 'Filter stations',
  stationName: 'Station name',
  selectOfficeForTheMaintenance:
    'You must select an office to be able to use the maintenance',
  createStation: 'Create station',
  stationMaintenance: 'Station maintenance',
  stationInformation: 'Station information',
  mustUpdateTheRoleToModifyPermissions:
    'You must update the role before you can modify permissions',
  update: 'Update',
  extraField: 'Extra Field',
  block: 'Block',
  unlock: 'Unlock',
  rolePermission: 'rolePermission',
  addedPermission: 'Added Permission',
  serviceQueueMaintenance: 'Service Queue Maintenance',
  officeMaintenance: 'Office Maintenance',
  serviceQueueGroupMaintenance: 'Service Queue Group Maintenance',
  filterServiceQueues: 'Filter service queues',
  filterOffices: 'Filter offices',
  filterServiceQueueGroups: 'Filter service queue groups',
  serviceTypeGroupMaintenance: 'Service Type Group Maintenance',
  appointmentServiceTypeGroupMaintenance:
    'Appointment Service Type Group Maintenance',
  filterServiceTypeGroups: 'Filter service type groups',
  filterAppointmentServiceTypeGroups: 'Filter appointment service type groups',
  listOfServiceQueues: 'Service queues list',
  serviceQueueName: 'Service queue name',
  serviceQueueGroupName: 'Group name',
  serviceQueueNumber: 'Service queue number',
  automaticCall: 'Automatic call',
  serviceQueueToMove: 'Service queue to move',
  daysAvailable: 'Days available',
  hoursAvailable: 'Hours available',
  autoregisterConfiguration: 'Autoregister configuration',
  kioskSpecialField: 'Kiosk Special Field',
  pageBackgroundColor: 'Page Background Color',
  footerBackgroundTopColor: 'Top Background Color',
  footerBackgroundBottomColor: 'Lower Background Color',
  footerFontTopColor: 'Top Font Color',
  footerFontBottomColor: 'Lower Background Color',
  mustBeAtLeast2Chars: 'Must be at least 2 characters',
  footerButtonBackgroundColor: 'Footer Button Background Color',
  footerButtonTextColor: 'Footer Button Text Color',
  currentPassword: 'Current password',
  newPassword: 'New password',
  confirmNewPassword: 'Confirm password',
  newPasswordDifferentCurrent:
    'New password must be different from the current one',
  selectOneStation: 'Select one station',
  stationsName: 'Stations name',
  unlocked: 'Unlocked',
  location: 'Location',
  locations: 'Locations',
  serviceMaintenance: 'Service Maintenance',
  filterServices: 'Filter services',
  listOfServices: 'Services list',
  nameOfServiceESP: 'Name of Service [ESP]',
  nameOfServiceENG: 'Name of Service [ENG]',
  autoregisterNameOfServiceESP: 'AutoRegistration Name of Service [ESP]',
  autoregisterNameOfServiceENG: 'AutoRegistration Name of Service [ENG]',
  isCommentRequired: 'Comment required?',
  waitingTimeInMinutes: 'Waiting time in minutes',
  attentionGoalTime: 'Attention goal time',
  autoregistrationOrder: 'AutoRegistration Order',
  availableHoursForService: 'Available hours for service',
  kioskHoursForService: 'Kiosk hours for service',
  availableDaysForService: 'Available days for service',
  serviceName: 'Service name',
  selfRegistrationServiceName: 'Self registration service name',
  waitTimeInMinuts: 'Wait time in minutes',
  targetAttentionTime: 'Target attention time',
  orderInSelfregistration: 'Order in self registration',
  commentIsRequired: 'Comment is required',
  businessDays: 'Business days',
  businessHours: 'Business hours',
  businessHoursKiosk: 'Business hours kiosk',
  copyBusinessHours: 'Copy business hours',
  copyWorkingDays: 'Copy working days',
  copyAttentionTargetTime: 'Copy attention target time',
  copyGoalWaitTime: 'Copy goal wait time',
  cloneService: 'Clone service',
  exit: 'Exit',
  sessionAboutToExpireTitle: 'Session about to expire',
  sessionAboutToExpireContent:
    'The current session is about to expire. Press <b>Continue</b> if you do not want to be logged out.',
  sessionWillExpireIn: 'The session will expire in',
  serviceQueueStartingLetter: 'Queue starting letter',
  serviceQueueStartingNumber: 'Queue starting number',
  createServiceQueue: 'Create Queue',
  createServiceQueueGroup: 'Create queue group',
  editServiceQueueGroup: 'Edit queue group',
  createServiceTypeGroup: 'Create service group',
  editServiceTypeGroup: 'Edit service group',
  automaticServiceQueuesMaintenance: 'Automatic service queues maintenance',
  automaticServiceQueuesMaintenanceMessage:
    'Queues for automatic call when there are no more people in the row',
  serviceQueueConfiguration: 'Service queue configuration',
  serviceQueueAssociatedServices: 'Services associated to the queue',
  moveToAssociatedQueues: 'Move to associated queues',
  daysAvailableForWork: 'Days available for work',
  workingHours: 'Working hours',
  autoregisterConfiguration2: 'Autoregister configuration',
  publicAliases: 'Public aliases',
  cloneQueue: 'Clone queue',
  queueNameIsNotCorrect: 'Queue name is not correct.',
  groupNameIsNotCorrect: 'Group name is not correct.',
  domainNameIsNotCorrect: 'Domain name is not correct.',
  serviceNameIsNotCorrect: 'Service name is not correct.',
  publicAlias: 'Public Alias',
  colorInScreen: 'Color in Display',
  autoSequential: 'Auto Sequential',
  visibleInRegistry: 'Visible in Registry',
  visibleInHandler: 'Visible in Handler',
  canAssignToAnotherUser: 'Can not assign to another user',
  considerVisitTo: 'Consider visiting',
  waitingQueue: 'Waiting Queue',
  commentRequired2: 'Comment Required',
  videoCall2: 'Video Call',
  transferVisitTo: 'Transfer visit to',
  instantRecord: 'Instant Record',
  totalTurnsPerQueue: 'Turns total per queue',
  alertWhenX: 'Alert with X shift in advance',
  displayInMonitor: 'Display in monitor',
  associatedQueuesList: 'Associated Queues List',
  associatedServicesList: 'Associated Services List',
  all2: 'All',
  startingLetter: 'Starting letter',
  userEmail: 'User / Email',
  breakMaintenance: 'Break Maintenance',
  filterBreak: 'Filter Breaks',
  listOfBreak: 'Breaks list',
  breakName: 'Break name',
  breakTime: 'Break time (minutes)',
  finalStep: 'Final step',
  implicit: 'Implicit',
  deleteVisitRelationship: 'Delete visit relationship to',
  documentBreak: 'Document break',
  autoApproved: 'Auto approved',
  configureLocation: 'Configure location',
  createBreak: 'Create break',
  updateBreak: 'Update break',
  breakNameIsNotCorrect: 'Break name is not correct',
  deleteAppointmentSpaces: 'Delete Appointment Spaces',
  verifyAppointmentSpacesToDelete: 'Appointments to delete',
  appointmentSpacesToDelete: 'Appointment spaces to delete',
  deletedAppointmentSpaces: 'Deleted appointments',
  appointmentsMassiveCancellation: 'Appointments Massive Cancellation',
  cancellationReason: 'Cancellation reason',
  listOfGroups: 'Groups list',
  nameESP: 'Name [ESP]',
  nameENG: 'Name [ENG]',
  contactUs: 'Contact Us',
  contactForm: 'Contact Form',
  message: 'Message',
  sendMessage: 'Send Message',
  contactInfornation: 'CONTACT INFORMATION',
  contactinformationRequest: 'Information request',
  contactstationName: 'Phone number',
  infoMail: 'info@turnosmedia.com',
  infoPhone: ' 787-399-0998',
  organizationsMaintenance: 'Organizations Maintenance',
  organizationMaintenance: 'Organization Maintenance',
  organizationFilter: 'Organization filter',
  listOfOrganizations: 'List of Organizations',
  addQueue: 'Add Queue',
  addService: 'Add Service',
  officeName: 'Office Name',
  selection: 'Selection',
  queueSelectionAccordingToOffice: 'Row Selection according to Office',
  serviceSelectionAccordingToOffice: 'Service Selection according to Office',
  configurationId: 'Configuration ID',
  disclaimer: 'Disclaimer',
  companiesMaintenance: 'Companies Maintenance',
  filtersCompanies: 'Companies Filters',
  listOfCompanies: 'List of Companies',
  visitFactor: 'Visit factor',
  workingDaysPerWeek: 'Working days per week',
  workingHoursPerDay: 'working hours per day',
  waitingTimeInHours: 'Waiting time in hours',
  finalPaymentDate: 'Final payment date',
  alertDaysBeforeSuspension: 'Alert days before suspension',
  alertDaysAfterSuspension: 'Alert days after suspension',
  miscellaneous: 'Miscellaneous',
  nameMisc: 'Name misc',
  coordinatorFilter: 'Coordinator filter',
  companyMaintenance: 'Company Maintenance',
  companyInformation: 'Company Information',
  otherMembers: 'Other members',
  otherMembersMaintenance: 'Other Members Maintenance',
  filtersMembers: 'Members Filters',
  listOfMembers: 'List of Members',
  emailIsNotCorrect: 'Email is not correct',
  addMembers: 'Add members',
  errorsList: 'Errors List',
  systemErrors: 'System Errors',
  systemErrorsLegacy: 'Legacy System Errors',
  filterSystemErrors: 'Error Filters',
  errorMessage: 'Error Message',
  errorLocation: 'Error Location',
  errorDate: 'Error Date',
  pageOfOrigin: 'Page of Origin',
  turnoPRCategory: 'TurnosPR Category',
  exceptionSent: 'Exception Sent',
  internalErrorException: 'Internal Exception',
  errorLevel: 'Nivel de Error',
  officeNameIsNotCorrect: 'Office name is not correct',
  averageWaitingTimeGauge: 'Average Wait Time',
  averageServiceTimeGauge: 'Average Attention Time',
  targetAverageTime: 'Target Average Time',
  activate: 'Activate',
  deactivate: 'Deactivate',
  numberOfTurns: 'Number Turns',
  invalidAppointmentNumber: 'Invalid appointment number',
  headerBackgroundText: 'Header background text',
  headerBackgroundSubText: 'Header background sub text',
  headerBackgroundImage: 'Header background image',
  statusUncalledWithStamp: 'Uncalled with Stamp',
  statusAttended: 'Attended',
  searchClient: 'Search client',
  officeInformation: 'Office information',
  officeParameter: 'Office parameter',
  handlerParameters: 'Handler parameters',
  printerParameters: 'Printer parameters',
  kioskParameters: 'Kiosk parameters',
  displayParameters: 'Display parameters',
  surveyModule: 'surveryModule',
  appointmentModule: 'Appointment Module',
  integrations: 'Integrations',
  alerts: 'Alerts',
  extraField1100: 'Extra field',
  address1200: 'Address',
  geographicCoordinates: 'Geographic Coordinates',
  descriptionServicesOffered: 'Description of services offered',
  goLive: 'Go-live',
  officeCategory: 'Office category',
  officeType: 'Office type',
  schedules: 'Schedules',
  waitingTimePerShiftInMinuts: 'Waiting time per shift in minutes',
  automaticRow: 'Fila Automatica',
  mobileAccess: 'Mobile access',
  createPublicAccount: 'Create public account',
  autoCleanListing: 'Auto-clean listing',
  requireTelephone: 'Requore telephone',
  activatePWA: 'Activate PWA',
  askForStation: 'Ask for station',
  activeDeactiveOffice: 'Activate/Deactivate office',
  municipality: 'Municipality',
  alertWithXNumberInAdvance: 'Alert with X number in advance',
  automaticRowType: 'Automatic Row Type',
  alternated: 'Alternated',
  sequential: 'Sequential',
  recordsPerPage: 'Records per page',
  autoCloseUserAccounts: 'Auto close user accounts',
  officeOpeningHours: 'Office opening hours',
  officeClosingHours: 'Office closing hours',
  callNextAutomatically: 'Call next automatically',
  systemAllocatesNextEqually: 'System allocates next equally',
  reassingToRow: 'Reassign to row',
  editCustomerInformationBeingServed: 'Edit customer information being served',
  readOnlyService: 'Read Only Service',
  minimumTimeToWaitMarkNoShow: 'Minimum time (in seconds) to wait Mark no Show',
  endOfServiceTime: 'End of service time',
  systemAssignsNextAutomatically: 'System assigns next automatically',
  recessModule: 'Recess module',
  individualServiceCounter: 'Individual service counter',
  recoordinateAppointment: 'Recoordinate Appointment',
  enablePrinter: 'Enable Printer',
  printCopies: 'Print copies',
  configurePrinter: 'Configure Printer',
  allowAppointmentAtKiosk: 'Allow appointment at Kiosk',
  toleranceQuotedTimeToGetTurns: 'Tolerance quoted time to get shift',
  chineseVersionKiosk: 'Chinese Version Kiosk',
  kioskButtonCode: 'Kiosk button code',
  toleranceQuotedTimeToGetTurn: 'Tolerance quoted time to get turn, in minutes',
  numberOfTurnsToShowWhenTheyAreMissingBeforeYou:
    'Number of turns to show when they are missing before you',
  showNameOnDisplay: 'Show name on display',
  showNumberOnDisplay: 'Show number on display',
  voiceOnDisplay: 'voice on display',
  volumeInVideos: 'Volume in videos',
  activateSurvery: 'Activate survey',
  surveryId: 'Survey ID',
  officeIdInSurverys: 'Office ID in surveys',
  activeAppointments: 'Activate Appointments',
  filterTypeOfServicesByNumberOfLoans:
    'Filter type of services by number of loans',
  companionsAllowedPerShift: 'Companions allowed per shift',
  etiquetteEscorts: 'Etiquette Escorts',
  activateDigitalCollection: 'Activate Digital Collection',
  askForIdWhenCancelingStamp: 'Ask for ID when canceling stamp',
  digitalCollection: 'Digital Collection',
  activateSingleSignOn: 'Activate Single Sign On',
  activateMSTeams: 'Activate Microsoft Teams®',
  urbCondResAptdo: 'Urb/Cond/Res/Aptdo',
  numberStreetApt: 'Number, Street, Apt',
  city: 'City',
  state: 'State',
  country: 'Country',
  postalCode: 'Postal Code',
  postalCodeExt: 'Postal Code Ext',
  physicalAddressEqualToPostal: 'Physical address equal to postal',
  addressPostal: 'Mailing Address',
  physicalAddress: 'Physical address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  getCoordinates: 'Get Coordinates',
  activateSpecialField: 'Activate special field',
  showInTurnHandler: 'Show in turns handler',
  showMobileAppointments: 'Show mobile appointments',
  showWebQuotes: 'Show web quotes',
  showMobileTurns: 'Show mobile shifts',
  specialFieldName: 'Special field name',
  required: 'Required',
  values: 'Values',
  miscellaneousFieldValues: 'Miscellaneous Field Values',
  localAlertsConfiguration: 'Local alerts configuration',
  alert: 'Alert',
  printerSetup: 'Printer setup',
  selectThePrinterMarkedByDefault: 'Select the printer marked by default',
  selectTheSpecificPrinter: 'Select the specific printer',
  searchPrinter: 'Search printer',
  selectedPrinter: 'Selected printer',
  notifications: 'Notifications',
  officeNotifications: 'Office notifications',
  maintenanceOfTypeOfServices: 'Maintenance of Type of Services',
  typeOfServicesFilter: 'Type of Services Filter',
  visibleToThePublic: 'Visible to the public',
  warning: 'Warning',
  requireEmail: 'Require Email:',
  requirePhone: 'Require Phone:',
  requireComment: 'Require Comment',
  hideComment: 'Hide Comment',
  youCanAttachDocument: 'You can Attach Document',
  inactive: 'Inactive',
  listOfTypeOfServices: 'List of Type of Services',
  maintenanceOfTypeOfServicesByOffice:
    'Maintenance of Type of Services by Office',
  typeOfServicesByOfficeFilter: 'Type of Services by Office Filter',
  listOfTypeOfServicesByOffice: 'List of Type of Services by Office',
  history: 'History',
  historyTitle: 'User Account Change History',
  historySubTitle: 'User Account Change Information',
  eventDate: 'Event Date',
  changedField: 'Changed Field',
  valueBeforeChange: 'Value Before Change',
  valueAfterChange: 'Value After Change',
  userMakingChange: 'User Making Change',
  locationSchoolId: 'Location School ID',
  surveyType: 'Survey Type',
  surveyId: 'Survey ID',
  surveyName: 'Survey Name',
  surveyList: 'Survey List',
  surveyIdentifierMaintenance: 'Identifier of Surveys in Office',
  filterSurvey: 'Filter Surveys',
  securityProfileInformation: 'Security Profile information',
  securityProfileMaintenance: 'Security Profile maintenance',
  securityProfilesMaintenance: 'Security Profiles maintenance',
  securityProfileNameIsNotCorrect: 'Security Profile name is not the correct',
  securityProfiles: 'Security Profiles',
  filterSecurityProfile: 'Filter Security Profile',
  securityProfileName: 'Security Profile name',
  listOfSecurityProfiles: 'List of Security Profiles',
  minimalCharacters: 'Minimal Characters',
  validityDayPassword: 'Days to change Password',
  needLowerCaseLetterFlag: 'Lower Case Letters',
  needUpperCaseLetterFlag: 'Upper Case Letters',
  needNumberFlag: 'Number',
  needSpecialCharactersFlag: 'Special Characters',
  failedAttempsAllow: 'Failed Attempts allow Number',
  unlockDatabase: 'Unlock Database',
  dateProcess: 'Date Process',
  lastProcess: 'Last Process',
  totalCellPhones: 'Total CellPhones',
  totalEmails: 'Total Emails',
  totalClientsDashboard: 'Total Clients',
  subcode: 'Sub-code',
  path: 'Path',
  domain: 'Dominio',
  maintenanceOfLogos: 'Maintenance of Logos',
  filterOfLogos: 'Filter of logos',
  listOfLogos: 'List of logos',
  schedulesForFrequencyOfSendingExceptions:
    'Schedules for frequency of sending exceptions',
  schedulesForFrequencyOfSendingException:
    'Schedules for frequency of sending exception',
  exceptionSubmissionSchedule: 'Exception submission schedule',
  listOfSchedules: 'List of schedules',
  category: 'Category',
  findAddress: 'Find address',
  enterLocation: 'Enter a location',
  selectAddress: 'Select address',
  adsMaintenance: 'Ads Maintenance',
  filterAds: 'Filter ads',
  listOfAds: 'List of ads',
  url: 'Url',
  keyword: 'Keyword',
  image: 'Image',
  video: 'Video',
  time: 'Time',
  group: 'Group',
  monitor: 'Monitor',
  volume: 'Volume',
  cloneOffice: 'Clone office',
  maintainQueueRelationshipWithService:
    'Maintain queue relationship with service',
  legacyPath: 'Legacy Path',
  addLogo: 'Add Logo',
  createLogo: 'Create logo',
  editLogo: 'Edit logo',
  domainName: 'Domain Name',
  sponsorName: 'Sponsor Name',
  sponsorMaintenance: 'Sponsor Maintenance',
  sponsorAdMaintenance: 'Sponsor Ad Maintenance',
  loginAdMaintenance: 'Login Ad Maintenance',
  filterSponsors: 'Filter sponsors',
  filterSponsorAds: 'Filter sponsor ads',
  filterLoginAds: 'Filter login ads',
  listOfSponsors: 'List of Sponsors',
  listOfSponsorAds: 'List of Sponsor Ads',
  listOfLoginAds: 'List of Login Ads',
  sponsorNameIsNotCorrect: 'Sponsor name is not correct.',
  createSponsor: 'Create sponsor',
  editSponsor: 'Edit sponsor',
  noShowHeaderLogo: 'No show header logo',
  noShowContentDisclaimer: 'No show content disclaimer',
  downloadTemplate: 'Download Template',
  advertisers: 'Advertisers',
  adCategory: 'Ad Category',
  beginning: 'Comienzo',
  expiration: 'Expiration',
  massCreateSpaces: 'Mass Space Creation',
  validate: 'Validate',
  documentToProcess: 'Document to Process',
  downloadValidatedDocument: 'Download Validated Document',
  rowsWithErrors: 'Rows With Errors',
  validRows: 'Valid Rows',
  download: 'Download',
  createInBatch: 'Create in Batch',
  createdSpaces: 'Created Spaces',
  spacesToCreate: 'Spaces to Create',
  validatedSpaces: 'Validated Spaces',
  totalRecords: 'Total records',
  createdRecords: 'Created records',
  recordsProcessed: 'Records processed',
  processesList: 'Processes List',
  watchRunningProcessStatus: 'Watch process status',
  openCloseLocationNotConfigured:
    'This location does not have the opening and closing time properly configured.',
  turnNumber: 'Turn number',
  areYouSureYouWantToFinalizeTheTurn:
    'Are you sure you want to finalize the turn?',
  toFinalizeTheTurnWriteTheWord: 'To finalize the turn write the word',
  toCreateSpaceOfficeMustHaveTimes:
    'In order to create space, the office must have opening and closing times',
  code: 'Code',
  assignClients: 'Assign clients',
  listOfClients: 'List of clients',
  minimumCharacters: 'Minimum characters',
  lowLetter: 'Lowercase letters',
  upLetter: 'Capital letters',
  numberNeeded: 'Numbers needed',
  specialCharacter: 'Special characters',
  comments: 'Comments',
  commentsHistory: 'Comments History',
  hasOffice: 'Has office',
  isAttender: 'Can be visited',
  visibleInVisitTo: "Visible in 'Visit to'",
  hiddenInReports: 'Not visible in Reports',
  lastUpdate: 'Last update',
  allowCoordinateOtherService: 'Coordinate appointments for multiple services',
  confirmCoordinateAnotherService:
    'Are you want to coordinate another service?',
  startMessage: 'Start message',
  mainScreen: 'Main screen',
  cancelButtonBackgroundColor: 'Cancel button background color',
  cancelButtonTextColor: 'Cancel button text color',
  commentScreen: 'Comment screen',
  requestComment: 'Request comment',
  screenHeader: 'Screen header',
  dateTime: 'Date/Time',
  locationName: 'Location',
  transactionList: 'Transaction List',
  notesResult: 'Notes Result',
  transTracking: 'Transactions Tracking',
  keyboardSize: 'Keyboard size',
  zoom: 'Zoom',
  maxCharacters: 'Maximun characters',
  hideInPWA: 'Hide in PWA',
  displayName: 'Display Name',
  inactivate: 'Inactivate',
  duration: 'Duration',
  createLoginAd: 'Create Login Ad',
  createSponsorAd: 'Create Sponsor Ad',
  editLoginAd: 'Edit Login Ad',
  editSponsorAd: 'Edit Sponsor Ad',
  adFile: 'Ad File',
  viewAd: 'View Ad',
  backToStartCounter: 'Back to start counter (millisecond)',
  listOfOfficeMonitors: 'List of Office Monitors',
  officeMonitorsMaintenance: 'Sales - Office Monitors',
  officeMonitorsFilters: 'Office Monitors Filter',
  salesAvailableSpaces: 'Sales - Spaces Available',
  filterSpaces: 'Filter Available Spaces',
  listOfAvailableSpaces: 'Listings of Available Spaces',
  town: 'Town',
  secondsAvailable: 'Seconds available',
  secondsAvailablePaying: 'Seconds available paying',
  availableSpacesPaying: 'Available spaces paying',
  officeWithAvailableSpaces: 'Office With Available Spaces',
  officesWithAvailableSpacesPaying: 'Offices With Available Spaces Paying',
  editAd: 'Edit Client Ad',
  createAd: 'Create Client Ad',
  nextDayAttentionNotification: 'Next Day Attention Notification',
  appointmentUrl: 'Appointment Url',
  virtualUrl: 'Virtual Url',
  hasPresential: 'Has Presential',
  capsLockOnKeyboard: 'Caps Lock on keyboard',
  showZoomOnKeyboard: 'Show zoom on keyboard',
  turn: 'Turn',
  sender: 'Sender',
  turnModule: 'Turn Module',
  notificationManagement: 'Notification Management',
  senderAppointmentCarrierDuplicityError:
    'For the appointment module, the phone carrier already exists in another sender.',
  senderTurnCarrierDuplicityError:
    'For the turn module the phone carrier already exists in another sender.',
  activatePreferentialTurnOption: 'Activate preferential turn option',
  priorityWhenCallingTurn: 'Priority when calling turn',
  turnPriority: 'Turn priority',
  notificationMaintenance: 'Notification Maintenance',
  notificationsMaintenance: 'Notifications',
  listOfNotifications: 'List of Notifications',
  filterNotifications: 'Filter Notifications',
  createNotification: 'Create Notification',
  editNotification: 'Edit Notification',
  subjectSpanish: 'Subject in Spanish',
  subjectEnglish: 'Subject in English',
  textSpanish: 'Text in Spanish',
  textEnglish: 'Text in English',
  shortTextSpanish: 'Short Text in Spanish',
  shortTextEnglish: 'Short Text in English',
  default: 'Default',
  searchCriteria: 'Search Criteria',
  verifyInformation: 'Verify information registered in the form.',
  notificationNameIsNotCorrect: 'Notification name is not correct.',
  linkOffice: 'Link Office',
  link: 'Link',
  maintenanceApplicationPortals: 'Maintenance of Application Portals',
  informationApplicationPortals: 'Información of Application Portals',
  filterRequestPortals: 'Filter Request Portals',
  listOfApplicationPortals: 'List of Application Portals',
  contentPageBackgroundColor: 'Content page background color',
  contentTitleFontWeight: 'Content title font weight',
  contentTitleFontColor: 'Content title font color',
  contentLabelFontWeight: 'Content label font weight',
  contentLabelFontColor: 'Content label font color',
  fontWeight: 'Font Weight',
  color: 'Color',
  size: 'Size',
  elements: 'Elements',
  continueButton: 'Continue button',
  backButton: 'Back button',
  labelTextColor: 'Label text color',
  labelTextSize: 'Label text size',
  newCompany: 'New company',
  contentLabelFontSize: 'Content label font size',
  continueButtonBackgroundColor: 'Continue button background color',
  buttonContinueFontColor: 'Button continue font color',
  buttonContinueFontSize: 'Button continue font size',
  buttonBackBackgroundColor: 'Button back background color',
  buttonBackFontColor: 'Button back font color',
  buttonBackFontSize: 'Button back font size',
  hyperLinkColor: 'Hyper link color',
  companyName: 'Company name',
  defaultCountryCode: 'Default country code',
  agencies: 'Agencies',
  displayAppointmentCoordinationDate:
    'Show appointment coordination date when attending turn',
  coordinatedDate: 'Coordinated date',
  maintenanceLayoutDesignConfigurations: 'Maintenance of Layout Configurations',
  filterLayoutDesignConfigurations: 'Filter Layout Configurations',
  layoutDesignConfigurationList: 'Layout Configurations',
  layoutDesignConfigurationInformation: 'Layout Configuration Information',
  listOfLayoutDesignConfigurations: 'List of Layout Configurations',
  defaultConfiguration: 'Default Configuration',
  agencyInformation: 'Agency Information',
  agencyCode: 'Agency Code',
  agencyName: 'Agency Name',
  agencyInUse: 'Agency in use by Design Configuration',
  agencyRepeated: 'Agency Repeated',
  preferentialTurn: 'Preferential turn',
  notPresentNotification: 'Not Present Notification',
  priorityWhenCallingTurnByRow: 'Priority when calling turn (Row)',
  priorityWhenCallingTurnByService: 'Priority when calling turn (Service)',
  createLayoutConfiguration: 'Create layout configuration',
  editLayoutConfiguration: 'Edit layout configuration',
  addNonBusinessDays: 'Add non-business days',
  removeNonBusinessDays: 'Remove non-business days',
  selectDate: 'Select date',
  availableLocations: 'Available locations',
  selectedLocations: 'Selected locations',
  citizenInformation: 'Citizen Information',
  specialistInformation: 'Specialist Information',
  dateAndTime: 'Date and Time',
  haveToleranceBeforeAppointmentHour: 'Have tolerance before appointment hour',
  toleranceBeforeAppointmentHour: 'Tolerance before appointment hour (minutes)',
  specialistWithSpacesCreated:
    'Following specialists already have spaces with selected configuration',
  doNotAllowCoordinatingInternalAppointments:
    'Do not allow coordinating internal appointments',
  showInformativeMessage: 'Show informative message',
  informativeMessage: 'Informative message',
  informativeMessageColor: 'Informative message color',
  youCannotCoordinateAppointmentsForThisLocation:
    'You cannot coordinate appointments for this location',
  priorityOrder: 'Priority order',
  showConfirmationButton: 'Show confirmation button',
  confirmationText: 'Confirmation text',
  informationText: 'Information text',
  confirmationTextColor: 'Confirmation text color',
  doNotAllowCoordinatingInternalAppointmentsToday:
    'Do not allow coordinating internal appointments today',
  breakComment: 'Break Comment',
  showSimpleKeyboard: 'Show simple keyboard',
  keyboardBackgroundColor: 'Keyboard background color',
  keyboardTextColor: 'Keyboard text color',
  datePickerBackgroundColor: 'Date picker background color',
  datePickerTextColor: 'Date picker text color',
  customNotifications: 'Custom notifications',
  showTurnTrackingButton: 'Show turn tracking button',
  searchTurnPrimaryText: 'Search turnbutton main text ESP',
  searchTurnPrimaryTextEN: 'Search turn button main text ENG',
  searchTurnSecondaryText: 'Search turn button secondary text ESP',
  searchTurnSecondaryTextEN: 'Search turn button secondary text ENG',
  serviceQueueNotifications: 'Service queue notifications',
  serviceRequiredOnTransfer: 'Service required in transfer',
  receiptNumber: 'Receipt Number',
  totalNumberActiveStampsIsNotSufficient:
    'The total number of active stamps does not cover the requested transaction in the appointment. The citizen must be required to provide all the stamps for this transaction ({0}: total amount of ${1})',
  contactDigitalCollectionAssistance:
    'If you have any questions about the collection of Stamps and Receipts, you can contact Colecturía Digital at 787-291-9292 or by email at Servicioalcliente@colecturiadigital.com',
  wantToCancelTheStamp: 'Do you want to proceed and cancel the stamp?',
  documentId: 'Document ID',
  belongsToGovernment: 'Belongs to government',
  surveyQuestion: 'Questions',
  surveyAnswer: 'Answers',
  noSurveyData: 'Does not contain survey',
  sureToConfirmAppointment:
    'Are you sure you want to confirm this appointment?',
  preventCoordinateAppointment: 'Prevent coordinate appointment',
  maxDayCoordinateAppointment: 'Maximum day to coordinate appointment',
  videoCallNotificationOnCreateTurn: 'Video call notification on turn creation',
  statusInformation: 'Status information',
  statusDate: 'Status date',
  statusCreatedBy: 'Status created by',
  statusCreatedForReason: 'Status created for reason',
  withoutReason: 'Without reason',
  youCanFollowTurnMobile: 'You can follow the turn in app mobile',
  showInMobile: 'Show in app mobile',
  hideInApp: 'Hide in Apps',
  disableEmailRequiredForAppointmentCoordination:
    'Not requiring email when scheduling an appointment',
  metroRegion: 'Metro Region',
  northRegion: 'North Region',
  southRegion: 'South Region',
  eastRegion: 'East Region',
  westRegion: 'West Region',
  islandLevel: 'Island Level',
  regionalDashboard: 'Dashboard by Regions',
  monitoring: 'Monitoring',
  singlesignon: 'Single Sign On',
  ssoAppsRegistration: 'SSO Apps registration',
  listOfAuthClients: 'List of Authorization Clients',
  maintenanceOfAuthClients: 'Maintenance of Authorization Clients',
  maintenanceOfSSOClients: 'Maintenance of SSO Apps',
  regionalCFSERealTime: 'CFSE Regional Dashboard REal Time',
  totalCustomersInjured: 'Total customers injured',
  totalAttendedVideocall: 'Total attended video call',
  totalClientEmployers: 'Total client employers',
  averageVideocallServiceTime: 'Average video call service time',
  resendUnansweredSurveys: 'Resend unanswered surveys',
  changeToLegacyMode: 'Change to legacy mode',
  removeLegacyMode: 'Remove legacy mode',
  stepperSection: 'Stepper section',
  completedColor: 'Completed color',
  loadingColor: 'Loading color',
  activeButtonBackgroundColor: 'Active button background color',
  activeButtonFontColor: 'Active button font color',
  activeButtonBorderColor: 'Active button border color',
  fieldTextColor: 'Field text color',
  fieldValueColor: 'Field value color',
  informationAreaBorderColor: 'Information area border color',
  informationAreaBackgroundColor: 'Information area background color',
  activeInformationAreaBorderColor: 'Active information area border color',
  activeInformationAreaBackgroundColor:
    'Active information area background color',
  documentUploadButtonColor: 'Document upload button color',
  documentUploadButtonTextColor: 'Document upload button text color',
  activeDocumentUploadBackgroundColor:
    'Active document upload background color',
  hoverColor: 'Hover color',
  dotColor: 'Dot color',
  showLocationTimeInformation: 'Show location time information',
  showTimeInformation: 'Show time information',
  showServiceAverageTime: 'Show service average time',
  showLocationTurns: 'Show location turns',
  showLocationAddress: 'Show location address',
  showMileage: 'Show mileage',
  showHowToArrive: 'Show how to arrive',
  hasCustomText: 'Has custom text',
  allowMultipleTurnsPerService: 'Allow more than one turn by service per user',
  hideInactiveUsers: 'Hide inactive users',
  hideBlockedUsers: 'Hide blocked users',
  allowCurrentDayCoordination:
    'Allow to coordinate appointments on the current day',
  hideInactiveUsersFilter: 'Hide Inactive / Locked User',
  hideBlockedUsersInVisitTo: "Hide blocked users in 'Visit to'",
  isPremium: 'Is premium client',
  premiumSiteUrl: 'Premium site URL',
  autoSelectSpecialist: 'Auto Select Specialist',
  thereAreNoDocuments: 'There are no documents',
  documents: 'Documents',
  addDocument: 'Add document',
  isRequired: 'Is required',
  autoDelete: 'Auto delete',
  showAppointmentsPlusToPublic: 'Show appointments plus to the public',
  plusClientDisablePasteInConfirmationEmail:
    'Disable Copy / Paste in Email confirmation',
  displayClientNameOnRibbon: 'Display client name in alert',
  displayTurnNumberOnRibbon: 'Display turn number in alert',
  rescheduleAppointment: 'Reschedule appointment',
  hideAttachDocument: 'Hide Attach Document',
  turnNumberTextSize: 'Turn number text size',
  stationTextSize: 'Station text size',
  nameTextSize: 'Name text size',
  excludeFromHomeDashboard: 'Exclude for home dashboard',
  attachDocumentsToActiveTurn: 'Attach documents to active turn',
  pressDragToUploadDocuments: 'Press or drag to upload documents',
  uploadedDocuments: 'Uploaded documents',
  loading: 'Loading',
  systemError: 'System error, please try again later',
  cannotAttachDocumets: 'Cannot attach documents',
  documentsHaveAlreadySentAgent:
    'The documents have already been sent to the care representative',
  documentsUploadedSuccessfully: 'The documents were uploaded successfully',
  invalidDocumentFormat: 'Invalid document format',
  sendDocumentForValidation: 'Send document for validation',
  documentsSentSuccessfully: 'Documents sent successfully',
  showWaitTime: 'Show wait time',
  hourFromMinimumCapacity: 'Hour from minimum capacity',
  hourToMinimumCapacity: 'Hour to minimum capacity',
  increasedWaitingTime: 'Increased waiting time (%)',
  messageWaitingTime: 'Message waiting time',
  requestDocuments: 'Request document(s)',
  documentsRequested: 'Documents requested',
  canUploadDocuments: 'Can upload documents',
  enableLanguageBehavior: 'Enable language management',
  enableTurnManualCompletion: 'End turn manually in visit mode',
  rowTextSize: 'Row text size',
  closeTurn: 'Close turn',
  canCloseTurn: 'Can close the turn?',
  backgroundMessageTextSize: 'Background message text size',
  timeInformationTextSize: 'Time information text size',
  enableVisitMode: 'Enable visit mode',
  displayHistoricData: 'Only historic data',
  ticketMaintenance: 'Custom Ticket Maintenance',
  filterTicket: 'Filter Ticket',
  listTicket: 'List of Ticket',
  ticketName: 'Ticket Name',
  languageScreen: 'Language screen',
  requestLanguage: 'Request language',
  languageRequired: 'Language required',
  languages: 'Languages',
  showCategory: 'Show category',
  categoryName: 'Category name',
  categoryFieldValues: 'Field values',
  showOfficeName: 'Show office name',
  showQueueName: 'Show queue name',
  showRegistrationDate: 'Show registration date',
  showURL: 'Show URL',
  showUserName: 'Show username',
  showVisitTo: 'Show visit to',
  showLogo: 'Show logo',
  miscellaneous1: 'Miscellaneous #1',
  miscellaneous2: 'Miscellaneous #2',
  miscellaneous3: 'Miscellaneous #3',
  miscellaneous4: 'Miscellaneous #4',
  miscellaneous5: 'Miscellaneous #5',
  miscellaneous6: 'Miscellaneous #6',
  miscellaneous7: 'Miscellaneous #7',
  showLanguageScreen: 'Show language screen',
  showCommentScreen: 'Show comment screen',
  useR8Appointment: 'Send to R8 appointment',
  appointmentFields: 'Appointment Fields',
  activateChat: 'Activate chat',
  activateInternalChat: 'Activate chat account',
  activateEntrance: "Activate 'Turnos Entrance'",
  youWantToActivateInternalChat:
    'You want to activate chat account for this user?',
  categories: 'Categories',
  activeInternalChat: 'Active internal chat',
  virtualPhoneExtension: 'Virtual phone extension',
  cantRemoveLanguage: "Can't remove language",
  cantRemoveLanguageUntilEndThesTurns:
    'You cannot remove the language until the end of these turns',
  youWantToRemove: 'You want to remove',
  languageWillRemovedFollowingTechnicians:
    'This language will be removed from the following technicians',
  categoryWillRemovedFollowingTechnicians:
    'This category will be removed from the following technicians',
  broadcast: 'Broadcast',
  broadcastSettings: 'Broadcast settings',
  dayTurns: 'Day turns',
  unattendedTurns: 'Unattended turns',
  sendEmail: 'Send email',
  sendSMS: 'Send SMS',
  sendPushNotification: 'Send push notification',
  scanLicenseMessage: 'Please, scan the license.',
  scanLicenseLoadingMessage: 'Scanning license...',
  messageSentSuccessfully: 'Message sent successfully',
  syncChatUsers: 'Sync Chat Users',
  typing: 'Typing',
  send: 'Send',
  communications: 'Communications',
  controlsConversation: 'Controls Conversation',
  participantStatus: 'Participant Status',
  hold: 'Hold',
  request: 'Request',
  transfer: 'Transfer',
  drop: 'Drop',
  giveControl: 'Give control',
  passive: 'Passive',
  citizen: 'Citizen',
  connection: 'Connection',
  supervisor: 'Supervisor',
  attentionlanguage: 'Attention language',
  representativeCompanionTypeRequired: 'Is representative companion required?',
  notInActiveWaitingRoom: 'You are not in any active session',
  startWaitingRoomPrompt:
    'To start a session, you must configure the company and press the PLUS button',
  responsesAndTurnData: 'Responses and Turn Data',
  startOfConversation: 'Start of conversation',
  userAddedBy: 'User {0} added by {1}.',
  userRemovedBy: 'User {0} removed by {1}.',
  userAdded: 'User {0} added.',
  userRemoved: 'User {0} removed.',
  here: 'Here',
  notifyAllInRoom: 'Notify all in this room',
  turnWaitingRoom: 'Turn Waiting Room',
  waitingRequestedForSession: 'Waiting to be requested for a session...',
  youSelectedToParticipateSession:
    'You were selected to participate in a session',
  passiveUserNotFound: 'No companion found',
  supervisorUserNotFound: 'No supervisor found',
  monitorUserNotFound: 'No monitor found',
  companionFound: 'Companion found, looking for citizen...',
  supervisorUserFound: 'Supervisor entered the waiting room',
  monitorUserFound: 'Monitor entered the waiting room',
  activeSessionHasEnded: 'The active session has ended',
  passiveMode: 'Passive mode',
  studentId: 'Student id',
  useChat: 'Use chat',
  useCall: 'Use call',
  allowchatrecording: 'You agree to this conversation being saved?',
  recordingDisclaimer: 'Recording Disclaimer',
  externalChat: 'External Chat',
  internalChat: 'Internal Chat',
  turnInformationWasUpdated: 'Turn information was updated',
  errorLogginInToChat: 'Error logging in to chat',
  unhold: 'Unhold',
  endPassiveSearch: 'End passive search',
  citizenEnteredRoom: 'The citizen has entered the room',
  citizenLeftRoom: 'The citizen has left the room',
  citizenEndedSesion: 'El ciudadano ha finalizado la sesión',
  citizenPhoneRinging: 'The citizen phone is ringing...',
  citizenPhoneIsBusy: 'The citizen phone is busy',
  citizenCallConnected: 'The citizen has interacted with the call',
  reJoinCall: 'Rejoin call',
  sessionConcluded: 'Session concluded',
  externalChatSessionCompleteMessage:
    'Thank you for choosing us! We are glad to have been able to assist you. Now you can close your browser.',
  enableCommunicationModule: 'Enable communications module',
  maxRecall: 'Max Recalls',
  dropOut: 'Drop Out',
  fileSizeLimitMessage: 'File size should not exceed 10 MB.',
  finishChat: 'Finish Chat',
  finishCall: 'Finish Call',
  monitorHasEnteredTheRoom: 'Monitor has entered the room',
  monitorHasLeftTheRoom: 'Monitor has left the room',
  supervisorHasEnteredTheRoom: 'Supervisor has entered the room',
  supervisorHasLeftTheRoom: 'Supervisor has left the room',
  getInto: 'Get into',
  listOfActiveSessions: 'List of active sessions',
  monitoringActiveSessions: 'Monitoring active sessions',
  minutes: 'Minutes',
  turnMinutes: 'Turn minutes',
  created: 'Created',
  waitingPassive: 'Waiting passive',
  waitingCitizen: 'Waiting citizen',
  inProgress: 'In progress',
  language: 'Language',
  participants: 'Participants',
  refresh: 'Refresh',
  leaveTheRoom: 'Leave the room',
  join: 'Unirse',
  requestToJoinWaitingRoom: 'Request to join waiting room',
  YouHaveReceivedRequestToJoinWaitingRoom:
    'You have received a request to join a waiting room like {type}',
  decline: 'Decline',
  signOut: 'Sign out',
  onLogout: 'on logout',
  roomStatus: 'Room status',
  supervising: 'Supervising',
  answer: 'Take',
  reject: 'Reject',
  incomingCall: 'Incoming call',
  mute: 'mute',
  unmute: 'Unmute',
  finish: 'Finish',
  endTurnSearch: 'End turn search',
  takeControl: 'Take control',
  takeControlWaitingRoom: 'Do you want to take control of the waiting room?',
  grantControlToSupervisor: 'Waiting room control granted to supervisor',
  rejectControlRequestForSupervisor: 'Control request rejected for supervisor',
  relations: 'Relations',
  markedNotPresentBy: 'Marked not present by {0}',
  confirmationButtonSize: 'Confirmation button size',
  medium: 'Medium',
  big: 'Big',
  plusClientShowAttendeesNumber: 'Show attendees number in appointment',
  writeYourMessage: 'Write your message',
  supervisorWillAssistingYou: 'A supervisor will be assisting you',
  notes: 'Notes',
  languageToAttend: 'Language to attend',
  es: 'Spanish',
  en: 'English',
  UserCategory: 'User Category',
  lookingForAnotherCompanion: 'Looking for another companion.',
  lookForSpecialist: 'Look for specialist',
  lookingForSpecialist: 'Looking for specialist',
  specialistNotFound: 'Specialist not found',
  specialistFoundTurnTransferred:
    'Specialist found, the turn has been transferred',
  requestToAttendTurn: 'Request to attend a turn',
  youhaveRecievedRequestAsSpecialist:
    'You have received a request to attend a turn as a specialist',
  specialty: 'Specialty',
  source: 'Source',
  minimumDaysToCoordinateAppointments:
    'Minimum days to coordinate appointments',
  accessCodeMessage: 'Please enter the access code',
  accessCodeInfo:
    '* The access code is either the appointment number or your identification number',
  accessCode: 'Access Code',
  preferenceWillRemovedFollowingLocation:
    'The preference will be removed from the following location',
  assignAppointmentOffice: 'Assign appointment office',
  citizenResponseTimeout: 'Citizen did not enter the room',
  areYouSureYouWantToEndSession: 'Are you sure you want to end the session?',
  endSession: 'End session',
  isWriting: '{names} is writing',
  sessionList: 'Sessions list.',
  sessionFilters: 'session filters',
  chat: 'Chat',
  unfinished: 'Unfinished',
  assess: 'Assess',
  holdTime: 'Hold time',
  ring: 'Call',
  spaceCreationProcess: 'Space creation process {0}: {1}% completed',
  endingTime: 'Ending time',
  reCallTo: 'Call again to ',
  evaluation: 'Evaluation',
  refferEvaluationTo: 'Reffer this evaluation to',
  needSecondEvaluation: 'Need second evaluation',
  activateChatUse: 'Activate chat use',
  useMessageNotFound: 'Show message on spaces searching',
  recording: 'Recording',
  recordingNotLoaded: 'This call has not been recorded',
  recordingDetail: 'Recording detail',
  chatDetail: 'chat detail',
  thereAreAttachedDocuments: 'There are attached documents',
  turnWithDocuments: 'Turn with documents',
  contactEmail: 'Contact email',
  contactPhone: 'Contact phone',
  individualCall: 'Individual call',
  loanNumber: 'Loan number',
  automaticTransfer: 'Automatic transfer',
  assingLocations: 'Assing locations',
  assignAnnouncementLocations: 'Assign announcement to locations',
  selectCompany: 'Select a company',
  youHaveNotSavedYetConfigurationCurrentlyFromFirstScreen:
    'You have not saved yet, the configuration you currently see is from the first screen',
  activateScanner: 'Activate scanner',
  'validation.allowedCharacters': 'The field contains invalid characters.',
  placeholderMask: 'Field mask',
  loadClientInfo: 'Load Customer Information',
  clientInfoTemplate: 'Customer information file (CSV)',
  representative: 'Representative',
  agent: 'Agent',
  communicationCompletedByCitizen: 'Communication completed by citizen',
  communicationCompletionDate: 'Communication completion date',
  communicationCompletionHour: 'Communication completion hour'
};
