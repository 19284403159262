import { apiTurnos } from '@/core/api/';

export const putCallOnHold = (waitingRoomId) =>  apiTurnos.put(`/api/communication/${waitingRoomId}/call/hold`);

export const getCallStatus = (waitingRoomId) => apiTurnos.get(`/api/communication/${waitingRoomId}/call/status`);

export const getVirtualPhoneInfo = () => apiTurnos.get(`/api/communication/virtual/phone/info`);

export const muteOrUnmuteCall = (waitingRoomId, payload) =>
    apiTurnos.post(`/api/communication/${waitingRoomId}/call/mute`, payload);

export const checkIfUserHasActiveCall = (autojoin) =>
    apiTurnos.post('/api/communication/call/check', { autoJoin: autojoin });

export const getChatPresavedMessages = (locationConfigurationId) =>
    apiTurnos.get('/api/communication/chat/presaved/messages', {params: {locationConfigurationId}});
