import { UserManager, WebStorageStateStore } from 'oidc-client';
import { AUTH } from '@/config';
import sessionState from '@/mixins/sessionState';
import localState from '@/mixins/localState';

const globalQValues = Object.fromEntries(new URLSearchParams(window.location.search).entries());

let getAcrValues = () => {
  let result = AUTH.ACR_VALUES;

  if (globalQValues.cp) 
    result += ` cp:${globalQValues.cp}`;

  if (globalQValues.s)
    result += ` source:${globalQValues.s}`;

  if (globalQValues.a)
    result += ` action:${globalQValues.a}`;

  if (globalQValues.cd)
    result += ` codeid:${globalQValues.cd}`;

  return result;
};

const extraQueryParams = {};

if(globalQValues.lang)
  extraQueryParams.lang = globalQValues.lang || 'es';

const userManager = new UserManager({
  authority: AUTH.AUTHORITY,
  automaticSilentRenew: true,
  client_id: AUTH.CLIENT_ID,
  filterProtocolClaims: true,
  post_logout_redirect_uri: AUTH.POST_LOGOUT_REDIRECT_URI,
  redirect_uri: AUTH.REDIRECT_URI,
  response_type: AUTH.RESPONSE_TYPE,
  scope: AUTH.SCOPE,
  silent_redirect_uri: AUTH.SILENT_REDIRECT_URI,
  acr_values: getAcrValues(),
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  extraQueryParams: extraQueryParams
});

export const getUser = async () => {
  const user = await userManager.getUser();

  // The login needs to be performed if there isn't a user for this site
  // NOTE: The login is not performed when traveling from here to another site
  // this line forces the login but once is done the coockie is checked,
  // so, the login is only done once and this site and the Kiosk can be used
  // simultaneously.
  if (user.profile.kiosk !== undefined || user.profile.display !== undefined) {
    _logOutWithRedirect();

    return null;
  }

  return user;
};

export const login = () => {
  return userManager.signinRedirect();
};

export const logout = () => {
  sessionState.methods.clearSessionDataForLogout();
  localState.methods.clearLocalDataForLogout();

  return userManager.removeUser();
};

export const logoutWithRedirect = () => {
  userManager.getUser().then(() => {
    const userProfile = JSON.parse(sessionStorage.getItem('user-profile'));

    sessionState.methods.clearSessionDataForLogout();
    localState.methods.clearLocalDataForLogout();
    
    _logOutWithRedirect(userProfile);
  });
};

function _logOutWithRedirect(userProfile) {
  userManager.getUser().then((currentUser) => {
    if(currentUser && currentUser.id_token) {
      let signoutPayload = { 'id_token_hint': currentUser.id_token, extraQueryParams: { } };

      if(userProfile?.adClientId)
        signoutPayload.extraQueryParams.cp = Object.keys(userProfile.companies).pop();

      userManager.signoutRedirect(signoutPayload);
    }
    else {
      let logoutUrl = `${
        AUTH.AUTHORITY
      }/account/logout?redirectUrl=${encodeURIComponent(
        AUTH.POST_LOGOUT_REDIRECT_URI,
      )}`;

      window.location.replace(logoutUrl);
    }
  });
}

export const startup = async () => {
  userManager.events.addAccessTokenExpired(_logOutWithRedirect);

  // load user from storage
  return await userManager
    .getUser()
    .then(function(user) {
      if (user == null) {
        // Clean the storage data if the user was logged out
        // by the oidc library
        sessionState.methods.clearSessionDataForLogout();
        localState.methods.clearLocalDataForLogout();

        userManager.signinRedirect();
        return false;
      }

      return true;
    })
    .catch(function() {
      return false;
    });
};
