export default [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "app-r" */ '@/views/Home'),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/appointments/CreateAppointment',
    name: 'CreateAppointment',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/Create'
      ),
    props: true,
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.create'],
      },
    },
  },
  {
    path: '/appointments/:appointmentId/CancelAppointment',
    name: 'CancelAppointment',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/Cancel'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/appointments/:appointmentId/CompleteAppointment',
    name: 'CompleteAppointment',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/Complete'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/appointments',
    name: 'RegisteredAppoinments',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.registered'],
      },
    },
  },
  {
    path: '/kiosks',
    name: 'kiosksList',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/kiosks/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.kiosks'],
      },
    },
  },
  {
    path: '/kiosks/create',
    name: 'kioskCreate',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/kiosks/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.kiosks'],
      },
    },
  },
  {
    path: '/kiosks/:id/edit',
    name: 'kioskEdit',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/kiosks/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.kiosks'],
      },
    },
  },
  {
    path: '/kiosks/:id/clone',
    name: 'kioskClone',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/kiosks/Clone'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.kiosks'],
      },
    },
  },
  {
    path: '/AdminOffice',
    name: 'AdminOffice',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/AdminOffice'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['Client', 'ClientList'],
    },
  },
  {
    path: '/AdminTurnos',
    name: 'AdminTurnos',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/AdminTurnos'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['ClientUser'],
    },
  },
  {
    path: '/Display',
    name: 'Display',
    component: () => import(/* webpackChunkName: "app-r" */ '@/views/Display'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['DisplayUser'],
    },
  },
  {
    path: '/AutoRegister',
    name: 'AutoRegister',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/AutoRegister'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['ClientAutoLogin'],
    },
  },
  {
    path: '/WebUser',
    name: 'WebUser',
    component: () => import(/* webpackChunkName: "app-r" */ '@/views/WebUser'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['WebUser'],
    },
  },
  {
    path: '/spaces',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/CreateSpaces'),
    children: [
      {
        name: 'CreateSpace',
        path: 'create',
        component: () =>
          import(/* webpackChunkName: "app-r" */ '@/views/spaces/Create'),
      },
      {
        name: 'VerifySpace',
        path: 'verify',
        component: () =>
          import(/* webpackChunkName: "app-r" */ '@/views/spaces/Verification'),
      },
    ],
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['Client', 'ClientList'],
    },
  },
  {
    path: '/availablespaces',
    name: 'availablespaces',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/spaces/Available'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.availablespaces'],
      },
    },
  },
  {
    path: '/appointments/spaces/delete',
    name: 'deletespaces',
    component: () =>
      import(/* webpackChunkName: "app-appointment" */ '@/views/spaces/Delete'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.spaces.delete'],
      },
    },
  },
  {
    path: '/appointments/masscancel',
    name: 'appointmentsmasscancellation',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/MassCancel'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.masscancel'],
      },
    },
  },
  {
    path: '/appointments/direct',
    name: 'directAppointmentsSearch',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/SearchDirect'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.direct'],
      },
    },
  },
  {
    path: '/appointments/direct/create',
    name: 'directAppointmentsCreate',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointments/CreateDirect'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.appointment.direct.create'],
      },
    },
  },
  {
    path: '/ViewTurns',
    name: 'ViewTurns',
    component: () =>
      import(/* webpackChunkName: "app-turns" */ '@/views/ViewTurns'),
    meta: {
      authorization: {
        authenticated: true,
      },
      role: ['Client', 'ClientList'],
    },
  },
  {
    path: '/reports/attentionperformancetest',
    name: 'attentionperformancereport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AttentionPerformanceTest'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/surveytest',
    name: 'surveyreport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyTest'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/realtimeregional',
    name: 'regionalrealtime',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/RegionalRealTime'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/surveybyoperatortest',
    name: 'surveybyoperatorreport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyByOperatorTest'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/overwaiting',
    name: 'overwaitingreport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/overwaiting/Search'
      ),
    meta: {
      authorization: {
        acceptedPermissions: ['menu.reports.overwaiting'],
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/mainmetricstest',
    name: 'mainmetricsreport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/MainMetricsTest'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import(/* webpackChunkName: "app-r" */ '@/views/NotFound'),
  },
  {
    path: '/401',
    name: '401',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/Unauthorized'),
  },
  {
    path: '/403',
    name: '403',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/Forbidden'),
  },
  {
    path: '/pwa',
    name: 'PWAsList',
    component: () =>
      import(/* webpackChunkName: "app-pwa" */ '@/views/pwa/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.pwa'],
      },
    },
  },
  {
    path: '/pwa/create',
    name: 'PWACreate',
    component: () =>
      import(/* webpackChunkName: "app-pwa" */ '@/views/pwa/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.pwa'],
      },
    },
  },
  {
    path: '/pwa/:id/edit',
    name: 'PWAEdit',
    component: () =>
      import(/* webpackChunkName: "app-pwa" */ '@/views/pwa/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.pwa'],
      },
    },
  },
  {
    path: '/pwa/:id/clone',
    name: 'PWAClone',
    component: () =>
      import(/* webpackChunkName: "app-pwa" */ '@/views/pwa/Clone'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.pwa'],
      },
    },
  },
  {
    path: '/users',
    name: 'usersList',
    component: () =>
      import(/* webpackChunkName: "app-users" */ '@/views/users/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.users'],
      },
    },
  },
  {
    path: '/users/create',
    name: 'userCreate',
    component: () =>
      import(/* webpackChunkName: "app-users" */ '@/views/users/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.users'],
      },
    },
  },
  {
    path: '/users/:id/edit',
    name: 'userEdit',
    component: () =>
      import(/* webpackChunkName: "app-users" */ '@/views/users/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.users'],
      },
    },
  },
  {
    path: '/users/:id/edit-permissions',
    name: 'userEditPermissions',
    component: () =>
      import(/* webpackChunkName: "app-users" */ '@/views/users/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.users'],
      },
    },
  },
  {
    path: '/users/:id/change-role',
    name: 'userChangeRole',
    component: () =>
      import(/* webpackChunkName: "app-users" */ '@/views/users/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.users'],
      },
    },
  },
  {
    path: '/monitors',
    name: 'displaysList',
    component: () =>
      import(/* webpackChunkName: "app-displays" */ '@/views/displays/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.monitors'],
      },
    },
  },
  {
    path: '/displays/create',
    name: 'displayCreate',
    component: () =>
      import(/* webpackChunkName: "app-displays" */ '@/views/displays/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.monitors'],
      },
    },
  },
  {
    path: '/displays/:id/edit',
    name: 'displayEdit',
    component: () =>
      import(/* webpackChunkName: "app-displays" */ '@/views/displays/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.monitors'],
      },
    },
  },
  {
    path: '/displays/:id/clone',
    name: 'displayClone',
    component: () =>
      import(/* webpackChunkName: "app-displays" */ '@/views/displays/Clone'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.monitors'],
      },
    },
  },
  {
    path: '/parameters',
    name: 'parametersList',
    component: () =>
      import(
        /* webpackChunkName: "app-parameters" */ '@/views/parameters/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.parameters'],
      },
    },
  },
  {
    path: '/plusclients',
    name: 'plusClientsList',
    component: () =>
      import(
        /* webpackChunkName: "app-plusclients" */ '@/views/plusclients/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.appointmentsplus'],
      },
    },
  },
  {
    path: '/plusclients/create',
    name: 'plusClientCreate',
    component: () =>
      import(
        /* webpackChunkName: "app-plusclients" */ '@/views/plusclients/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.appointmentsplus'],
      },
    },
  },
  {
    path: '/plusclients/:id/edit',
    name: 'plusClientEdit',
    component: () =>
      import(
        /* webpackChunkName: "app-plusclients" */ '@/views/plusclients/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.appointmentsplus'],
      },
    },
  },
  {
    path: '/plusclients/:id/clone',
    name: 'plusClientClone',
    component: () =>
      import(
        /* webpackChunkName: "app-plusclients" */ '@/views/plusclients/Clone'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.devices.appointmentsplus'],
      },
    },
  },
  {
    path: '/apps/sso',
    name: 'AuthClientSearch',
    component: () =>
      import(/* webpackChunkName: "app-apps" */ '@/views/authClients/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.apps.sso'],
      },
    },
  },
  {
    path: '/roles',
    name: 'rolesSearch',
    component: () =>
      import(/* webpackChunkName: "app-roles" */ '@/views/roles/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.roles'],
      },
    },
  },
  {
    path: '/roles/create',
    name: 'roleCreate',
    component: () =>
      import(/* webpackChunkName: "app-roles" */ '@/views/roles/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.roles'],
      },
    },
  },
  {
    path: '/roles/:id/edit',
    name: 'roleEdit',
    component: () =>
      import(/* webpackChunkName: "app-roles" */ '@/views/roles/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.roles'],
      },
    },
  },
  {
    path: '/roles/:id/edit-permissions',
    name: 'roleEditPermissions',
    component: () =>
      import(/* webpackChunkName: "app-roles" */ '@/views/roles/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.roles'],
      },
    },
  },
  {
    path: '/reports/employeestatus',
    name: 'employeeStatus',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/employeeStatus/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.employeestatus'],
      },
    },
  },
  {
    path: '/reports/employeehistory',
    name: 'employeeHistory',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/EmployeeDetailedHistory'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.employeehistory'],
      },
    },
  },
  {
    path: '/techniciansetup',
    name: 'technicianSetup',
    component: () =>
      import(
        /* webpackChunkName: "app-technicianSetup" */ '@/views/technicianSetup/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.techniciansetup'],
      },
    },
  },
  {
    path: '/loadclientinfo',
    name: 'loadClientInfo',
    component: () =>
      import(/* webpackChunkName: "app-technicianSetup" */ '@/views/clientInfo/LoadClientInfoForm'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.techniciansetup'],
      },
    },
  },
  {
    path: '/reports/currentdashboard',
    name: 'currentDashboardReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CurrentDashboard'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.currentdashboard'],
      },
    },
  },
  {
    path: '/reports/currentstatisticssummary',
    name: 'CurrentStatisticsSummaryReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CurrentStatisticsSummary'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.currentstatisticssummary'],
      },
    },
  },
  {
    path: '/reports/statisticssummary',
    name: 'StatisticsSummaryReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/StatisticsSummary'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.statisticssummary'],
      },
    },
  },
  {
    path: '/reports/dailylist',
    name: 'DailyListReport',
    component: () =>
      import(/* webpackChunkName: "app-reports" */ '@/views/reports/DailyList'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.dailylist'],
      },
    },
  },
  {
    path: '/reports/currentlist',
    name: 'CurrentListReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CurrentList'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.currentlist'],
      },
    },
  },
  {
    path: '/reports/currentlistsimple',
    name: 'CurrentListReportSimple',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CurrentListSimple'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.currentlistsimple'],
      },
    },
  },
  {
    path: '/reports/dailylistbyservicecurrent',
    name: 'DailyListByServiceReportCurrent',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/DailyListByServiceCurrent'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.dailylistbyservice'],
      },
    },
  },
  {
    path: '/reports/dailylistbyservice',
    name: 'DailyListByServiceReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/DailyListByService'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.dailylistbyservice'],
      },
    },
  },
  {
    path: '/reports/notpresent',
    name: 'PeopleNotPresented',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/PeopleNotPresented'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.notpresent'],
      },
    },
  },
  {
    path: '/reports/dashboard',
    name: 'DashboardReport',
    component: () =>
      import(/* webpackChunkName: "app-reports" */ '@/views/reports/Dashboard'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.dashboard'],
      },
    },
  },
  {
    path: '/reports/statisticsextended',
    name: 'StatisticsExtendedReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/StatisticsExtended'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.statisticsextended'],
      },
    },
  },
  {
    path: '/reports/breakstakenbyemployee',
    name: 'BreaksTakenByEmployeeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/BreaksTakenByEmployee'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.breakstakenbyemployee'],
      },
    },
  },
  {
    path: '/reports/deletedturns',
    name: 'DeletedTurnsReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/DeletedTurns'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.deletedturns'],
      },
    },
  },
  {
    path: '/reports/citedpeople',
    name: 'CitedPeopleReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CitedPeople'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.citedpeople'],
      },
    },
  },
  {
    path: '/reports/statisticssummarymultioffice',
    name: 'StatisticsSummaryMultiOfficeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/StatisticsSummaryMultiOffice'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.statisticssummarymultioffice'],
      },
    },
  },
  {
    path: '/reports/statisticssummaryservicemultioffice',
    name: 'StatisticsSummaryServiceMultiOfficeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/StatisticSummaryServiceMultiOffice'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: [
          'menu.reports.statisticssummaryservicemultioffice',
        ],
      },
    },
  },
  {
    path: '/reports/personperhourbyoffice',
    name: 'PersonPerHourByOfficeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/PersonPerHourByOffice'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.personperhourbyoffice'],
      },
    },
  },
  {
    path: '/reports/personperhourservicebyoffice',
    name: 'PersonPerHourServiceByOfficeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/PersonPerHourServiceByOffice'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.personperhourservicebyoffice'],
      },
    },
  },
  {
    path: '/reports/appointmentstatus',
    name: 'AppointmentsStatusReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AppointmentsStatus'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.appointmentstatus'],
      },
    },
  },
  {
    path: '/reports/averageservicetimeperemployee',
    name: 'AverageServiceTimePerEmployee',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AverageServiceTimePerEmployee'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.averageservicetimeperemployee'],
      },
    },
  },
  {
    path: '/reports/officewaittime',
    name: 'OfficeWaitTimeReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/OfficeWaitTime'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.officewaittime'],
      },
    },
  },
  {
    path: '/reports/stampcancellation',
    name: 'StampCancelationReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/StampCancelation'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.stampcancellation'],
      },
    },
  },
  {
    path: '/reports/averageservicetimeperqueue',
    name: 'AverageServiceTimePerQueueReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AverageServiceTimePerQueue'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.averageservicetimeperqueue'],
      },
    },
  },
  {
    path: '/reports/appointmentsavailable',
    name: 'AppointmentsAvailableReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AppointmentsAvailable'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.appointmentsavailable'],
      },
    },
  },
  {
    path: '/reports/averageservicetimeperservice',
    name: 'AverageServiceTimePerServiceReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AverageServiceTimePerService'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.averageservicetimeperservice'],
      },
    },
  },
  {
    path: '/reports/surveyanswergraphics',
    name: 'SurveyAnswerGraphicsReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyAnswerGraphics'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.surveyanswergraphics'],
      },
    },
  },
  {
    path: '/reports/surveyanswerresults',
    name: 'SurveyAnswerResultsReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyAnswerResults'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.surveyanswerresults'],
      },
    },
  },
  {
    path: '/reports/surveyquestionwithcomments',
    name: 'SurveyQuestionWithCommentsReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyQuestionWithComments'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.surveyquestionwithcomments'],
      },
    },
  },
  {
    path: '/reports/surveyanswersturn',
    name: 'SurveyAnswerTurnReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/SurveyAnswerTurn'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.surveyanswersturn'],
      },
    },
  },
  {
    path: '/reports/appointmentrecoordination',
    name: 'AppointmentRecoordinationReport',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/AppointmentRecoordination'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reports.appointmentrecoordination'],
      },
    },
  },
  {
    path: '/turns/manager',
    name: 'turnsManager',
    component: () =>
      import(/* webpackChunkName: "app-turns" */ '@/views/turns/Manager'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.turns.manager'],
        useStationPermissions: true,
      },
    },
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "app-user" */ '@/views/users/Profile'),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/stations',
    name: 'Stations',
    component: () =>
      import(/* webpackChunkName: "app-stations" */ '@/views/stations/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.stations'],
      },
    },
  },
  {
    path: '/stations/:id/edit',
    name: 'StationEdit',
    component: () =>
      import(/* webpackChunkName: "app-stations" */ '@/views/stations/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.stations'],
      },
    },
  },
  {
    path: '/offices',
    name: 'Offices',
    component: () =>
      import(/* webpackChunkName: "app-offices" */ '@/views/offices/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.offices'],
      },
    },
  },
  {
    path: '/offices/create',
    name: 'OfficeCreate',
    component: () =>
      import(/* webpackChunkName: "app-offices" */ '@/views/offices/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.offices'],
      },
    },
  },
  {
    path: '/offices/:id/edit',
    name: 'OfficeEdit',
    component: () =>
      import(/* webpackChunkName: "app-offices" */ '@/views/offices/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.offices'],
      },
    },
  },
  {
    path: '/offices/:id/clone',
    name: 'OfficeClone',
    component: () =>
      import(/* webpackChunkName: "app-offices" */ '@/views/offices/Clone'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.offices'],
      },
    },
  },
  {
    path: '/servicequeues',
    name: 'ServiceQueuesList',
    component: () =>
      import(
        /* webpackChunkName: "app-servicequeues" */ '@/views/serviceQueues/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicequeues'],
      },
    },
  },
  {
    path: '/servicequeues/:id/clone',
    name: 'ServiceQueuesClone',
    component: () =>
      import(
        /* webpackChunkName: "app-servicequeues" */ '@/views/serviceQueues/Clone'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicequeues'],
      },
    },
  },
  {
    path: '/servicequeues/:id/notifications',
    name: 'ServiceQueueCustomNotifications',
    component: () =>
      import(
        /* webpackChunkName: "app-servicequeues" */ '@/views/serviceQueues/Notifications'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['service.queue.custom.notification.manage'],
      },
    },
  },
  {
    path: '/servicequeues/groups',
    name: 'ServiceQueueGroupsList',
    component: () =>
      import(
        /* webpackChunkName: "app-servicequeues" */ '@/views/serviceQueueGroups/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicequeuegroups'],
      },
    },
  },
  {
    path: '/appointmentservicetypes/groups',
    name: 'AppointmentServiceTypeGroupsList',
    component: () =>
      import(
        /* webpackChunkName: "app-appointment" */ '@/views/appointmentServiceTypeGroups/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.appointment.servicesgroup'],
      },
    },
  },
  {
    path: '/servicetypes/groups',
    name: 'ServiceTypeGroupsList',
    component: () =>
      import(
        /* webpackChunkName: "app-servicetypes" */ '@/views/serviceTypeGroups/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicetypegroups'],
      },
    },
  },
  {
    path: '/company-users',
    name: 'CompanyUsers',
    component: () =>
      import(
        /* webpackChunkName: "app-company" */ '@/views/companyUsers/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.company.users'],
      },
    },
  },
  {
    path: '/servicetypes',
    name: 'ServicesList',
    component: () =>
      import(
        /* webpackChunkName: "app-servicetypes" */ '@/views/serviceTypes/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicetypes'],
      },
    },
  },
  {
    path: '/servicetypes/:id/clone',
    name: 'ServicesClone',
    component: () =>
      import(
        /* webpackChunkName: "app-servicetypes" */ '@/views/serviceTypes/Clone'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.servicetypes'],
      },
    },
  },
  {
    path: '/logs',
    name: 'SystemErrorsList',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/errorLogs/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.logs.errors'],
      },
    },
  },
  {
    path: '/logs/legacy',
    name: 'LegacySystemErrorsList',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/errorLogs/legacy/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.logs.errors.legacy'],
      },
    },
  },
  {
    path: '/systemLogs/process',
    name: 'ProcessList',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/systemLogs/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.logs.process'],
      },
    },
  },
  {
    path: '/breaks',
    name: 'Breaks',
    component: () =>
      import(/* webpackChunkName: "app-breaks" */ '@/views/breaks/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.breaks'],
      },
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "app-contact" */ '@/views/contact/Index'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.contact'],
      },
    },
  },
  {
    path: '/organization',
    name: 'Organization',
    component: () =>
      import(
        /* webpackChunkName: "app-organization" */ '@/views/organization/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.organization'],
      },
    },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () =>
      import(/* webpackChunkName: "app-company" */ '@/views/companies/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.companies'],
      },
    },
  },
  {
    path: '/companies/create',
    name: 'CompanyCreate',
    component: () =>
      import(/* webpackChunkName: "app-company" */ '@/views/companies/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.companies'],
      },
    },
  },
  {
    path: '/companies/:id/edit',
    name: 'CompanyEdit',
    component: () =>
      import(/* webpackChunkName: "app-company" */ '@/views/companies/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.companies'],
      },
    },
  },
  {
    path: '/companies/:id/add-members',
    name: 'CompanyAddMembers',
    component: () =>
      import(/* webpackChunkName: "app-company" */ '@/views/companies/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.operationalstructure.companies'],
      },
    },
  },
  {
    path: '/securityprofile',
    name: 'SecurityProfile',
    component: () =>
      import(
        /* webpackChunkName: "app-system" */ '@/views/securityProfile/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.securityprofile'],
      },
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/dashboard/Dashboard'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.dashboard'],
      },
    },
  },
  {
    path: '/codeservicetypes',
    name: 'CodeServiceTypes',
    component: () =>
      import(
        /* webpackChunkName: "app-office" */ '@/views/codeServiceTypes/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.service.types'],
      },
    },
  },
  {
    path: '/location/servicetypes',
    name: 'CodeServiceTypesLocation',
    component: () =>
      import(
        /* webpackChunkName: "app-office" */ '@/views/codeServiceTypesLocation/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.service.types.location'],
      },
    },
  },
  {
    path: '/survey/identifiers',
    name: 'SurveyIdentifier',
    component: () =>
      import(
        /* webpackChunkName: "app-survey" */ '@/views/surveyIdentifier/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.survey.identifier'],
      },
    },
  },
  {
    path: '/unlockdatabase',
    name: 'UnlockDatabase',
    component: () =>
      import(
        /* webpackChunkName: "app-system" */ '@/views/unlockDatabase/UnlockDatabase'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.unlockdatabase'],
      },
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () =>
      import(
        /* webpackChunkName: "app-notification" */ '@/views/notifications/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.notifications'],
      },
    },
  },
  {
    path: '/notification/create',
    name: 'NotificationCreate',
    component: () =>
      import(
        /* webpackChunkName: "app-notification" */ '@/views/notifications/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.notifications'],
      },
    },
  },
  {
    path: '/notification/:id/edit',
    name: 'NotificationEdit',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "app-notification" */ '@/views/notifications/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.notifications'],
      },
    },
  },
  {
    path: '/notification/:id/clone',
    name: 'NotificationClone',
    component: () =>
      import(
        /* webpackChunkName: "app-notification" */ '@/views/notifications/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.notifications'],
      },
    },
  },
  {
    path: '/logos',
    name: 'Logos',
    component: () =>
      import(/* webpackChunkName: "app-r" */ '@/views/logos/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.logos'],
      },
    },
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: () =>
      import(/* webpackChunkName: "app-ad" */ '@/views/sponsors/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.sponsors'],
      },
    },
  },
  {
    path: '/sponsor/ads',
    name: 'SponsorsAds',
    component: () =>
      import(/* webpackChunkName: "app-ad" */ '@/views/sponsors/ads/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.sponsors.ads'],
      },
    },
  },
  {
    path: '/sponsor/ads/:id/assing-location',
    name: 'SponsorsAdsAssingLocation',
    component: () =>
      import(
        /* webpackChunkName: "app-ad" */ '@/views/sponsors/ads/AssingLocation'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.ads'],
      },
    },
  },
  {
    path: '/login/ads',
    name: 'LoginAds',
    component: () =>
      import(/* webpackChunkName: "app-ad" */ '@/views/login/ads/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.login.ads'],
      },
    },
  },
  {
    path: '/schedule-frequencies',
    name: 'ShippingFrequencies',
    component: () =>
      import(
        /* webpackChunkName: "app-ad" */ '@/views/shippingFrequencies/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.maintenance.schedule.frequencies'],
      },
    },
  },
  {
    path: '/ads',
    name: 'Ads',
    component: () =>
      import(/* webpackChunkName: "app-ad" */ '@/views/ads/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.ads'],
      },
    },
  },
  {
    path: '/ads/search',
    name: 'SearchSpace',
    component: () =>
      import(/* webpackChunkName: "app-ad" */ '@/views/ads/AvailableSearch'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.advertisements.ads'],
      },
    },
  },
  {
    path: '/office/monitors',
    name: 'office-monitors',
    component: () =>
      import(
        /* webpackChunkName: "app-office" */ '@/views/officeMonitors/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reqappclient/search',
    name: 'reqAppClient',
    component: () =>
      import(
        /* webpackChunkName: "app-reqappclient" */ '@/views/reqAppClient/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reqappclient'],
      },
    },
  },
  {
    path: '/reqappclient/create',
    name: 'reqAppClientCreate',
    component: () =>
      import(
        /* webpackChunkName: "app-reqappclient" */ '@/views/reqAppClient/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reqappclient'],
      },
    },
  },
  {
    path: '/reqappclient/:id/edit',
    name: 'reqAppClientEdit',
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "app-reqappclient" */ '@/views/reqAppClient/Master'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.reqappclient'],
      },
    },
  },
  {
    path: '/reports/regionaldashboardaaa',
    name: 'regionaldashboardaaa',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/RegionalDashboardAAA'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/regionalcfserealtime',
    name: 'regionalcfserealtime',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/RegionalCFSERealTime'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/operationlog',
    name: 'operationlog',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/OperationLog'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/operationadministrativelog',
    name: 'operationadministrativelog',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/OperationAdministrativeLog'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/reports/callstatisticssummary',
    name: 'callstatisticssummary',
    component: () =>
      import(
        /* webpackChunkName: "app-reports" */ '@/views/reports/CallStatisticsSummary'
      ),
    meta: {
      authorization: {
        authenticated: true,
      },
    },
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: () =>
      import(/* webpackChunkName: "app-tickets" */ '@/views/tickets/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.tickets'],
      },
    },
  },
  {
    path: '/tickets/create',
    name: 'TicketCreate',
    component: () =>
      import(/* webpackChunkName: "app-tickets" */ '@/views/tickets/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.tickets'],
      },
    },
  },
  {
    path: '/tickets/:id/edit',
    name: 'TicketEdit',
    component: () =>
      import(/* webpackChunkName: "app-tickets" */ '@/views/tickets/Master'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.officesetup.tickets'],
      },
    },
  },
  {
    path: '/monitor',
    name: 'Monitor',
    component: () =>
      import(/* webpackChunkName: "app-monitor" */ '@/views/monitor/Search'),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.monitor.active.sessions'],
      },
    },
  },
  {
    path: '/quality-evaluation',
    name: 'QualityEvaluation',
    component: () =>
      import(
        /* webpackChunkName: "app-monitor" */ '@/views/qualityEvaluation/Search'
      ),
    meta: {
      authorization: {
        authenticated: true,
        acceptedPermissions: ['menu.qualityspecialist.quality.evaluation'],
      },
    },
  },
];
