import { getMetrics as _getMetrics } from '@/services/TurnService';
import { checkBreak as _checkBreak } from '@/services/BreakManagerService';
import { logoutWithRedirect } from '@/core/auth';
import { clearApiCache } from '@/modules/user/api';

import { userMixin } from '@/modules/user/mixins/user.mixin';
import IdentityPermission from '@/constants/IdentityPermission';

export const name = '$_turnManager';

const getDefaultState = () => {
  return {
    activeTurn: null,
    activeBreak: null,
    activeWaitingRoom: null,
    configuration: null,
    permissions: null,
    serviceQueues: [],
    handlerServiceQueues: [],
    stations: [],
    attenders: [],
    breaks: [],
    defaultServiceQueue: null,
    defaultStation: null,
    serviceQueueSelectionFixed: false,
    stationSelectionFixed: false,
    metrics: {
      turnsTotal: 0,
      turnClientsTotal: 0,
      notAttendedTurnsTotal: 0,
      attendedTurnsTotal: 0,
      attendedClientsTotal: 0,
      turnsAbsoluteTotal: 0,
      notAttendedClientsTotal: 0,
    },
    miscData: [],
    carriers: [],
    automaticTurns: null,
    autoClearList: null,
    preferentialTurn: false,
    canUploadDocuments: false,
    enableLanguageBehavior: false,
    selectedTurnManagerServiceQueue: null,
    selectedTurnManagerHandlerServiceQueue: null,
    selectedTurnManagerStation: null,
    turnListSearchFilters: {
      locationConfigurationId: null,
      turnNumber: null,
      clientName: null,
      status: null,
      attenderId: null,
      serviceQueueIds: [],
      pageSize: 5,
      pageIndex: 1,
    },
    loaders: {
      metrics: false,
      turnManager: {
        fullPage: false,
      },
    },
  };
};

// initial state
const state = getDefaultState();

export default {
  namespaced: true,
  state: state,
  mutations: {
    SET_INIT(state, payload) {
      state.activeTurn = payload.activeTurn;
      state.activeBreak = payload.activeBreak;
      state.activeWaitingRoom = payload.activeWaitingRoom;
      state.configuration = payload.configuration;
      state.permissions = payload.permissions;
      state.serviceQueues = payload.serviceQueues;
      state.handlerServiceQueues = payload.handlerServiceQueues;
      state.stations = payload.stations;
      state.attenders = payload.attenders;
      state.breaks = payload.breaks;
      state.defaultServiceQueue = payload.defaultServiceQueue;
      state.defaultStation = payload.defaultStation;
      state.serviceQueueSelectionFixed = payload.serviceQueueSelectionFixed;
      state.stationSelectionFixed = payload.stationSelectionFixed;
      state.metrics = payload.metrics;
      state.miscData = payload.miscData;
      state.carriers = payload.carriers;
      state.automaticTurns = payload.automaticTurns;
      state.autoClearList = payload.autoClearList;
      state.selectedTurnManagerStation = null;
      state.selectedTurnManagerHandlerServiceQueue = null;
      state.preferentialTurn = payload.preferentialTurn;
      state.canUploadDocuments = payload.canUploadDocuments;
      state.enableLanguageBehavior = payload.enableLanguageBehavior;
      state.languages = payload.languages;

      if (state.handlerServiceQueues.length == 1) {
        state.selectedTurnManagerHandlerServiceQueue =
          state.handlerServiceQueues[0];
      }

      if (state.stations.length == 1) {
        state.selectedTurnManagerStation = state.stations[0];
      }

      if (payload.defaultServiceQueue) {
        state.selectedTurnManagerHandlerServiceQueue =
          payload.defaultServiceQueue;
      }

      if (payload.defaultStation) {
        state.selectedTurnManagerStation = payload.defaultStation;
      }
    },
    SET_ACTIVE_TURN(state, payload) {
      if (state.serviceQueues.length == 1) {
        state.selectedTurnManagerServiceQueue = state.serviceQueues[0];
      }

      if (state.handlerServiceQueues.length == 1) {
        state.selectedTurnManagerHandlerServiceQueue =
          state.handlerServiceQueues[0];
      }

      if (state.stations.length == 1) {
        state.selectedTurnManagerStation = state.stations[0];
      }

      if (payload?.activeBreak) {
        state.activeBreak = payload.activeBreak;
      }

      state.activeTurn = payload;
    },
    SET_SIMPLE_ACTIVE_TURN(state, payload) {
      state.activeTurn = payload;
    },
    SET_ACTIVE_BREAK(state, payload) {
      state.activeBreak = payload;
    },
    SET_ACTIVE_WAITING_ROOM(state, payload) {
      state.activeWaitingRoom = payload;
    },
    SET_WAITING_ROOM_CITIZEN_STATE(state, payload) {
      if(state.activeWaitingRoom) {
        state.activeWaitingRoom.isCitizenActive = payload;
      }
    },
    SET_TURN_SEARCH_FILTERS(state, payload) {
      state.turnListSearchFilters = payload;
    },
    SET_TURN_LIST(state, payload) {
      state.turnList = payload;
    },
    SET_METRICS(state, payload) {
      state.metrics = payload;
    },
    RESET_DEFAULT_SERVICE_STATION(state) {
      if (state.defaultServiceQueue) {
        state.selectedTurnManagerServiceQueue = state.defaultServiceQueue;
        state.selectedTurnManagerHandlerServiceQueue =
          state.defaultServiceQueue;
      }

      if (state.defaultStation) {
        state.selectedTurnManagerStation = state.defaultStation;
      }
    },
    SET_SELECTED_TURN_MANAGER_SERVICE_QUEUE(state, payload) {
      state.selectedTurnManagerServiceQueue = payload;
    },
    SET_SELECTED_TURN_MANAGER_HANDLER_SERVICE_QUEUE(state, payload) {
      state.selectedTurnManagerHandlerServiceQueue = payload;
    },
    SET_SELECTED_TURN_MANAGER_STATION(state, payload) {
      state.selectedTurnManagerStation = payload;
    },
    TOGGLE_METRICS_LOADER(state) {
      state.loaders.metrics = !state.loaders.metrics;
    },
    TOGGLE_FULLPAGE_LOADER(state) {
      state.loaders.turnManager.fullPage = !state.loaders.turnManager.fullPage;
    },
    MARK_TURN_AS_FINALIZED(state) {
      if (state.activeTurn) {
        state.activeTurn.finalized = true;
      }
    },
    CLEAN_METRICS(state) {
      state.metrics = {
        turnsTotal: 0,
        turnClientsTotal: 0,
        notAttendedTurnsTotal: 0,
        attendedTurnsTotal: 0,
        attendedClientsTotal: 0,
        turnsAbsoluteTotal: 0,
        notAttendedClientsTotal: 0,
      };
    },
    SET_ATTENDING_TIME(state, payload) {
      state.activeTurn.attendingTime = payload;
    },
    RESET_STATE(state) {
      Object.assign(state, {...getDefaultState(), activeWaitingRoom: state.activeWaitingRoom});
    },
    UPDATE_PARTICIPANT_NOTES(state, payload) {
      const waitingRoom = state.activeWaitingRoom;
      const user = waitingRoom?.participants?.find(
        (x) => x.userId === payload.userId,
      );

      if (user) {
        user.notes = payload.notes;
      }
    },
  },
  actions: {
    setInit({ commit }, payload) {
      commit('SET_INIT', payload);
    },
    toggleFullPageLoader({ commit }) {
      commit('TOGGLE_FULLPAGE_LOADER');
    },
    setActiveTurn({ commit }, payload) {
      commit('SET_ACTIVE_TURN', payload);
    },
    setSimpleActiveTurn({ commit }, payload) {
      commit('SET_SIMPLE_ACTIVE_TURN', payload);
    },
    setActiveBreak({ commit }, payload) {
      commit('SET_ACTIVE_BREAK', payload);
    },
    setActiveWaitingRoom({ commit }, payload) {
      commit('SET_ACTIVE_WAITING_ROOM', payload);
    },
    setWaitingRoomCitizenState({ commit }, payload) {
      commit('SET_WAITING_ROOM_CITIZEN_STATE', payload);
    },
    searchTurns() {}, // subscribe
    callNextTurn() {}, // subscribe
    initCalls() {}, // subscribe
    onWaitingRoomCallDeclined() {}, // subscribe
    onWaitingRoomCallAccepted() {}, // subscribe
    setMetrics({ commit }, payload) {
      commit('SET_METRICS', payload);
    },
    resetDefaultServiceStation({ commit }) {
      commit('RESET_DEFAULT_SERVICE_STATION');
    },
    setSelectedTurnManagerServiceQueue({ commit }, payload) {
      commit('SET_SELECTED_TURN_MANAGER_SERVICE_QUEUE', payload);
    },
    setSelectedTurnManagerHandlerServiceQueue({ commit }, payload) {
      commit('SET_SELECTED_TURN_MANAGER_HANDLER_SERVICE_QUEUE', payload);
    },
    setSelectedTurnManagerStation({ commit }, payload) {
      commit('SET_SELECTED_TURN_MANAGER_STATION', payload);
    },
    cleanMetrics({ commit }) {
      commit('CLEAN_METRICS');
    },
    setTurnSearchFilters({ commit }, payload) {
      commit('SET_TURN_SEARCH_FILTERS', {
        ...payload,
        turnNumber: payload.turnNumber ? payload.turnNumber : null,
      });
    },
    markActiveTurnAsFinalized({ commit }) {
      commit('MARK_TURN_AS_FINALIZED');
    },
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async refreshMetrics({ state, commit }, locationConfigurationId) {
      if (!state.serviceQueues.length) {
        commit('CLEAN_METRICS');
        return;
      }

      const payload = {
        locationConfigurationId,
        serviceQueueIds: state.serviceQueues.map((x) => x.id),
      };

      commit('TOGGLE_METRICS_LOADER');

      await _getMetrics(payload)
        .then(({ data }) => {
          commit('SET_METRICS', data);
        })
        .finally(() => {
          commit('TOGGLE_METRICS_LOADER');
        });
    },
    cleanTopFilters() {}, // subscribe
    refreshTurnManager() {}, //subscribe
    setAttendingTime({ commit }, payload) {
      commit('SET_ATTENDING_TIME', payload);
    },
    async checkUserBreak({ state, getters, commit }, payload) {
      if (
        !userMixin.methods.userHasPermissions(
          IdentityPermission.break.check.enabled,
        ) ||
        !state.configuration.breakModuleEnabled
      )
        return false;

      if (payload.turnFinished && !state.loaders.turnManager.fullPage)
        commit('TOGGLE_FULLPAGE_LOADER');

      let performLogout = false; // This is a flag for logging out

      const checkResult = await _checkBreak({
        turnFinished: payload.turnFinished,
      }).then(async ({ data }) => {
        commit('SET_ACTIVE_BREAK', data.activeBreak);

        if (
          payload.turnFinished &&
          data.activeBreak &&
          !data.activeBreak.hasAuthorizationPending
        ) {
          performLogout = true;
        }

        // Start the break if needed
        if (getters.userHasPendingBreakToStart) {
          performLogout = true;

          return await _checkBreak({ turnFinished: true }).then(
            async ({ data }) => {
              commit('SET_ACTIVE_BREAK', data.activeBreak);

              return true;
            },
          );
        }

        if (
          (!state.activeTurn || state.activeTurn.finalized) &&
          (!data.activeBreak ||
            data.activeBreak.hasAuthorizationPending ||
            data.activeBreak.userHasPendingBreakToStart)
        ) {
          return false;
        }

        return true;
      });

      if (performLogout) {
        await clearApiCache();
        logoutWithRedirect();

        return true;
      }

      if (state.loaders.turnManager.fullPage) commit('TOGGLE_FULLPAGE_LOADER');

      return checkResult;
    },
    updateParticipantNotes({ commit }, payload) {
      commit('UPDATE_PARTICIPANT_NOTES', payload);
    },
  },
  getters: {
    userHasPendingBreak: (state) => {
      if (!state.activeBreak) return false;

      const activeTurn =
        state.activeTurn != null && !state.activeTurn.finalized;
      const activeWaitingRoom = state.activeWaitingRoom != null;

      return (
        (activeTurn || activeWaitingRoom) &&
        state.activeBreak.userHasPendingBreakToStart
      );
    },
    userHasPendingBreakToStart: (state) => {
      if (!state.activeBreak) return false;

      const noActiveTurn =
        state.activeTurn == null || state.activeTurn.finalized;
      const noActiveWaitingRoom = state.activeWaitingRoom == null;

      if (noActiveTurn && noActiveWaitingRoom) {
        return state.activeBreak.userHasPendingBreakToStart;
      }

      return false;
    },
  },
};
