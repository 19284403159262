import { ACTION_TYPES } from './types';

import { getValidationMessages as _getValidationMessages } from '../api';

export default {
  async getValidationMessages (context, messagesCodes) {
    const validationMessages = await _getValidationMessages(messagesCodes);
    context.commit(ACTION_TYPES.SET_VALIDATION_MESSAGES, validationMessages);
  }
};
