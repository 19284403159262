import { apiTurnos } from '@/core/api/';

const qs = require('querystring');

export default {
  getCurrentTurnAttended(locationConfigurationId) {
    return apiTurnos.get(
      `/api/turnmanager/GetCUrrentTurnAttended?locationConfigurationId=${locationConfigurationId}`,
    );
  },
};

export const getTurn = (turnId) => apiTurnos.get(`/api/turnmanager/${turnId}`);

export const editTurn = (turn) => {
  return apiTurnos.put('/api/turnmanager', turn);
};

// Below is the correct way to handle the exports for api calls
export const getAvailablePeriods = () =>
  apiTurnos.get('api/turnmanager/availableperiods');

export const getTurnsOverWaiting = (payload) => {
  return apiTurnos.post('api/turnmanager/overwait/list', payload);
};

export const isReactivateValid = (turnNumber, locationConfigurationId) => {
  return apiTurnos.get('api/turnmanager/overwait/isreactivationvalid', {
    params: { turnNumber, locationConfigurationId },
  });
};

export const reactivateTurn = (locationConfigurationId, turnId) => {
  return apiTurnos.post('api/turnmanager/overwait/reactivate', {
    locationConfigurationId,
    turnId,
  });
};

export const getTotalStatistics = (locationConfigurationId) =>
  apiTurnos.get(`api/turnmanager/TotalStatistics/${locationConfigurationId}`);

export const getProfile = (payload) => {
  return apiTurnos.post('api/turnmanager/profile', payload);
};

export const getServiceQueues = (locationConfigurationId) => {
  return apiTurnos.get(
    `api/turnmanager/${locationConfigurationId}/servicequeues/list`,
  );
};

export const getServiceTypes = (locationConfigurationId, serviceQueues) => {
  return apiTurnos.post(
    `api/turnmanager/${locationConfigurationId}/serviceTypes/list`,
    serviceQueues,
  );
};

export const createByManager = (payload) => {
  return apiTurnos.post('api/turnmanager/manager/create', payload);
};

export const getTurns = (payload) => {
  return apiTurnos.post('api/turnmanager/search', payload);
};

export const validateNewListConfiguration = (locationConfigurationId) => {
  return apiTurnos.get(
    `api/turnmanager/turn/list/create/${locationConfigurationId}`,
  );
};

export const createNewList = (payload) => {
  return apiTurnos.post('api/turnmanager/turn/list/create', payload);
};
export const getManagerPermissions = (locationConfigurationId) =>
  apiTurnos.get(
    `api/turnmanager/${locationConfigurationId}/manager/permissions`,
  );

export const getMetrics = (payload) => {
  return apiTurnos.get(`api/turnmanager/metrics`, {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const removeTurn = (turnId) =>
  apiTurnos.delete(`api/turnmanager/${turnId}`);

// This method calls the turn and finalize it at the same time
export const closeTurn = (turnId) =>
  apiTurnos.post(`api/turnmanager/${turnId}/close`);

export const callNextTurn = (payload) =>
  apiTurnos.put(`/api/turnmanager/turn/call/next`, payload);

export const recallTurn = (payload) =>
  apiTurnos.put(`/api/turnmanager/turn/call/recall`, payload);

export const callIndividualTurn = (payload) =>
  apiTurnos.put('api/turnmanager/turn/call/individual', payload);
export const initTurnManager = (locationConfigurationId) =>
  apiTurnos.get(`/api/turnmanager/init`, {
    params: {
      locationConfigurationId,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

export const getTurnToUpdate = (turnId) =>
  apiTurnos.get(`api/turnmanager/${turnId}/update`);

export const finalizeTurn = (payload) =>
  apiTurnos.put(`/api/turnmanager/turn/finalize`, payload);

export const reassignTurn = (payload) =>
  apiTurnos.post(`/api/turnmanager/reassign`, payload);

export const finishServiceType = (payload) =>
  apiTurnos.put(`/api/turnmanager/finish/servicetype`, payload);

export const getCurrentTurnLocation = (payload) =>
  apiTurnos.get(`/api/turnmanager/current/location`, payload);

export const resendVideoCallLink = (turnId) =>
  apiTurnos.post('api/turnmanager/turn/notification/video/resend', turnId);

export const instantCallTurn = (payload) =>
  apiTurnos.post(`/api/turnmanager/turn/call/instant`, payload);

export const requestTurnDocuments = (payload) =>
  apiTurnos.post(`/api/turnmanager/turn/documents/request`, payload);

export const getTurnTransactions = (payload) => {
  return apiTurnos.get('/api/turnmanager/turn/transactions/search', {
    params: payload,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });
};

export const getDocuments = (turnoId) =>
  apiTurnos.get(`api/turnmanager/${turnoId}/documents/list`);

export const getDocumentsZip = (turnoId) =>
  apiTurnos.get(`api/turnmanager/${turnoId}/documents/download`);

export const getDocument = (turnoId, documentId) =>
  apiTurnos.get(`api/turnmanager/${turnoId}/document/${documentId}`);

export const sendBroadcast = (payload) =>
  apiTurnos.post(`/api/turnmanager/turn/notification/broadcast`, payload);
