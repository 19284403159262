import Vue from 'vue';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend
} from 'vee-validate';

import * as rules from 'vee-validate/dist/rules';

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);

Object.keys(rules).forEach(rule => {
  let ruleObj = {
    ...rules[rule],
  };
  extend(rule, ruleObj);
});

setInteractionMode('eager');
