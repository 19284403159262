import Vue from 'vue';
import VueRouter from 'vue-router';
import { applyEvents } from './events';
import { applyRedirect } from './redirect';
import routes from './routes';
import store from '../store';
import { modulesName } from '@/store';

Vue.use(VueRouter);

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 };
  }
});

applyRedirect(Router);
applyEvents(Router);

Router.beforeEach((to, from, next) => {
  store.commit(`${modulesName.appModuleName}/SET_ROUTE`, to.name);
  const acceptedPermissions = to.meta?.authorization?.acceptedPermissions || [];
  if (acceptedPermissions.length) {
    const userPermissions = store.state[modulesName.userModuleName].permissions;
    const havePermissions = acceptedPermissions.filter(permission => userPermissions.includes(permission));
    if (havePermissions.length) next();
    else next({ name: '403' });
  } else next();
});

export default Router;
